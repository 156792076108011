import { ElementTabKey } from '@business/design_resource_bar/tab/_constants/elementTabType';

const elementTabKeyToKoreanMap: Record<ElementTabKey, string> = {
  ELEMENTALL: '전체',
  ILLUSTRATION: '일러스트',
  FIGURE: '도형',
  LINE: '선',
  ELEMENT_KEYWORD_ICON: '아이콘',
  ANI: '애니',
  COLLECTION: '컬렉션',
  FRAME: '프레임',
  GRID: '표',
  CHART: '차트',
  COMBINATION: '조합',
};

export const makeElementTabKeyToKoreanMap = (tabKey: ElementTabKey) =>
  elementTabKeyToKoreanMap[tabKey];
