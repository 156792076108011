import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionSearchTemplateWorkspace: GAParameterMakerFnType<
  'ACTION_SEARCH_TEMPLATE_WORKSPACE'
> = (payload) => {
  return {
    beginning_template: payload.data.beginning_template,
    event: 'search_template_workspace',
    log_type: 'search_template_workspace',
    search_status: payload.data.search_status,
    search_template_type: payload.data.search_template_type,
    search_term: payload.data.search_term,
    search_type: payload.data.search_type,
    source_term: payload.data.source_term,
    account_id: payload.data.account_id?.toString(),
    plan_type: payload.data.plan_type,
    team_idx: payload.data.team_idx?.toString(),
  };
};
