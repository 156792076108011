import { useAtom } from 'jotai';
import { ReactNode, createContext } from 'react';
import { shouldForwardProp } from '@configs/styled-components/shouldForwardProp';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { ThemeTokenType, mixinFunctions, themeDark, themeLight } from '@miri-unicorn/miricanvas-ds';
import { DeviceType } from '@configs/devices/device_type';
import { GlobalStyle } from '@styles/GlobalStyle';
import { media } from '@styles/media/media';
import { toggleZendeskWidgetGlobalStyle } from '@ui/common/zendesk/useZendeskWidgetGlobalStyleStore';
import { useBoolean } from '@utils/hooks';

export const DarkModeContext = createContext({
  isDarkMode: false,
  toggleDarkMode: () => {},
  setLightMode: () => {},
  setDarkMode: () => {},
});

export type MicanThemeProviderProps = {
  isDarkMode: boolean;
  deviceType?: DeviceType;
  children: ReactNode;
};

const createTheme = (isDarkMode: boolean) => {
  return {
    // TODO 다크모드 상태 기본값을 유저 세팅에서 불러오기, SSR 대응
    token: isDarkMode ? themeDark : (themeLight as ThemeTokenType),
    media,
    mixin: mixinFunctions,
  };
};

export const MicanStyledProvider = ({
  isDarkMode: initialDarkMode,
  children,
  deviceType,
}: MicanThemeProviderProps) => {
  const {
    value: isDarkMode,
    toggle: toggleDarkMode,
    setTrue: setDarkMode,
    setFalse: setLightMode,
  } = useBoolean(initialDarkMode);

  const theme = createTheme(isDarkMode);
  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode, setDarkMode, setLightMode }}>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <ThemeProvider theme={theme}>
          <GlobalStyleWrapper deviceType={deviceType} />
          {children}
        </ThemeProvider>
      </StyleSheetManager>
    </DarkModeContext.Provider>
  );
};

const GlobalStyleWrapper = ({ deviceType }: { deviceType?: DeviceType }) => {
  const [isZendeskWidgetOpen] = useAtom(toggleZendeskWidgetGlobalStyle);
  return <GlobalStyle deviceType={deviceType} isZendeskWidgetOpen={isZendeskWidgetOpen} />;
};
