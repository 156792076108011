import { i18n } from 'next-i18next';
import { useMemo } from 'react';
import { LanguageType } from '@constants/LanguageType';

const DEFAULT_LANGUAGE = 'ko';
export const useCurrentLanguage = () => {
  const currentLanguage = (i18n?.language ?? DEFAULT_LANGUAGE) as LanguageType;

  const isKorean = useMemo(() => currentLanguage === 'ko', [currentLanguage]);
  const isJapanese = useMemo(() => currentLanguage === 'ja', [currentLanguage]);
  return { currentLanguage, isKorean, isJapanese };
};
