import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickAIPresentationButtonInAITools: GAParameterMakerFnType<
  'ACTION_CLICK_AI_PRESENTATION_BUTTON_IN_AI_TOOLS'
> = (payload) => ({
  log_type: 'generic_event_mican',
  event: 'gtm_trigger_event',
  location1: '에디터',
  location2: 'AI드로잉',
  action1: '클릭',
  label1: '프레젠테이션생성',
  account_id: payload.data.account_id?.toString(),
});
