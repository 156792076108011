import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

export const makeGAParamsActionClickTemplateKeywordBanner: GAParameterMakerFnType<
  'ACTION_CLICK_TEMPLATE_KEYWORD_BANNER'
> = ({ data: { location2 } }) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '템플릿페이지',
    location2,
    location3: 'CTA',
    location4: undefined,
    action1: '클릭',
    label1: '템플릿페이지_맞춤배너_CTA_클릭',
  } satisfies ActionTemplateKeywordBannerReturnPayload;
};

export const makeGAParamsActionViewTemplateKeywordBanner: GAParameterMakerFnType<
  'ACTION_VIEW_TEMPLATE_KEYWORD_BANNER'
> = ({ data: { location2 } }) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '템플릿페이지',
    location2,
    location3: undefined,
    location4: undefined,
    action1: '뷰',
    label1: '템플릿페이지_맞춤배너_뷰',
  } satisfies ActionTemplateKeywordBannerReturnPayload;
};

export type ActionTemplateKeywordBannerPayload = {
  location2: '프레젠테이션' | '포스터' | '인포그래픽' | '로고' | '명함' | '초대장' | undefined;
};

type ActionTemplateKeywordBannerReturnPayload = GAParam<GTMTriggerEventParams>;
