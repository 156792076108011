import { getTierKor } from '@business/design_resource/tier/_services/_pures/designTierUtil';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { ActionClickTemplateSearchFilterTierButtonReturnPayload } from '../types/TemplateSearchFilterPayload';

export const makeGAParamsActionClickTemplateSearchFilterTierButton: GAParameterMakerFnType<
  'ACTION_CLICK_TEMPLATE_SEARCH_FILTER_TIER_BUTTON'
> = (params) => {
  return {
    log_type: 'generic_event_mican',
    event: 'gtm_trigger_event',
    location1: '에디터',
    location2: '템플릿',
    location3: '검색필터',
    location4: getTierKor(params.data.tier),
    action1: '클릭',
    label1: `에디터_템플릿_검색필터_${getTierKor(params.data.tier)}`,
  } satisfies ActionClickTemplateSearchFilterTierButtonReturnPayload;
};
