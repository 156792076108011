import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickCloseuttonAddBookmarkGuidePopup = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickCloseButtonAddBookmarkGuidePopup: GAParameterMakerFnType<
  'ACTION_CLICK_CLOSE_BUTTON_ADD_BOOKMARK_GUIDE_POPUP'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '다운로드 완료 팝업',
    location3: '즐겨찾기에추가하기',
    location4: '단축키안내팝업닫기',
    action1: '클릭',
    label1: '에디터_다운로드중완료_즐겨찾기에추가하기_단축키안내팝업닫기',
  } satisfies ActionClickCloseuttonAddBookmarkGuidePopup;
};
