import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { getSearchActionStatus } from '@configs/google_analytics/utils/getSearchStatus';
import { makeTextTabKeyToKoreanMap } from '../utils/makeTextTabKeyToKoreanMap';

export const makeGAParamsActionSearchTextItem: GAParameterMakerFnType<'ACTION_SEARCH_TEXT_ITEM'> = (
  payload
) => {
  return {
    event: 'search_text',
    log_type: 'search_text',
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
    search_status: getSearchActionStatus(payload.data.keyword),
    search_type: payload.data.search_type,
    search_term: payload.data.keyword,
    search_text_type: makeTextTabKeyToKoreanMap(payload.data.tabKey),
  };
};
