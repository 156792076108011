import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import {
  GTMTriggerEventParams,
  GTMUserParams,
} from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionUploadQRCodeSymbolMarkImageParams = GAParam<GTMTriggerEventParams & GTMUserParams>;

export const makeGAParamsActionUploadQRCodeSymbolMarkImage: GAParameterMakerFnType<
  'ACTION_UPLOAD_QR_CODE_SYMBOL_MARK_IMAGE'
> = (payload) => {
  return {
    log_type: 'generic_event_mican',
    event: 'gtm_trigger_event',
    location1: '에디터',
    location2: 'QR/바코드',
    location3: '심벌마크',
    action1: '완료',
    label1: '업로드',
    account_id: payload.data.account_id,
  } satisfies ActionUploadQRCodeSymbolMarkImageParams;
};
