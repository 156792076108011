import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import {
  GTMTriggerEventParams,
  GTMUserParams,
} from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickQRCodeMakeButtonParams = GAParam<GTMTriggerEventParams & GTMUserParams>;

export const makeGAParamsActionClickQRCodeMakeButton: GAParameterMakerFnType<
  'ACTION_CLICK_QR_CODE_MAKE_BUTTON'
> = () => {
  return {
    log_type: 'generic_event_mican',
    event: 'gtm_trigger_event',
    location1: '에디터',
    location2: '좌패널',
    location3: 'QR코드',
    action1: '클릭',
    label1: '2.0에디터_좌패널_QR코드_만들기',
  } satisfies ActionClickQRCodeMakeButtonParams;
};

export const makeGAParamsActionClickQRCodeMakeMobileButton: GAParameterMakerFnType<
  'ACTION_CLICK_QR_CODE_MAKE_MOBILE_BUTTON'
> = () => {
  return {
    log_type: 'generic_event_mican',
    event: 'gtm_trigger_event',
    location1: '에디터',
    location2: '좌패널',
    location3: 'QR코드',
    action1: '클릭',
    label1: '2.0에디터_모바일_좌패널_QR코드_만들기',
  } satisfies ActionClickQRCodeMakeButtonParams;
};
