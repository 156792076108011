import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickTemplateSearchPageBannerButton: GAParameterMakerFnType<
  'ACTION_CLICK_TEMPLATE_SEARCH_PAGE_BANNER_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '템플릿페이지2',
    location2: '키워드카테고리페이지',
    location3: '가입유도배너',
    location4: undefined,
    action1: '클릭',
    label1: '템플릿페이지2_키워드카테고리_가입유도배너_클릭',
  };
};
