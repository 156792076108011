import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { AbValue } from '@configs/ab-test/_types/type';

const AB_BUCKET_VALUE_KEY = 'AB_BUCKET_VALUE';

const abBucketValueAtom = atomWithStorage<AbValue>(AB_BUCKET_VALUE_KEY, {});

export const useSetAbBucketLocalStorage = () => {
  const setAbBucketLocalStorage = useSetAtom(abBucketValueAtom);

  return { setAbBucketLocalStorage };
};

export const useAbBucketLocalStorageValue = () => {
  const abBucketLocalStorage = useAtomValue(abBucketValueAtom);
  return { abBucketLocalStorage };
};

export const getAbBucketLocalStorageValue = () => {
  return JSON.parse(localStorage.getItem(AB_BUCKET_VALUE_KEY) || '{}');
};
