import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { getSearchActionStatus } from '@configs/google_analytics/utils/getSearchStatus';
import { makeElementFileTypeToKorean } from '../utils/makeElementFileTypeToKorean';
import { makeElementTabKeyToKoreanMap } from '../utils/makeElementTabKeyToKorean';

export const makeGAParamsActionClickElementItem: GAParameterMakerFnType<
  'ACTION_CLICK_ELEMENT_ITEM'
> = (payload) => {
  return {
    event: 'action_element',
    log_type: 'action_element',
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    beginning_element: payload.data.beginning_element,
    beginning_element_type: payload.data.beginning_element_type,
    source_tab: payload.data.sourceTab,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
    search_status: getSearchActionStatus(payload.data.keyword),
    search_type: payload.data.search_type,
    search_term: payload.data.keyword,
    search_element_type: makeElementTabKeyToKoreanMap(payload.data.tabKey),
    search_color: payload.data.filteredColor,
    search_file_type: makeElementFileTypeToKorean(payload.data.tabKey, payload.data.typeList),
    search_keyword_category: payload.data.keywordCategoryName,
    action_item: payload.data.key?.toString(),
    action_item_type: payload.data.resourceType,
    priority: payload.data.verticalViewSequence?.toString(),
    priority2: payload.data.horizontalViewSequence?.toString(),
  };
};
