/**
 * 순환 참조 방지를 위한 JSON.stringify
 * @param obj
 * @param spacing
 */
export const toJsonString = (obj: unknown, spacing = 4): string => {
  if (obj === undefined) return 'undefined';
  if (obj === null) return 'null';

  try {
    const cache = new Set();
    return JSON.stringify(
      obj,
      (_, value) => {
        if (typeof value === 'object' && value !== null) {
          if (cache.has(value)) {
            return '[Circular]';
          }
          cache.add(value);
        }
        return value;
      },
      spacing
    );
  } catch (e) {
    return 'stringify error';
  }
};
