import { FetchDesignDraftListParams } from '@miri-unicorn/miricanvas-api-client';
import { getQueryKey } from '@configs/react-query/queryKeys';
import {
  CreateDesignDraftParams,
  UpdateDesignDraftDecisionParams,
  UpdateDesignDraftParams,
} from './useDesignDraftQuery';

export const designDraftQueryKeys = {
  createDesignDraft: (params: CreateDesignDraftParams) => getQueryKey('createDesignDraft', params),
  fetchDesignDraft: (draftIdx: number) => getQueryKey('fetchDesignDraft', draftIdx),
  updateDesignDraft: (params: UpdateDesignDraftParams) => getQueryKey('updateDesignDraft', params),
  updateDesignDraftDecision: (params: UpdateDesignDraftDecisionParams) =>
    getQueryKey('updateDesignDraftDecision', params),
  fetchDesignDraftList: (params: FetchDesignDraftListParams) =>
    getQueryKey('fetchDesignDraftList', params),
};
