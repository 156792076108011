export const IMAGE_JPEG = 'image/jpeg';
export const IMAGE_JPG = 'image/jpg';
export const IMAGE_PNG = 'image/png';
export const IMAGE_SVG_XML = 'image/svg+xml';
export const IMAGE_GIF = 'image/gif';
// Video
export const VIDEO_MP4 = 'video/mp4';
export const VIDEO_MOV = 'video/quicktime';
// Audio
export const AUDIO_MP3 = 'audio/mpeg';
export const AUDIO_M4A = 'audio/x-m4a';
