import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type MakeGAParamsActionClickCaretButtonOpenLeftMenus = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickCaretButtonOpenLeftMenus: GAParameterMakerFnType<
  'ACTION_CLICK_CARET_BUTTON_OPEN_LEFT_MENUS'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '좌탭메뉴',
    location3: '메뉴펼치기',
    action1: '클릭',
    label1: '에디터_좌탭메뉴_메뉴펼치기',
  } satisfies MakeGAParamsActionClickCaretButtonOpenLeftMenus;
};
