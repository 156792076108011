import { useSyncExternalStore } from 'react';
import { subscribeViewportSize } from '@utils/hooks/viewport/subscribeViewportSize';

/**
 * window.innerWidth를 구독하는 훅
 * 서버사이드에서는 null을 반환
 * @param selector (필수) window.innerWidth를 이용해 값을 계산하는 함수, 리턴하는 값에 따라 리렌더링 여부 결정
 */
export const useViewportWidth = <T extends Primitive>(selector: (width: number) => T) => {
  return useSyncExternalStore<T | null>(
    subscribeViewportSize,
    () => selector(window.innerWidth),
    () => null
  );
};
