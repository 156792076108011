import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import {
  GTMTriggerEventParams,
  GTMUserParams,
} from '@configs/google_analytics/types/GTMTriggerEventParams';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

type ActionChangeTemplateWithPrintoutSize = GAParam<GTMTriggerEventParams & GTMUserParams>;

export const makeGAParamsActionChangeTemplateWithPrintoutSize: GAParameterMakerFnType<
  'ACTION_CHANGE_TEMPLATE_WITH_PRINTOUT_SIZE'
> = (payload) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '인쇄물제작하기',
    location2: '3뎁스',
    location3: '선택한 인쇄용 사이즈로 적용',
    action1: '뷰',
    label1: payload.data.template_type_id,
    account_id: payload.data.account_id,
  } satisfies ActionChangeTemplateWithPrintoutSize;
};
