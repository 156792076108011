import { useCallback, useState } from 'react';
import { TabIdType } from '@miri-unicorn/miricanvas-ds/lib/src/component/tab/TabProps';

export const useTab = <T extends TabIdType>(initialTabId: T) => {
  const [selectedTab, setSelectedTab] = useState<T>(initialTabId);

  const onChangeTabItem = useCallback(
    (id: T) => {
      setSelectedTab(id);
    },
    [setSelectedTab]
  );

  return {
    selectedTab,
    onChangeTabItem,
  };
};
