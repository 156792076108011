import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickGlobalUpdateModalNextButton: GAParameterMakerFnType<
  'ACTION_CLICK_GLOBAL_UPDATE_MODAL_NEXT_BUTTON'
> = () => ({
  log_type: 'generic_event_mican',
  event: 'gtm_trigger_event',
  location1: '에디터',
  location2: '업데이트팝업',
  location3: 'Next',
  action1: '클릭',
  label1: '에디터_업데이트팝업_Next',
});
