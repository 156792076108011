import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickPageAnimationPresetParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickPageAnimationPreset: GAParameterMakerFnType<
  'ACTION_CLICK_PAGE_ANIMATION_PRESET'
> = (payload) => {
  return {
    log_type: 'generic_event_mican',
    event: 'gtm_trigger_event',
    location1: '에디터',
    location2: '페이지 애니메이션',
    location3: payload.data.pageAnimationPresetName,
    action1: '클릭',
    label1: `페이지 애니메이션_${payload.data.pageAnimationPresetName}`,
  } satisfies ActionClickPageAnimationPresetParams;
};
