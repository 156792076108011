import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickFreeSubscriptionModalStartForFreeButton: GAParameterMakerFnType<
  'ACTION_CLICK_FREE_SUBSCRIPTION_POPUP_START_FOR_FREE_BUTTON'
> = () => ({
  log_type: 'generic_event_mican',
  event: 'gtm_trigger_event',
  location1: '무료구독권',
  location2: 'StartForFreeCTA',
  action1: '클릭',
  label1: '무료구독권_StartForFreeCTA',
});

export const makeGAParamsActionClickFreeSubscriptionModalCloseButton: GAParameterMakerFnType<
  'ACTION_CLICK_FREE_SUBSCRIPTION_POPUP_CLOSE_BUTTON'
> = () => ({
  log_type: 'generic_event_mican',
  event: 'gtm_trigger_event',
  location1: '무료구독권',
  location2: '닫기',
  action1: '클릭',
  label1: '닫기',
});
