import { atom, useAtom } from 'jotai';
import { Language } from '@miri-unicorn/miricanvas-api-client';
import { useCurrentLanguage } from '@configs/i18next/useCurrentLanguage';
import { getRootStore } from '@configs/jotai/RootProvider';

export const templateSearchLanguageAtom = atom<Language | null>(null);

/**
 * 템플릿 패널에서 검색 시 언어칩에서 선택한 언어
 */
export const useTemplateSearchLanguage = () => {
  const { currentLanguage } = useCurrentLanguage();
  const [searchLanguage, setSearchLanguage] = useAtom(templateSearchLanguageAtom);

  return {
    /** currentLanauge로 디폴트값을 설정해주지 않으면 SSR시 사용한 정보와
     *  language가 달라져 한 번 더 API를 패치해오는 문제가 발생함 */
    searchLanguage: searchLanguage ?? currentLanguage,
    setSearchLanguage,
  };
};

export const getTemplateSearchLanguageSnapshotValue = () => {
  return {
    search_language: getRootStore().get(templateSearchLanguageAtom),
  };
};
