export const DEFAULT_USER = {
  accountId: 0,
  admin: false,
  animatedUploadSizeLimit: 0,
  authList: [],
  authStatus: 'NOT_CONFIRMED',
  editorConfig: '',
  email: 'guest',
  emailReception: 'NO',
  imageUploadSizeLimit: 0,
  ipCurrency: 'KRW',
  myAuthInfoList: [],
  name: 'guest',
  onlySocial: false,
  svgUploadSizeLimit: 0,
  teamList: [],
  totalUploadSizeLimit: 0,
  userInfoSurveyComplete: false,
  videoUploadSizeLimit: 0,
  uuid: undefined,
  languageSetting: undefined,
  profileImageUrl: undefined,
  firstLogin: false,
  isFirstLogin: false,
  isHelpMode: false,
  ipCountryCode: 'KR',
};
