import {
  GetDesignResourceListParams,
  GetRecentDesignResourceListParams,
} from '@miri-unicorn/miricanvas-api-client';

export const videoElementQueryKeys = {
  getVideoElementList: (params: GetDesignResourceListParams) =>
    ['getVideoElementList', params] as const,
  getVideoElementListInfinite: (params: Omit<GetDesignResourceListParams, 'page'>) =>
    ['getVideoElementListInfinite', params] as const,
  getRecentVideoElementListInfinite: (params: Omit<GetRecentDesignResourceListParams, 'page'>) =>
    ['getRecentVideoElementListInfinite', params] as const,
  getRecentVideoElementListInfiniteDefault: () => ['getRecentVideoElementListInfinite'],
};
