import { designResourcePanelMenuKorMap } from '@business/design_resource_bar/tab/_constants/designResourcePanelMenuType';
import { getDeviceType } from '@configs/devices/device_util';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { selectedDesignResourceMenuSnapshotValue } from '@ui/design/_common/_store/useDesignResourceMenuStore';
import { ActionClickMoreInfoButtonReturnPayload } from '../types/ActionClickMoreInfoButtonPayload';

export const makeGAParamsActionClickMoreInfoButton: GAParameterMakerFnType<
  'ACTION_CLICK_MORE_INFO_BUTTON'
> = (params) => {
  const selectedLeftPanelMenu = selectedDesignResourceMenuSnapshotValue();
  return {
    log_type: 'generic_event_mican',
    event: 'gtm_trigger_event',
    location1: '에디터',
    location2: '편집메뉴',
    location3: `${designResourcePanelMenuKorMap[selectedLeftPanelMenu]}`,
    location4: '정보더보기',
    action1: '클릭',
    label1: '더보기',
    account_id: params.data.account_id?.toString(),
    device_type: getDeviceType(),
  } satisfies ActionClickMoreInfoButtonReturnPayload;
};
export const makeGAParamsActionClickMoreInfoPopoverPriceInfoButton: GAParameterMakerFnType<
  'ACTION_CLICK_MORE_INFO_POPOVER_PRICE_INFO_BUTTON'
> = (params) => {
  const selectedLeftPanelMenu = selectedDesignResourceMenuSnapshotValue();
  return {
    log_type: 'generic_event_mican',
    event: 'gtm_trigger_event',
    location1: '에디터',
    location2: '편집메뉴',
    location3: `${designResourcePanelMenuKorMap[selectedLeftPanelMenu]}`,
    location4: '정보더보기',
    action1: '클릭',
    label1: params.data.isFree ? '무료사용안내' : '가격안내',
    account_id: params.data.account_id?.toString(),
    device_type: getDeviceType(),
  };
};
