import { getDeviceType } from '@configs/devices/device_util';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { getSignUpSocialTypeGAText } from '../utils/sign_up_ga_util';

export const makeGAParamsActionSignInUser: GAParameterMakerFnType<'ACTION_SIGN_IN_USER'> = (
  params
) => {
  return {
    event: 'signin',
    log_type: 'signin',
    type: params.data.type,
    channel: params.data.channel,
    user_id: params.data.user_id?.toString(),
    team_idx: params.data.team_idx?.toString(),
    plan_type: params.data.plan_type,
    email_address: params.data.email_address,
    notification_subscription_types: params.data.notification_subscription_types,
  };
};

export const makeGAParamsActionSignUpUser: GAParameterMakerFnType<'ACTION_SIGN_UP_USER'> = (
  params
) => {
  const socialTypeKorean = getSignUpSocialTypeGAText(params);

  return {
    event: 'ma_sign_up',
    log_type: 'ma_sign_up',
    location1: '회원가입',
    label1: socialTypeKorean ?? '',
    action1: '완료',
    account_id: `${params.data.account_id}`,
    device_type: getDeviceType(),
  };
};
