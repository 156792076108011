import { useEffect, useState } from 'react';
import { useIsomorphicLayoutEffect } from '@utils/hooks';

type UseLockedBodyOutput = [boolean, (locked: boolean) => void];

/***
 * ### useLockedBody 커스텀 훅
 * - components 의 스크롤 막는 상태를 관리하는 커스텀 훅
 * @todo 의도한대로 제대로 동작하는지 테스트 후 주석 지우기
 * @example
 * ```ts
  const [locked, setLocked] = useLockedBody(false)
      <button onClick={()=> setLocked(!locked)}>
        {locked ? 'unlock scroll' : 'lock scroll'}
```
 */
export const useLockedBody = (
  initialLocked = false,
  rootId = '__next' // next root
): UseLockedBodyOutput => {
  const [locked, setLocked] = useState(initialLocked);

  // Do the side effect before render
  useIsomorphicLayoutEffect(() => {
    if (!locked) {
      return;
    }

    // Save initial components style
    const originalOverflow = document.body.style.overflow;
    const originalPaddingRight = document.body.style.paddingRight;

    // Lock components scroll
    document.body.style.overflow = 'hidden';

    // Get the scrollBar width
    const root = document.getElementById(rootId); // or root
    const scrollBarWidth = root ? root.offsetWidth - root.scrollWidth : 0;

    // Avoid width reflow
    if (scrollBarWidth) {
      document.body.style.paddingRight = `${scrollBarWidth}px`;
    }

    return () => {
      document.body.style.overflow = originalOverflow;

      if (scrollBarWidth) {
        document.body.style.paddingRight = originalPaddingRight;
      }
    };
  }, [locked]);

  // Update state if initialValue changes
  useEffect(() => {
    if (locked !== initialLocked) {
      setLocked(initialLocked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLocked]);

  return [locked, setLocked];
};
