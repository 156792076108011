import {
  PostAIChatConversationsBody,
  PostAIChatFeedbackBody,
} from '@miri-unicorn/miricanvas-api-client';

export const aiChatbotQueryKeys = {
  getAIChatConversationsLatest: () => ['getAIChatConversationsLatest'],
  getAiChatTeamQuota: (teamIdx: number) => ['getAiChatTeamQuota', teamIdx],
  postAIChatConversations: (params: PostAIChatConversationsBody) => [
    'postAIChatConversations',
    params,
  ],
  postAIChatFeedback: (params: PostAIChatFeedbackBody) => ['postAIChatFeedback', params],
};
