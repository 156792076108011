import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import {
  GTMTriggerEventParams,
  GTMUserParams,
} from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionOverwritePrintoutTemplate = GAParam<GTMTriggerEventParams & GTMUserParams>;
export const makeGAParamsActionOverwritePrintoutTemplate: GAParameterMakerFnType<
  'ACTION_OVERWRITE_PRINTOUT_TEMPLATE'
> = (payload) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '인쇄물제작하기',
    location2: '3뎁스',
    location3: '템플릿 덮어쓰기',
    action1: '뷰',
    label1: payload.data.template_idx,
    label2: payload.data.template_type_id,
    account_id: payload.data.account_id,
  } satisfies ActionOverwritePrintoutTemplate;
};
