import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import {
  GTMTriggerEventParams,
  GTMUserParams,
} from '@configs/google_analytics/types/GTMTriggerEventParams';

export const makeGAParamsActionGlobalTrialWelcomePopupButton: GAParameterMakerFnType<
  'ACTION_CLICK_GLOBAL_TRIAL_WELCOME_POPUP_BUTTON'
> = ({ data: { location1 } }) =>
  ({
    log_type: 'generic_event_mican',
    event: 'gtm_trigger_event',
    location1,
    location2: '웰컴팝업',
    location3: 'CTA',
    action1: '클릭',
    label1: '온보딩_웰컴팝업_CTA _클릭',
  } satisfies ActionGlobalTrialWelcomePopupButtonReturnPayload);

export type ActionGlobalTrialWelcomePopupButtonPayload = {
  location1: '메인페이지' | '템플릿페이지' | '에디터페이지';
};
export type ActionGlobalTrialWelcomePopupButtonReturnPayload = GAParam<
  GTMTriggerEventParams & GTMUserParams
>;
