import { ReactElement, ReactNode } from 'react';
import { SERVER_ENV_KEY } from './ServerEnv';

export const ServerEnvProvider = ({
  serverEnv,
  children,
}: {
  serverEnv?: object;
  children: ReactNode;
}) => (
  <>
    {serverEnv && (
      <script
        dangerouslySetInnerHTML={{
          __html: `window.${SERVER_ENV_KEY} = ${JSON.stringify(serverEnv)};`,
        }}
      />
    )}
    {children}
  </>
);
