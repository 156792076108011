const COMMON_GA_EVENTS = [
  'gtm_trigger_event',
  'size_trigger_event',
  'ma_sign_up',
  'ma_design_save',
  'ma_design_share',
];
const COMMON_GA_EVENT_PARAM_FIELD_NAMES = [
  'location1',
  'location2',
  'location3',
  'location4',
  'action1',
  'action2',
  'action3',
  'action4',
  'label1',
  'label2',
  'label3',
  'label4',
];

export const optimizeGAParams = (params: any) => {
  try {
    const result: typeof params = {};

    Object.entries(params).forEach(([key, value]) => {
      if (value === undefined || value === null) {
        result[key] = value;
      } else {
        result[key] = typeof value === 'string' ? value.trim().replaceAll('\n', '') : String(value);
      }
    });

    if (COMMON_GA_EVENTS.includes(params.event)) {
      fillUndefinedIntoCommonEventParams(result);
    }

    return result;
  } catch (e) {
    console.error('GA params optimize error', e);
    return params;
  }
};

const fillUndefinedIntoCommonEventParams = (params: any) => {
  COMMON_GA_EVENT_PARAM_FIELD_NAMES.forEach((fieldName) => {
    params.hasOwnProperty(fieldName) || (params[fieldName] = undefined);
  });
};
