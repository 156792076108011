import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickTextStyleRecommend = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionPatternBackgroundSize: GAParameterMakerFnType<
  'ACTION_CLICK_PATTERN_BACKGROUND_SIZE'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '배경.패턴',
    location3: '배경편집',
    location4: '패턴크기',
    action1: '클릭',
    label1: '배경_패턴_패턴크기',
  } satisfies ActionClickTextStyleRecommend;
};
