import { Currency } from '@miri-unicorn/miricanvas-api-client';
import { EVENT_TYPES, EventType } from '@event/_types/EventType';
import { UpgradePopupBillingCycle } from '@ui/common/payment_upgrade_popup/types/UpgradePopupTypes';

export const UpgradePopupFlowStep: Record<number, string> = {
  /** 업그레이드 팝업의 1뎁스 */
  0: '프로평가판사용',
  /** 업그레이드 팝업의 2뎁스 */
  1: '프로평가판신청',
} as const;

/** 업그레이드 팝업에서 GA를 전송해야하는 액션(개발자 기준으로는 이벤트)들 */
export const TriggerUpgradePopup = {
  /** VIEW가 보일때 */
  VIEW: '뷰',
  /** 다음 depth로 넘어가는 버튼을 클릭할때(다음이 결제인 경우는, 결제가 성공/실패 될 경우 트리거됨) */
  CLICK: '클릭',
} as const;

/**
 * 업그레이드 팝업에서 GA가 보내지는 시점에 발생할 수 있는 이벤트 타입 모음
 */
export type UpgradePopupEventType = Extract<
  EventType,
  | typeof EVENT_TYPES.ACTION_USE_PREMIUM_CONTENTS_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_DOWNLOAD_PREMIUM_RESOURCE_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_CLICK_UPLOAD_INFO_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_ADD_RESOURCE_TO_FOLDER_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_MAKE_TEMPLATE_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_REVERT_DESIGN_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_REVERT_DESIGN_INDIVIDUAL_VERSION_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_COPY_DESIGN_HISTORY_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_REMOVE_BACKGROUND_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_CHANGE_VIEW_MODE_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_PUBLISH_DESIGN_WEB_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_INVITE_MEMBER_CHANGE_ROLE_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_CREATE_UPLOAD_FOLDER_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_CHANGE_ANIMATION_APPEARANCE_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_CHANGE_ANIMATION_SPEED_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_CHANGE_ANIMATION_DIRECTION_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_CHANGE_ANIMATION_OUTRO_DIRECTION_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_CHANGE_ANIMATION_DELAY_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_CHANGE_ANIMATION_UNIT_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_DOWNLOAD_MP4_RESTRICTION_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_DOWNLOAD_GIF_RESTRICTION_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_SHARE_PREMIUM_CONTENTS_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_UPLOAD_FONT_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_USE_BRAND_KIT_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_SET_CLAM_PAGE_ANIMATION_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_SET_FANCY_PAGE_ANIMATION_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_SET_CUTE_PAGE_ANIMATION_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_SET_SOPHISTICATED_PAGE_ANIMATION_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_DOWNLOAD_VIDEO_COUNT_RESTRICTION_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_CLICK_HEADER_PRO_INFO_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_CLICK_PICK_BANNER_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_CLICK_TEMPLATE_SEARCH_HEADER_BANNER_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_CLICK_VIDEO_SEARCH_HEADER_BANNER_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_CLICK_AUDIO_SEARCH_HEADER_BANNER_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_CLICK_TEMPLATE_SEARCH_FILTER_BANNER_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_CLICK_VIDEO_SEARCH_FILTER_BANNER_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_CLICK_AUDIO_SEARCH_FILTER_BANNER_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_CLICK_TEXT_SEARCH_FILTER_BANNER_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_CLICK_GENERATE_AI_PRESENTATION_OUTLINE_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_AI_TOOLS_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_OBJECT_REMOVAL_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_UPSCALE_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_INPAINTING_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_OPEN_UNKNOWN_FLOW_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_OPEN_GLOBAL_TRIAL_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_CLICK_DOWNGRADE_POPUP_SUBSCRIBE_BUTTON
  | typeof EVENT_TYPES.ACTION_CLICK_MY_LIST_PICK_BANNER_UPGRADE_POPUP
  | typeof EVENT_TYPES.ACTION_USE_MY_LIST_PREMIUM_CONTENTS_UPGRADE_POPUP
>;

export type UpgradePopupFlowStepType = ValueOf<typeof UpgradePopupFlowStep>;
export type TriggerUpgradePopupType = ValueOf<typeof TriggerUpgradePopup>;

/**
 * 요금제 업그레이드 팝업창 흐름에서 중에 보내야하는 Param
 * @docs https://docs.google.com/spreadsheets/d/1abtbcgMT_7gA1hOoBrIWww63pgcuanpGUqSLAbdZWFI/edit#gid=1682274814
 */
export type ActionUpgradePopupPayload = {
  /** 진입 경로1 or 기능 제약 대분류..? */
  flow_name1: string | undefined;
  /** (자세한) 진입 경로2 */
  flow_name2: string | undefined;
  /**
   * 업그레이드 팝업에 어떤 VIEW인가를 나타냄
   * 0 -> 업그레이드 팝업 1뎁스
   * 1 -> 업그레이드 팝업 2뎁스
   */
  flow_type: number;
  /**
   * VIEW -> VIEW가 보일때 GA 트리거
   * CLICK -> 다음 depth로 넘어가는 버튼을 클릭할때(다음이 결제인 경우는, 결제가 성공/실패 되고나서) GA 트리거
   */
  action_type: TriggerUpgradePopupType;
  team_idx: number;
  billing_cycle: UpgradePopupBillingCycle;
  plan_type: string;
  currency: Currency;
  payment_orders_idx?: number;
  account_id: number;
  /** 프리미엄 템플릿으로 업그레이드 팝업을 발생시켰을 경우, 템플릿 idx를 ga 전송 시 같이 보내야함  */
  trial_template_key?: string;
  /** 프리미엄 템플릿으로 업그레이드 팝업을 발생시켰을 경우, 템플릿 목적을 ga 전송 시 같이 보내야함  */
  trial_template_purpose?: Array<string>;
};
