import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import {
  GTMTriggerEventParams,
  GTMUserParams,
} from '@configs/google_analytics/types/GTMTriggerEventParams';

export const makeGAParamsActionClickMainHeaderSignUpButton: GAParameterMakerFnType<
  'ACTION_CLICK_MAIN_HEADER_SIGN_UP_BUTTON'
> = (payload) => {
  const location1 = payload.data?.isTemplatePage ? '탬플릿페이지' : '메인페이지';

  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: location1,
    location2: '헤더StartForFree',
    action1: '클릭',
    label1: '메인페이지/템플릿페이지_헤더_StartForFree_클릭',
  } satisfies ActionClickMainHeaderSignUpButtonReturnPayload;
};

export type ActionClickMainHeaderSignUpButtonPayload = {
  isTemplatePage?: boolean;
};
export type ActionClickMainHeaderSignUpButtonReturnPayload = GAParam<
  GTMTriggerEventParams & GTMUserParams
>;
