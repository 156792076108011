import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickHeaderFolderButtonParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickHeaderFolderButton: GAParameterMakerFnType<
  'ACTION_CLICK_HEADER_FOLDER_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '상단바',
    location3: '내드라이브',
    action1: '클릭',
    label1: '에디터_상단바_내드라이브',
  } satisfies ActionClickHeaderFolderButtonParams;
};
