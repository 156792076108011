import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionViewRecommendedTemplateInChatBot: GAParameterMakerFnType<
  'ACTION_VIEW_RECOMMENDED_TEMPLATE_IN_CHATBOT'
> = (payload) => ({
  log_type: 'view_template_chatbot',
  event: 'view_template_chatbot',
  conversation_id: payload.data.conversation_id,
  account_id: payload.data.account_id,
  team_idx: payload.data.team_idx,
  design_idx: payload.data.design_idx,
  plan_type: payload.data.plan_type,
  last_search_term: payload.data.last_search_term,
  preferred_language: payload.data.preferred_language,
  inflow_type: payload.data.inflow_type,
  view_template_key: payload.data.viewedTemplateKeyList.join(','),
});
