type Point = {
  x: number;
  y: number;
};

export const getVector = (point1: Point, point2: Point) => {
  const diffX = point2.x - point1.x;
  const diffY = point2.y - point1.y;
  const getScalar = (x: number, y: number) => Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));

  return {
    dx: diffX,
    dy: diffY,
    getScalar,
  };
};

export const clamp = (value: number, min?: number, max?: number) =>
  Math.min(Math.max(value, min ?? Number.MIN_SAFE_INTEGER), max ?? Number.MAX_SAFE_INTEGER);

export const toFixed = (num: number, fixed: number) =>
  Math.round(num * Math.pow(10, fixed)) / Math.pow(10, fixed);
