import { ReactNode, createContext, useState } from 'react';
import { ConfirmModal, ConfirmModalProps } from '@miri-unicorn/miricanvas-ds';
import { useBoolean } from '@utils/hooks';

export type OpenConfirmFn = (props: Omit<ConfirmModalProps, 'onClose'>) => void;
export type UpdateConfirmModalPropsFn = (
  props: Partial<Omit<ConfirmModalProps, 'onClose'>>
) => void;

export const ConfirmModalContext = createContext<{
  openConfirmModal: OpenConfirmFn;
  updateConfirmModalProps: UpdateConfirmModalPropsFn;
} | null>(null);

type ConfirmModalProviderProps = {
  children: ReactNode;
};

/**
 *  @todo
 *   _app 에 깔려 , 내부의 state 가 바뀌면 전체가 리렌더링 되는 현상을 최적화 할 필요가 있음
 */
export const ConfirmModalProvider = ({ children }: ConfirmModalProviderProps) => {
  const { value: isOpen, setTrue: openModal, setFalse: closeModal } = useBoolean();

  const [confirmModalProps, setConfirmModalProps] = useState<ConfirmModalProps>({
    onClose: closeModal,
    title: '',
    cancelText: '',
    confirmText: '',
    // for initialize
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onConfirm: () => {},
  });

  const openConfirmModal = (props: Omit<ConfirmModalProps, 'onClose'>) => {
    openModal();
    setConfirmModalProps({ ...props, onClose: closeModal });
  };

  const updateConfirmModalProps = (props: Partial<Omit<ConfirmModalProps, 'onClose'>>) => {
    setConfirmModalProps((prev) => ({ ...prev, ...props, onClose: closeModal }));
  };

  return (
    <ConfirmModalContext.Provider value={{ openConfirmModal, updateConfirmModalProps }}>
      {isOpen && <ConfirmModal {...confirmModalProps} />}
      {children}
    </ConfirmModalContext.Provider>
  );
};
