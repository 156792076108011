export type ServerEnv = {
  test?: string;
};

export const SERVER_ENV_KEY = '__MICAN_SERVER_ENV__';

declare const window: Window & {
  [SERVER_ENV_KEY]: ServerEnv;
};

const DEFAULT_SERVER_ENV: ServerEnv = {
  test: '',
};

export const getServerEnv = (): ServerEnv => {
  if (typeof window !== 'undefined') {
    return window[SERVER_ENV_KEY] ?? DEFAULT_SERVER_ENV;
  } else {
    return DEFAULT_SERVER_ENV;
  }
};
