import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import {
  GTMTriggerEventParams,
  GTMUserParams,
} from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickPropertyElementSelect = GAParam<GTMTriggerEventParams & GTMUserParams>;

export const makeGAParamsActionClickPropertyOverlappingElementSelect: GAParameterMakerFnType<
  'ACTION_CLICK_PROPERTY_OVERLAPPING_ELEMENT_SELECT'
> = (payload) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '요소속성',
    location3: '겹침요소',
    action1: '클릭',
    label1: '요소속성_겹침요소',
    account_id: payload.data.account_id,
    device_type: payload.data.device_type,
  } satisfies ActionClickPropertyElementSelect;
};
