import {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  useQuery as _useQuery,
} from '@tanstack/react-query';

export type UseQueryProps<TQueryFnData = unknown, TError = unknown, TData = TQueryFnData> = {
  queryKey: QueryKey;
  queryFn: QueryFunction<TQueryFnData>;
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'suspense'>;
};

export const useQuery = <TQueryFnData, TError = undefined, TData = TQueryFnData>(
  props: UseQueryProps<TQueryFnData, TError, TData>
) => {
  const { queryKey, queryFn, options: queryOptions } = props;

  return _useQuery(queryKey, queryFn, {
    ...queryOptions,
    suspense: false,
  });
};
