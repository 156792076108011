import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickDownloadMobileButtonParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickDesignDownloadButton: GAParameterMakerFnType<
  'ACTION_CLICK_DESIGN_DOWNLOAD_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '상단바',
    location3: '다운로드',
    location4: '다운로드버튼',
    action1: '클릭',
    label1: '상단바_다운로드_다운로드버튼_클릭',
  } satisfies ActionClickDownloadMobileButtonParams;
};

/**
 * @description 앱 (현재는 태블릿 제외)에서만 보여지는 네이티브 공유 버튼 클릭 시
 */
export const makeGAParamsActionClickDesignShareButton: GAParameterMakerFnType<
  'ACTION_APP_DESIGN_SHARE'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '상단바',
    location3: '다운로드',
    location4: '네이티브공유버튼',
    action1: '클릭',
    label1: '상단바_다운로드_네이티브공유버튼_클릭',
  } satisfies ActionClickDownloadMobileButtonParams;
};
