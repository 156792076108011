import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickMainPageSignUpCTA: GAParameterMakerFnType<
  'ACTION_CLICK_MAIN_PAGE_SIGN_UP_CTA'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '메인페이지',
    location2: '중간StartForFree',
    action1: '클릭',
    label1: '메인페이지_중간_StartForFree_클릭',
  };
};
