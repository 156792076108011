import {
  GetPersonalTemplateListParams,
  GetRecentTemplateListParams,
  GetSimilarTemplateListParams,
  Tier,
} from '@miri-unicorn/miricanvas-api-client';
import { UseDesignTemplateListParams } from '@business/design_resource/template/_queries/useDesignTemplateListQuery';
import { UseTemplateTypeParams } from '@business/design_resource/template/_queries/useTemplateTypeListQuery';
import { LanguageType } from '@constants/LanguageType';
import { UseKeywordCategoryTemplateListInfiniteQueryParams } from './useKeywordCategoryTemplateListInfiniteQuery';
import { UsePersonalizedTemplateListInfinityQueryParams } from './usePersonalizedTemplateListInfinityQuery';
import { UseRerankTemplateListParams } from './useRerankTemplateListQuery';
import { UseTemplateListParams } from './useTemplateListQuery';

export const templateQueryKeys = {
  /**
   * TODO: queryKey 컨벤션 문서화
   * queryKey의 예시입니다.
   * - queryKey는 사용하는 api 함수 이름 과 prarms를 배열로 키를 생성합니다.
   *      -['사용하는 api 함수 이름', params]
   * - infinite 쿼리를 사용할 경우 key에 xxxInfinite로 명시해줍니다.
   */
  getTemplateList: (params: UseTemplateListParams) => ['getTemplateList', params] as const,
  getTemplateListInfiniteByType: (params: UseTemplateListParams) =>
    ['getTemplateListInfiniteByType', params] as const,
  getTemplateListInfinite: (
    params: Omit<UseTemplateListParams, 'tier'> & { tier?: Tier | Tier[] }
  ) => ['getTemplateListInfinite', params] as const,
  getTemplateByIndex: (templateIdx: number, language: LanguageType) =>
    ['getTemplateByIndex', templateIdx, language] as const,
  getTemplateTypeList: (params: UseTemplateTypeParams) => ['getTemplateTypeList', params] as const,
  // getTemplateTypes: (params: TemplateTypeParams) => ['getTemplateType', params] as const,
  getDesignTemplateList: (params: UseDesignTemplateListParams) =>
    ['getDesignTemplateList', params] as const,
  getRecentTemplateList: (params: GetRecentTemplateListParams) =>
    ['getRecentTemplateList', params] as const,
  getRecentTemplateListDefault: () => ['getRecentTemplateList'] as const,
  getSimilarTemplateList: (
    templateIdx: number,
    params: Omit<GetSimilarTemplateListParams, 'page'>
  ) => ['getSimilarTemplateList', templateIdx, params] as const,
  postRerankTemplateList: (params: UseRerankTemplateListParams) =>
    ['getRerankingTemplateList', params] as const,
  getUserGroupForRerankTemplates: () => ['getUserGroupForRerankTemplates'] as const,
  getPersonalizedTemplateList: (params: GetPersonalTemplateListParams) =>
    ['getPersonalizedTemplateList', params] as const,
  getPersonalizedTemplateListInfinite: (params: UsePersonalizedTemplateListInfinityQueryParams) =>
    ['getPersonalizedTemplateListInfinite', params] as const,
  getKeywordCategoryTemplateListInfinite: (
    params: UseKeywordCategoryTemplateListInfiniteQueryParams
  ) => ['getKeywordCategoryTemplateListInfinite', params] as const,
};
