import { RefObject, useEffect, useRef } from 'react';
import { addOnClickOutsideEventListener } from '@utils/functions/element_util';

type Handler = (event: MouseEvent) => void;

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: Handler
): void => {
  const handlerRef = useRef<Handler>(handler);
  handlerRef.current = handler;

  useEffect(() => {
    if (!ref?.current) {
      return;
    }
    return addOnClickOutsideEventListener(ref.current, handlerRef.current);
  }, [ref]);
};
