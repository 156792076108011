import { useMutation } from '@configs/react-query';
import {
  login,
  requestKeepLogin,
  logout,
  signUp,
  sendAuthMail,
  sendPasswordRestoreEmail,
  setUserLanguage,
} from '@miri-unicorn/miricanvas-api-client';

export const useUserMutation = () => {
  const loginUserMutation = useMutation(login);
  const autoLoginMutation = useMutation(requestKeepLogin);
  const logoutUserMutation = useMutation(logout);
  const signUpMutation = useMutation(signUp);
  const sendAuthMailMutation = useMutation(sendAuthMail);
  const sendPasswordRestoreMailMutation = useMutation(sendPasswordRestoreEmail);
  const setUserLanguageMutation = useMutation(setUserLanguage);

  return {
    loginUserMutation,
    logoutUserMutation,
    autoLoginMutation,
    signUpMutation,
    sendAuthMailMutation,
    sendPasswordRestoreMailMutation,
    setUserLanguageMutation,
  };
};
