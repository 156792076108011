import { Provider, createStore } from 'jotai';
import { ReactNode, useRef } from 'react';

export const RootProvider = ({ children }: { children: ReactNode }) => {
  const storeRef = useRef<ReturnType<typeof createStore>>();

  if (!storeRef.current) {
    storeRef.current = createRootStore();
  }

  return <Provider store={storeRef.current}>{children}</Provider>;
};

let rootStore: ReturnType<typeof createStore>;

const createRootStore = () => {
  rootStore = createStore();
  return rootStore;
};

export const getRootStore = () => {
  return rootStore;
};
