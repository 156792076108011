import { useEffect, useRef, useState } from 'react';

export type Position = {
  width: number;
  height: number;
  top: number;
  right: number;
  left: number;
  bottom: number;
};

export const INITIAL_POSITION = {
  width: 0,
  height: 0,
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
};

export const usePosition = <T extends HTMLElement>() => {
  const ref = useRef<T>(null);
  const [position, setPosition] = useState<Position>(INITIAL_POSITION);

  useEffect(() => {
    if (ref.current) {
      const { width, height, top, right, left, bottom } = ref.current.getBoundingClientRect();
      setPosition({ width, height, top, right, left, bottom });
    }
  }, []);

  return {
    ref,
    position,
  };
};
