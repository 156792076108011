import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickAIWritingEditTextButton: GAParameterMakerFnType<
  'ACTION_CLICK_AI_WRITING_EDIT_TEXT_BUTTON'
> = () => ({
  log_type: 'generic_event_mican',
  event: 'gtm_trigger_event',
  location1: '에디터',
  location2: '텍스트 박스',
  location3: 'AI 라이팅',
  action1: '클릭',
  label1: '에디터_AI라이팅_수정',
});
