import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { getSearchActionStatus } from '@configs/google_analytics/utils/getSearchStatus';

export const makeGAParamsActionClickElementItemInCollectionItem: GAParameterMakerFnType<
  'ACTION_CLICK_ELEMENT_ITEM_IN_COLLECTION_ITEM'
> = (payload) => {
  return {
    event: 'mican_collection',
    log_type: 'mican_collection',
    search_status: getSearchActionStatus(payload.data.keyword),
    search_type: payload.data.search_type,
    search_term: payload.data.keyword,
    collection_id: payload.data.collectionId,
    search_keyword_category: payload.data.keywordCategoryName,
    element_id: payload.data.elementId,
    action_type: '요소',
  };
};
