import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickPageNumberButtonParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickPageNumberButton: GAParameterMakerFnType<
  'ACTION_CLICK_PAGE_NUMBER_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '상단바',
    location3: '페이지번호추가',
    action1: '클릭',
    label1: '설정_페이지번호',
  } satisfies ActionClickPageNumberButtonParams;
};
