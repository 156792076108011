import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickFontFilter = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionSolidColorPicker: GAParameterMakerFnType<
  'ACTION_CLICK_SOLID_COLOR_PICKER'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '색상팝업',
    location3: '색상탭',
    location4: '직접조정',
    action1: '클릭',
    label1: '에디터_색상팝업_색상_직접조정',
  } satisfies ActionClickFontFilter;
};
