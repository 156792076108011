import { useState } from 'react';
import { DISABLED_VARIABLE } from '@configs/ab-test/_constants/abVariables';
import { useAbBucketingMutation } from '@configs/ab-test/_query/useAbBucketingMutation';
import {
  getAbBucketLocalStorageValue,
  useSetAbBucketLocalStorage,
} from '@configs/ab-test/_stores/useAbBucketLocalStorageStore';
import {
  AbValue,
  PostAbBucketingDto,
  PostAbResponseSchemaType,
} from '@configs/ab-test/_types/type';
import { isEqual } from '@utils/functions/lodash_util';
import { LogUtil } from '@utils/functions/log_util';
import { useIsomorphicLayoutEffect } from '@utils/hooks';

/**
 * @description AB 테스트 버켓팅
 */
export const useAbBucketing = () => {
  const [abBucketValue, setAbBucketValue] = useState<AbValue>({});
  const [isFetched, setIsFetched] = useState<boolean>(false);
  const { setAbBucketLocalStorage } = useSetAbBucketLocalStorage();
  const { abBucketingMutationAsync } = useAbBucketingMutation();

  useIsomorphicLayoutEffect(() => {
    (async () => {
      try {
        const abBucketLocalStorage = getAbBucketLocalStorageValue();
        const parameters = abBucketValueToAbBucketingParameters(abBucketLocalStorage as AbValue);
        const response = await abBucketingMutationAsync(parameters);
        const newAbStorageValue = abResponseToAbValue(response.data);
        setAbBucketValue(newAbStorageValue);

        if (!isEqual(abBucketLocalStorage, newAbStorageValue)) {
          void setAbBucketLocalStorage(newAbStorageValue);
        }
      } catch (err) {
        LogUtil.error.dev('useAbBucketing', err);
      } finally {
        setIsFetched(true);
      }
    })();
  }, [abBucketingMutationAsync, setAbBucketLocalStorage]);

  return { data: abBucketValue, isFetched };
};

/**
 * 로컬스토리지에 저장된 AbValue 를 API 요청 파라미터 형태로 포맷팅
 * @returns {PostAbBucketingDto}
 * */
const abBucketValueToAbBucketingParameters = (abBucketValue: AbValue): PostAbBucketingDto => {
  const parameters: PostAbBucketingDto = {
    domain: 'MIRICANVAS',
    flagSettings: [],
  };

  if (abBucketValue) {
    Object.keys(abBucketValue).forEach((flagKey) => {
      if (abBucketValue[flagKey]?.variationKey !== DISABLED_VARIABLE) {
        parameters.flagSettings?.push({
          flagKey,
          forcedVariationKey: abBucketValue[flagKey]?.variationKey,
          isNotCalculateStride: true,
        });
      }
    });
  }

  return parameters;
};

/**
 * API 응답을 로컬스토리지에 저장된 값과 동일한 AbValue 형태로 포맷팅
 * @returns {AbValue | {}}
 * */
const abResponseToAbValue = (response: PostAbResponseSchemaType[] | undefined) => {
  const newAbStorageValue = {} as AbValue;
  if (response) {
    response.forEach((v) => {
      if (v.variationKey) {
        /**
         newAbStorageValue[v.flagKey] = {
           description: v.variationDescription,
           index: v.variationIndex,
           ratio: v.variationRatio,
           startDate: v.flagStartDate,
           variationKey: v.variationKey,
         };
         위와 같이 작성하면 serialize 영향으로 무한 리렌더링이 발생함.
         @link https://github.com/miridih/cpdev2-lupin/pull/1859#discussion_r1494134489
         */
        newAbStorageValue[v.flagKey] = {};
        if (v.variationDescription !== undefined) {
          newAbStorageValue[v.flagKey]!.description = v.variationDescription;
        }
        if (v.variationIndex !== undefined) {
          newAbStorageValue[v.flagKey]!.index = v.variationIndex;
        }
        if (v.variationRatio !== undefined) {
          newAbStorageValue[v.flagKey]!.ratio = v.variationRatio;
        }
        if (v.flagStartDate !== undefined) {
          newAbStorageValue[v.flagKey]!.startDate = v.flagStartDate;
        }
        if (v.variationKey !== undefined) {
          newAbStorageValue[v.flagKey]!.variationKey = v.variationKey;
        }
      }
    });
  }

  return newAbStorageValue;
};
