/**
 * 현재 미리캔버스 사이트에서 제공하는 "언어" 목록을 정의한 타입
 * @see http://www.lingoes.net/en/translator/langcode.htm
 */
export const LANGUAGE_TYPE_LIST = ['ko', 'en', 'ja', 'de', 'es', 'fr', 'it', 'nl', 'pt'] as const;

/**
 * 현재 서비스 중인 언어 LanguageType 저장하고 있는 배열
 * 언어별 탭을 그려주는 순서를 보장해주는 배열이기 때문에 새로운 언어가 추가될 경우 순서에 맞게 넣어주어야 함
 * @important single source of truth
 */
export type LanguageType = (typeof LANGUAGE_TYPE_LIST)[number];

/**
 * 'ko' 보단 LanguageEnum.KO 로 사용하길 추천
 */
export const LanguageEnum = {
  KO: 'ko',
  EN: 'en',
  JA: 'ja',
  DE: 'de',
  ES: 'es',
  FR: 'fr',
  IT: 'it',
  NL: 'nl',
  PT: 'pt',
} as const satisfies Record<Uppercase<LanguageType>, LanguageType>;

/**
 * 국가코드 Enum
 * @see https://countrycode.org/
 * KR: 대한민국
 * US: 미국
 * JP: 일본
 * CA: 캐나다
 * AU: 호주
 * DE: 독일
 * ES: 스페인
 * FR: 프랑스
 * IT: 이탈리아
 * NL: 네덜란드
 * PT: 포르투갈
 * CN: 중국
 * KE: 케냐
 * ... 등등의 값이 있다.
 */
export const CountryEnum = {
  KR: 'KR',
  US: 'US',
  JP: 'JP',
  CA: 'CA',
  AU: 'AU',
  DE: 'DE',
  ES: 'ES',
  FR: 'FR',
  IT: 'IT',
  NL: 'NL',
  PT: 'PT',
  CN: 'CN',
  KE: 'KE',
} as const;
export type CountryCode = keyof typeof CountryEnum;
