import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type GAParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickPropertyAlign: GAParameterMakerFnType<
  'ACTION_CLICK_PROPERTY_ALIGN'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '요소속성',
    location3: '정렬',
    action1: '클릭',
    label1: '요소속성_정렬',
  } satisfies GAParams;
};

export const makeGAParamsActionClickPropertyZIndex: GAParameterMakerFnType<
  'ACTION_CLICK_PROPERTY_ZINDEX'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '요소속성',
    location3: '순서',
    action1: '클릭',
    label1: '요소속성_순서',
  } satisfies GAParams;
};

export const makeGAParamsActionClickPropertyFlip: GAParameterMakerFnType<
  'ACTION_CLICK_PROPERTY_FLIP'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '요소속성',
    location3: '반전',
    action1: '클릭',
    label1: '요소속성_반전',
  } satisfies GAParams;
};

export const makeGAParamsActionClickPropertyCrop: GAParameterMakerFnType<
  'ACTION_CLICK_PROPERTY_CROP'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '요소속성',
    location3: '자르기',
    action1: '클릭',
    label1: '요소속성_자르기',
  } satisfies GAParams;
};
