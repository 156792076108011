import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickMiricanvasLogoButton = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickMiricanvasLogoButton: GAParameterMakerFnType<
  'ACTION_CLICK_MIRICANVAS_LOGO_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '상단바',
    location3: '미리캔버스로고',
    action1: '클릭',
    label1: '에디터_상단바_미리캔버스로고',
  } satisfies ActionClickMiricanvasLogoButton;
};
