import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickGlobalMainPageStartNowButton: GAParameterMakerFnType<
  'ACTION_CLICK_GLOBAL_MAIN_PAGE_START_NOW_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '메인페이지',
    location2: '하단_디자인 시작',
    action1: '클릭',
    label1: '메인_하단_디자인시작',
  };
};
