import { BuildEnv } from './BuildEnv';

export const envSuffix =
  BuildEnv.NEXT_PUBLIC_ENV === 'production' ? '' : (`-${BuildEnv.NEXT_PUBLIC_ENV}` as const);
const bizSuffix = BuildEnv.NEXT_PUBLIC_ENV === 'production' ? '' : 'ab';

export const S3 = {
  webPrefix: `https://file${envSuffix}.miricanvas.com/`,
} as const;

export const API_URL =
  BuildEnv.NEXT_PUBLIC_USE_PROXY === 'Y'
    ? '/proxy'
    : (`https://api${envSuffix}.miricanvas.com` as const);

export const SUB_DOMAIN = BuildEnv.IS_LOCAL
  ? 'local'
  : BuildEnv.NEXT_PUBLIC_ENV === 'production'
  ? 'www'
  : BuildEnv.NEXT_PUBLIC_ENV;

export const MIRICANVAS_URL = `https://${SUB_DOMAIN}.miricanvas.com` as const;

export const getPrintoutModuleUrl = ({ suffix = bizSuffix }: { suffix?: string } = {}) =>
  `https://${
    suffix ? `${suffix}-` : ''
  }www.bizhows.com/out/app/lib-4-miricanvas/lib4Miricanvas.loader.mjs` as const;

export const PAYMENT_GATEWAY_API_URL =
  BuildEnv.NEXT_PUBLIC_PAYMENT_GATEWAY_API_URL || 'https://api-payment.miridih.com';

export const PAYMENT_GATEWAY_SCRIPT_URL =
  BuildEnv.NEXT_PUBLIC_PAYMENT_GATEWAY_SCRIPT_URL ||
  'https://api-payment.miridih.com/pg/prepare.js';

/**
 * TODO: 이 V2_BASE_PATH 제거 시 middleware.ts의 상단에 정의된 config에 존재하는 "/v2" 부분 같이 제거하여야 함!
 * (config 내 Url 정의 시 템플릿 리터럴 사용하지 못해서 V2_BASE_PATH 상수 활용하는 구조로 정의하지 못함.)
 */
export const V2_BASE_PATH = '/v2';

/**
 * editor init 할 때, editorProps 에 아래 url 들을 넘겨줘야 미캔에서 달력만들기 같은 비즈하우스 기능을 사용할 수 있다.
 */
const BIZHOWS_API_URL_PREFIX = BuildEnv.IS_PRODUCTION ? '' : 'ab-';
export const BIZHOWS_API_URL = `https://${BIZHOWS_API_URL_PREFIX}www.bizhows.com` as const;
export const BIZHOWS_IMAGE_SERVER = 'https://img1.bizhows.com';

export const AI_CREATION_SCRIPT_URL =
  `https://aicreation${envSuffix}.miricanvas.com/libs/client.js` as const;
