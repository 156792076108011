import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type NewFeatureAIPresentationUse = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickNewFeatureAIPresentationUse: GAParameterMakerFnType<
  'ACTION_CLICK_NEW_FEATURE_AI_PRESENTATION_USE_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '미캔소식팝업',
    location3: 'AI프레젠테이션-지금사용해보기',
    action1: '클릭',
    label1: '에디터_미캔소식팝업_AI프레젠테이션_지금사용해보기_클릭',
  } satisfies NewFeatureAIPresentationUse;
};
