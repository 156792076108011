import { PostAbBucketingDto, PostAbResponseSchemaType } from '@configs/ab-test/_types/type';
import { envSuffix } from '@configs/environments/base_url';
import { useMutation } from '@configs/react-query';

/**
 * 비즈의 ab testing api
 * @see https://miridih.atlassian.net/wiki/spaces/~jhha/pages/361634616/Bucketing+API+-+AB
 * */
const AB_TESTING_API_URL =
  `https://api${envSuffix}.ab-testing.miricanvas.com/v1/api/ab/bucketing` as const;

export const useAbBucketingMutation = () => {
  const abBucketingMutation = useMutation(async (parameters: PostAbBucketingDto) => {
    const response = await fetch(AB_TESTING_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(parameters),
      signal: AbortSignal.timeout(3000),
    });
    return (await response.json()) as { data: PostAbResponseSchemaType[] };
  });

  return { abBucketingMutationAsync: abBucketingMutation.mutateAsync };
};
