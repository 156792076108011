import { css } from 'styled-components';
import type { StyledObject } from 'styled-components';

/**
 * 디자인 페이지에서 사용하는 미디어 쿼리
 * - 현재 헤더바에서 사용 중 입니다.
 */

export const X_LARGE = 1440;
export const LARGE = 1200;
export const MEDIUM = 768;

const over1440Desktop = (styles: StyledObject) => {
  return css`
    @media screen and (min-width: ${X_LARGE}px) {
      ${styles};
    }
  `;
};

const over1200Desktop = (styles: StyledObject) => {
  return css`
    @media screen and (min-width: ${LARGE}px) {
      ${styles};
    }
  `;
};

const over768Desktop = (styles: StyledObject) => {
  return css`
    @media screen and (min-width: ${MEDIUM}px) {
      ${styles};
    }
  `;
};

/**
 * 디자인 페이지에서 사용하는 미디어 쿼리
 */
export const design_page_media = {
  over1440Desktop,
  over1200Desktop,
  over768Desktop,
};
