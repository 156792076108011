import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickShareMemberInviteTabButton = GAParam<GTMTriggerEventParams>;
type ActionClickShareMemberInviteEmailToggle = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionShareMemberInviteTabButton: GAParameterMakerFnType<
  'ACTION_CLICK_SHARE_MEMBER_INVITE_TAB_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '공유',
    location3: '사용자초대',
    action1: '클릭',
    label1: '공유_사용자초대',
  } satisfies ActionClickShareMemberInviteTabButton;
};

export const makeGAParamsActionShareMemberInviteEmailToggle: GAParameterMakerFnType<
  'ACTION_CLICK_SHARE_MEMBER_INVITE_EMAIL_TOGGLE'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '공유',
    location3: '사용자초대',
    location4: '이메일알림보내기',
    action1: '클릭',
    label1: '공유_사용자초대_이메일알림보내기',
  } satisfies ActionClickShareMemberInviteEmailToggle;
};
