import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickAIDesignAssistantPopupAIWritingButton: GAParameterMakerFnType<
  'ACTION_CLICK_AI_DESIGN_ASSISTANT_POPUP_AI_WRITING_BUTTON'
> = () => ({
  log_type: 'generic_event_mican',
  event: 'gtm_trigger_event',
  location1: '에디터',
  location2: '플로팅 버튼',
  location3: '메뉴-AI 라이팅',
  action1: '클릭',
  label1: '에디터_AI진입점_AI라이팅',
});
