import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickSharePreviewButton = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionSharePreviewButton: GAParameterMakerFnType<
  'ACTION_CLICK_SHARE_PREVIEW_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '공유',
    location3: '웹게시',
    location4: '미리보기',
    action1: '클릭',
    label1: '공유_미리보기',
  } satisfies ActionClickSharePreviewButton;
};

type ActionClickShareLinkCopyButton = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionShareLinkCopyButton: GAParameterMakerFnType<
  'ACTION_CLICK_SHARE_LINK_COPY_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '공유',
    location3: '복사',
    action1: '클릭',
    label1: '공유_복사',
  } satisfies ActionClickShareLinkCopyButton;
};

type ActionClickShareSNSFacebookButton = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionShareSNSFacebookButton: GAParameterMakerFnType<
  'ACTION_CLICK_SHARE_SNS_FACEBOOK_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '공유',
    location3: '페이스북',
    action1: '클릭',
    label1: '공유_SNS_페이스북',
  } satisfies ActionClickShareSNSFacebookButton;
};

type ActionClickShareSNSTwitterButton = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionShareSNSTwitterButton: GAParameterMakerFnType<
  'ACTION_CLICK_SHARE_SNS_TWITTER_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '공유',
    location3: '트위터',
    action1: '클릭',
    label1: '공유_SNS_트위터',
  } satisfies ActionClickShareSNSTwitterButton;
};

type ActionClickShareSNSKakaoTalkButton = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionShareSNSKakaoTalkButton: GAParameterMakerFnType<
  'ACTION_CLICK_SHARE_SNS_KAKAO_TALK_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '공유',
    location3: '카카오톡',
    action1: '클릭',
    label1: '공유_SNS_카카오톡',
  } satisfies ActionClickShareSNSKakaoTalkButton;
};

type ActionClickShareSNSKakaoStoryButton = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionShareSNSKakaoStoryButton: GAParameterMakerFnType<
  'ACTION_CLICK_SHARE_SNS_KAKAO_STORY_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '공유',
    location3: '카카오스토리',
    action1: '클릭',
    label1: '공유_SNS_카카오스토리',
  } satisfies ActionClickShareSNSKakaoStoryButton;
};

type ActionClickShareSNSNaverBlogButton = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionShareSNSNaverBlogButton: GAParameterMakerFnType<
  'ACTION_CLICK_SHARE_SNS_NAVER_BLOG_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '공유',
    location3: '네이버블로그',
    action1: '클릭',
    label1: '공유_SNS_네이버블로그',
  } satisfies ActionClickShareSNSNaverBlogButton;
};

type ActionClickShareSNSNaverBandButton = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionShareSNSNaverBandButton: GAParameterMakerFnType<
  'ACTION_CLICK_SHARE_SNS_NAVER_BAND_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '공유',
    location3: '네이버밴드',
    action1: '클릭',
    label1: '공유_SNS_네이버밴드',
  } satisfies ActionClickShareSNSNaverBandButton;
};

type ActionClickSharePageStyleDropdownButton = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionSharePageStyleDropdownButton: GAParameterMakerFnType<
  'ACTION_CLICK_SHARE_PAGE_STYLE_DROPDOWN_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '공유',
    location3: '페이지스타일',
    action1: '클릭',
    label1: '공유_페이지스타일',
  } satisfies ActionClickSharePageStyleDropdownButton;
};

type ActionClickSharePageStyleSlideButton = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionSharePageStyleSlideButton: GAParameterMakerFnType<
  'ACTION_CLICK_SHARE_PAGE_STYLE_SLIDE_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '공유',
    location3: '좌우슬라이드',
    action1: '클릭',
    label1: '공유_페이지스타일_좌우슬라이드',
  } satisfies ActionClickSharePageStyleSlideButton;
};

type ActionClickSharePageStyleScrollButton = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionSharePageStyleScrollButton: GAParameterMakerFnType<
  'ACTION_CLICK_SHARE_PAGE_STYLE_SCROLL_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '공유',
    location3: '상하스크롤',
    action1: '클릭',
    label1: '공유_페이지스타일_상하스크롤',
  } satisfies ActionClickSharePageStyleScrollButton;
};

type ActionClickSharePageStyleQuizeButton = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionSharePageStyleQuizeButton: GAParameterMakerFnType<
  'ACTION_CLICK_SHARE_PAGE_STYLE_QUIZE_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '공유',
    location3: '퀴즈심리테스트',
    action1: '클릭',
    label1: '공유_페이지스타일_퀴즈심리테스트',
  } satisfies ActionClickSharePageStyleQuizeButton;
};

type ActionClickShareDetailOptionButton = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionShareDetailOpitonButton: GAParameterMakerFnType<
  'ACTION_CLICK_SHARE_DETAIL_OPTION_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '공유',
    location3: '상세옵션',
    action1: '클릭',
    label1: '공유_상세옵션',
  } satisfies ActionClickShareDetailOptionButton;
};

type ActionClickShareDetailOptionCopyToggle = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionShareDetailOpitonCopyToggle: GAParameterMakerFnType<
  'ACTION_CLICK_SHARE_DETAIL_OPTION_COPY_TOGGLE'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '공유',
    location3: '원본문서복제허용On',
    action1: '클릭',
    label1: '공유_상세옵션_원본문서복제허용',
  } satisfies ActionClickShareDetailOptionCopyToggle;
};

type ActionClickShareDetailOptionLikeAndCommentToggle = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionShareDetailOpitonLikeAndCommentToggle: GAParameterMakerFnType<
  'ACTION_CLICK_SHARE_DETAIL_OPTION_LIKE_AND_COMMENT_TOGGLE'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '공유',
    location3: '좋아요댓글허용On',
    action1: '클릭',
    label1: '공유_상세옵션_좋아요댓글허용',
  } satisfies ActionClickShareDetailOptionLikeAndCommentToggle;
};

type ActionClickShareDetailOptionSlideShowToggle = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionShareDetailOpitonSlideShowToggle: GAParameterMakerFnType<
  'ACTION_CLICK_SHARE_DETAIL_OPTION_SLIDE_SHOW_TOGGLE'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '공유',
    location3: '슬라이드쇼보기On',
    action1: '클릭',
    label1: '공유_상세옵션_슬라이드쇼보기',
  } satisfies ActionClickShareDetailOptionSlideShowToggle;
};

type ActionClickShareDetailOptionShareToggle = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionShareDetailOpitonShareToggle: GAParameterMakerFnType<
  'ACTION_CLICK_SHARE_DETAIL_OPTION_SHARE_TOGGLE'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '공유',
    location3: '공유버튼On',
    action1: '클릭',
    label1: '공유_상세옵션_공유버튼',
  } satisfies ActionClickShareDetailOptionShareToggle;
};

type ActionCLickShareTemplateLinkShareButton = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionShareTemplateLinkShareButton: GAParameterMakerFnType<
  'ACTION_CLICK_SHARE_TEMPLATE_LINK_SHARE_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '공유',
    location3: '템플릿링크공유',
    action1: '클릭',
    label1: '공유_상세옵션_템플릿링크공유',
  } satisfies ActionCLickShareTemplateLinkShareButton;
};
