import { makeGAParamsActionByAbTest } from '@configs/ab-test/maker/makeGAParamsAddResourceToFolderUpgradePopup';
import { makeGAParamsActionUserInitialize } from '@configs/google_analytics/features/common/makers/makeGAParamsActionUserInitialize';
import { makeGAParamsActionClickColumnGuideLineButton } from '@configs/google_analytics/features/header_bar/main_menu/guide_line/makers/makeGAParamsActionClickColumnGuideLineButton';
import { makeGAParamsActionClickGuideLineLockToggle } from '@configs/google_analytics/features/header_bar/main_menu/guide_line/makers/makeGAParamsActionClickGuideLineLockToggle';
import { makeGAParamsActionClickGuideLineMenuButton } from '@configs/google_analytics/features/header_bar/main_menu/guide_line/makers/makeGAParamsActionClickGuideLineMenuButton';
import { makeGAParamsActionClickGuideLineResetButton } from '@configs/google_analytics/features/header_bar/main_menu/guide_line/makers/makeGAParamsActionClickGuideLineResetButton';
import { makeGAParamsActionClickGuideLineRulerToggle } from '@configs/google_analytics/features/header_bar/main_menu/guide_line/makers/makeGAParamsActionClickGuideLineRulerToggle';
import { makeGAParamsActionClickGuideLineToggle } from '@configs/google_analytics/features/header_bar/main_menu/guide_line/makers/makeGAParamsActionClickGuideLineToggle';
import { makeGAParamsActionClickRowGuideLineButton } from '@configs/google_analytics/features/header_bar/main_menu/guide_line/makers/makeGAParamsActionClickRowGuideLineButton';
import { makeGAParamsActionClickSnapGuideToggle } from '@configs/google_analytics/features/header_bar/main_menu/guide_line/makers/makeGAParamsActionClickSnapGuideToggle';
import { makeGAParamsActionClickAutoSaveToggle } from '@configs/google_analytics/features/header_bar/main_menu/makers/makeGAParamsActionClickAutoSaveToggle';
import { makeGAParamsActionClickDarkModeActiveButton } from '@configs/google_analytics/features/header_bar/main_menu/makers/makeGAParamsActionClickDarkModeActiveButton';
import { makeGAParamsActionClickLayerActiveButton } from '@configs/google_analytics/features/header_bar/main_menu/makers/makeGAParamsActionClickLayerActiveButton';
import { makeGAParamsActionClickMainMenuButton } from '@configs/google_analytics/features/header_bar/main_menu/makers/makeGAParamsActionClickMainMenuButton';
import { makeGAParamsActionClickPageNumberActiveButton } from '@configs/google_analytics/features/header_bar/main_menu/makers/makeGAParamsActionClickPageNumberActiveButton';
import { makeGAParamsActionClickPageNumberButton } from '@configs/google_analytics/features/header_bar/main_menu/makers/makeGAParamsActionClickPageNumberButton';
import { makeGAParamsActionClickTemplateCreateButton } from '@configs/google_analytics/features/header_bar/main_menu/makers/makeGAParamsActionClickTemplateCreateButton';
import { makeGAParamsActionClickWorkspaceMoveButton } from '@configs/google_analytics/features/header_bar/main_menu/makers/makeGAParamsActionClickWorkspaceMoveButton';
import { makeGAParamsActionClickEditorResizeButton } from '@configs/google_analytics/features/header_bar/makers/makeGAParamsActionClickEditorResizeButton';
import { makeGAParamsActionClickHeaderFolderButton } from '@configs/google_analytics/features/header_bar/makers/makeGAParamsActionClickHeaderFolderButton';
import { makeGAParamsActionClickHeaderPrintoutButton } from '@configs/google_analytics/features/header_bar/makers/makeGAParamsActionClickHeaderPrintoutButton';
import { makeGAParamsActionClickHeaderShareButton } from '@configs/google_analytics/features/header_bar/makers/makeGAParamsActionClickHeaderShareButton';
import { makeGAParamsActionClickMoreMenuShareButton } from '@configs/google_analytics/features/header_bar/makers/makeGAParamsActionClickMoreMenuShareButton';
import { makeGAParamsActionClickResizeDirectInputButton } from '@configs/google_analytics/features/header_bar/makers/makeGAParamsActionClickResizeDirectInputButton';
import { makeGAParamsActionClickDesignPublicToggleButton } from '@configs/google_analytics/features/header_bar/share_modal/makers/makeGAParamsActionClickDesignPublicToggleButton';
import { makeGAParamsActionClickAIPresentationButtonInAITools } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionClickAIPresentationButtonInAITools';
import { makeGAParamsActionClickBackToGenerateStepButton } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionClickBackToGenerateStepButton';
import { makeGAParamsActionClickCancelGeneratingAIImageButton } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionClickCancelGeneratingAIImageButton';
import { makeGAParamsActionClickCategoryPackItem } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionClickCategoryPackItem';
import { makeGAParamsActionClickGenerateAIImageButton } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionClickGenerateAIImageButton';
import { makeGAParamsActionClickGeneratedAIImageWhenAllTasksAreFinished } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionClickGeneratedAIImageWhenAllTasksAreFinished';
import { makeGAParamsActionClickGeneratedAIImageWhenAllTasksAreUnfinished } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionClickGeneratedAIImageWhenAllTasksAreUnfinished';
import { makeGAParamsActionClickLeftPanelAIDrawingMenuButton } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionClickLeftPanelAIDrawingMenuButton';
import { makeGAParamsActionClickRecreateAIImageButton } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionClickRecreateAIImageButton';
import { makeGAParamsActionClickRecreateWithGeneratedAIImageButton } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionClickRecreateWithGeneratedAIImageButton';
import { makeGAParamsActionClickShowStyleListButton } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionClickShowStyleListButton';
import { makeGAParamsActionClickStyleItem } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionClickStyleItem';
import { makeGAParamsActionClickStyleListBackButton } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionClickStyleListBackButton';
import { makeGAParamsActionClickUseExampleButton } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionClickUseExampleButton';
import { makeGAParamsActionViewResultWhenAllTasksAreFinished } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionViewResultWhenAllTasksAreFinished';
import { makeGAParamsActionClickCaretButtonCloseLeftMenus } from '@configs/google_analytics/features/left_panel/common/makers/makeGAParamsActionClickCaretButtonCloseLeftMenus';
import { makeGAParamsActionClickCaretButtonOpenLeftMenus } from '@configs/google_analytics/features/left_panel/common/makers/makeGAParamsActionClickCaretButtonOpenLeftMenus';
import { makeGAParamsActionClickCollectionItem } from '@configs/google_analytics/features/left_panel/element/makers/makeGAParamsActionClickCollectionItem';
import { makeGAParamsActionClickElementItemInCollectionItem } from '@configs/google_analytics/features/left_panel/element/makers/makeGAParamsActionClickElementItemInCollectionItem';
import {
  makeGAParamsActionClickQRCodeMakeButton,
  makeGAParamsActionClickQRCodeMakeMobileButton,
} from '@configs/google_analytics/features/left_panel/qr_barcode/makers/makeGAParamsActionClickQRCodeMakeButton';
import { makeGAParamsActionClickQRCodeSymbolMarkIcon } from '@configs/google_analytics/features/left_panel/qr_barcode/makers/makeGAParamsActionClickQRCodeSymbolMarkIcon';
import { makeGAParamsActionClickQRCodeSymbolMarkSwitch } from '@configs/google_analytics/features/left_panel/qr_barcode/makers/makeGAParamsActionClickQRCodeSymbolMarkSwitch';
import { makeGAParamsActionClickQRCodeSymbolMarkUploadButton } from '@configs/google_analytics/features/left_panel/qr_barcode/makers/makeGAParamsActionClickQRCodeSymbolMarkUploadButton';
import { makeGAParamsActionUploadQRCodeSymbolMarkImage } from '@configs/google_analytics/features/left_panel/qr_barcode/makers/makeGAParamsActionUploadQRCodeSymbolMarkImage';
import { makeGAParamsActionClickUploadItemDeleteButton } from '@configs/google_analytics/features/left_panel/upload/makers/makeGAParamsActionClickUploadItemDeleteButton';
import { makeGAParamsActionClickUploadItemDownloadButton } from '@configs/google_analytics/features/left_panel/upload/makers/makeGAParamsActionClickUploadItemDownloadButton';
import { makeGAParamsActionClickUploadItemMoreButton } from '@configs/google_analytics/features/left_panel/upload/makers/makeGAParamsActionClickUploadItemMoreButton';
import { makeGAParamsActionClickUploadItemMoveButton } from '@configs/google_analytics/features/left_panel/upload/makers/makeGAParamsActionClickUploadItemMoveButton';
import { makeGAParamsActionClickFavoritePageEntryPoint } from '@configs/google_analytics/features/main_page/_makers/makeGAParamsActionClickFavoritePageEntryPoint';
import { makeGAParamsActionClickMainHeaderSignUpButton } from '@configs/google_analytics/features/main_page/main_header/makers/makeGAParamsActionClickMainHeaderSignUpButton';
import {
  makeGAParamsActionApplyCalendar,
  makeGAParamsActionClickCalendar,
} from '@configs/google_analytics/features/property_panel/calendar/makers/makeGAParamsActionCalendar';
import { makeGAParamsActionBrandKit } from '@configs/google_analytics/features/property_panel/color_popup/makers/makeGAParamsActionBrandKit';
import { makeGAParamsActionEyeDropper } from '@configs/google_analytics/features/property_panel/color_popup/makers/makeGAParamsActionEyeDropper';
import { makeGAParamsActionGradationColorPicker } from '@configs/google_analytics/features/property_panel/color_popup/makers/makeGAParamsActionGradationColorPicker';
import { makeGAParamsActionSolidColorPicker } from '@configs/google_analytics/features/property_panel/color_popup/makers/makeGAParamsActionSolidColorPicker';
import { makeGAParamsActionClickPropertyOverlappingElementSelect } from '@configs/google_analytics/features/property_panel/element_select/makers/makeGAParamsActionClickPropertyOverlappingElementSelect';
import { makeGAParamsActionPatternBackgroundColor } from '@configs/google_analytics/features/property_panel/pattern_background/maker/makeGAParamsActionPatternBackgroundColor';
import { makeGAParamsActionPatternBackgroundSize } from '@configs/google_analytics/features/property_panel/pattern_background/maker/makeGAParamsActionPatternBackgroundSize';
import { makeGAParamsActionClickObjectRemovalAreaSelectionButton } from '@configs/google_analytics/features/property_panel/photo_extra_edit/_makers/makeGAParamsActionClickObjectRemovalAreaSelectionButton';
import { makeGAParamsActionClickObjectRemovalBackButton } from '@configs/google_analytics/features/property_panel/photo_extra_edit/_makers/makeGAParamsActionClickObjectRemovalBackButton';
import { makeGAParamsActionClickObjectRemovalEditOtherButton } from '@configs/google_analytics/features/property_panel/photo_extra_edit/_makers/makeGAParamsActionClickObjectRemovalEditOtherButton';
import { makeGAParamsActionClickObjectRemovalEraseButton } from '@configs/google_analytics/features/property_panel/photo_extra_edit/_makers/makeGAParamsActionClickObjectRemovalEraseButton';
import { makeGAParamsActionClickObjectRemovalGuideButton } from '@configs/google_analytics/features/property_panel/photo_extra_edit/_makers/makeGAParamsActionClickObjectRemovalGuideButton';
import { makeGAParamsActionClickObjectRemovalHeaderButton } from '@configs/google_analytics/features/property_panel/photo_extra_edit/_makers/makeGAParamsActionClickObjectRemovalHeaderButton';
import { makeGAParamsActionClickObjectRemovalToolButton } from '@configs/google_analytics/features/property_panel/photo_extra_edit/_makers/makeGAParamsActionClickObjectRemovalToolButton';
import { makeGAParamsActionClickObjectRemovalUndoRedoButton } from '@configs/google_analytics/features/property_panel/photo_extra_edit/_makers/makeGAParamsActionClickObjectRemovalUndoRedoButton';
import { makeGAParamsActionClickPhotoExtraEditToolButton } from '@configs/google_analytics/features/property_panel/photo_extra_edit/_makers/makeGAParamsActionClickPhotoExtraEditToolButton';
import { makeGAParamsActionCloseObjectRemovalGuidePopover } from '@configs/google_analytics/features/property_panel/photo_extra_edit/_makers/makeGAParamsActionCloseObjectRemovalGuidePopover';
import { makeGAParamsActionViewObjectRemovalGuidePopover } from '@configs/google_analytics/features/property_panel/photo_extra_edit/_makers/makeGAParamsActionViewObjectRemovalGuidePopover';
import {
  makeGAParamsActionChangeAllSameFont,
  makeGAParamsActionClickPropertyFont,
  makeGAParamsActionClickPropertyTextAdjustment,
  makeGAParamsActionClickPropertyTextSize,
  makeGAParamsActionFontCategoryAll,
  makeGAParamsActionFontCategoryBrandKit,
  makeGAParamsActionFontCategoryFavorite,
  makeGAParamsActionFontFilter,
  makeGAParamsActionFontFilterApply,
  makeGAParamsActionFontFilterList,
  makeGAParamsActionFontFilterReset,
  makeGAParamsActionFontListOpen,
  makeGAParamsActionFontListRecent,
  makeGAParamsActionSelectFontList,
  makeGAParmasActionClickPropertyTextColor,
} from '@configs/google_analytics/features/property_panel/text/makers/makeGAParamsActionFont';
import { makeGAParamsActionTextAnimationTab } from '@configs/google_analytics/features/property_panel/text/makers/makeGAParamsActionTextAnimationTab';
import { makeGAParamsActionTextStyleRecommend } from '@configs/google_analytics/features/property_panel/text/makers/makeGAParamsActionTextStyleRecommend';
import { makeGAParamsActionTextLock } from '@configs/google_analytics/features/property_panel/text/makers/makeGAParamsTextLock';
import { makeGAParamsActionClickTemplateFavoriteButton } from '@configs/google_analytics/features/template/_makers/makeGAParamsActionClickTemplateFavoriteButton';
import { makeGAParamsActionClickTemplateMyList } from '@configs/google_analytics/features/template_mylist/makers/makeGAParamsActionClickTemplateMyList';
import { makeGAParamsActionDetailTemplateMyList } from '@configs/google_analytics/features/template_mylist/makers/makeGAParamsActionDetailTemplateMyList';
import { makeGAParamsActionSearchTemplateMyList } from '@configs/google_analytics/features/template_mylist/makers/makeGAParamsActionSearchTemplateMyList';
import { makeGAParamsActionClickSuccessTemplateMyList } from '@configs/google_analytics/features/template_mylist/makers/makeGAParamsActionSuccessClickTemplateMyList';
import { makeGAParamsActionViewTemplateMyList } from '@configs/google_analytics/features/template_mylist/makers/makeGAParamsActionViewTemplateMyList';
import { makeGAParamsActionOverwritePrintoutTemplate } from '@configs/google_analytics/features/template_overwrite/makers/makeGAParamsActionOverwritePrintoutTemplate';
import { makeGAParamsActionResizePrintoutTemplateSize } from '@configs/google_analytics/features/template_type_select/design_resize/makers/makeGAParamsActionResizePrintoutTemplateSize';
import { makeGAParamsAIToolsUpgradePopup } from '@configs/google_analytics/features/upgrade_popup/makers/makeGAParamsAIToolsUpgradePopup';
import { makeGAParamsClickMyListPickBannerUpgradePopup } from '@configs/google_analytics/features/upgrade_popup/makers/makeGAParamsClickMyListPickBannerUpgradePopup';
import { makeGAParamsInpaintingUpgradePopup } from '@configs/google_analytics/features/upgrade_popup/makers/makeGAParamsInpaintingUpgradePopup';
import { makeGAParamsObjectRemovalUpgradePopup } from '@configs/google_analytics/features/upgrade_popup/makers/makeGAParamsObjectRemovalUpgradePopup';
import { makeGAParamsUpscaleUpgradePopup } from '@configs/google_analytics/features/upgrade_popup/makers/makeGAParamsUpscaleUpgradePopup';
import { makeGAParamsUseMyListPremiumContentsUpgradePopup } from '@configs/google_analytics/features/upgrade_popup/makers/makeGAParamsUseMyListPremiumContentsUpgradePopup';
import {
  makeGAParamsActionAppEditorCrash,
  makeGAParamsActionAppHomeBottomTab,
  makeGAParamsActionAppHomeCardNews,
  makeGAParamsActionAppHomeDetailPage,
  makeGAParamsActionAppHomeMore,
  makeGAParamsActionAppHomePresentation,
  makeGAParamsActionAppHomeSearch,
  makeGAParamsActionAppHomeSnsSquare,
  makeGAParamsActionAppHomeStartEmpty,
  makeGAParamsActionAppHomeStartEmptySelectType,
  makeGAParamsActionAppHomeVideo,
  makeGAParamsActionAppHomeView,
  makeGAParamsActionAppHomeWebPosterLandscape,
  makeGAParamsActionAppHomeWorkspaceBottomTab,
  makeGAParamsActionAppHomeYoutubeThumbnail,
} from '@configs/google_analytics/features/webview/makers/makeGAParamsActionAppHome';
import { AIDrawingGAParameterMakerFnType } from '@configs/google_analytics/types/AIDrawingGAParameterMakerFnType';
import { ObjectRemovalGAParameterMakerFnType } from '@configs/google_analytics/types/ObjectRemovalGAParameterMakerFnType';
import { EventType } from '@event/_types/EventType';
import { makeGAParamsActionClickAddBookMarkButtonWhenDownloading } from '@ui/design/_common/retention_lab/guide_to_bookmark/_makers/makeGAParamActionClickAddBookMarkButtonWhenDownloading';
import { makeGAParamsActionClickCloseButtonAddBookmarkGuidePopup } from '@ui/design/_common/retention_lab/guide_to_bookmark/_makers/makeGAParamsActionClickCloseButtonAddBookmarkGuidePopup';
import { makeGAParamsActionClickConfirmButtonAddBookmark } from '@ui/design/_common/retention_lab/guide_to_bookmark/_makers/makeGAParamsActionClickConfirmButtonAddBookmark';
import { makeGAParamsActionClickAddBookmarkButtonAfterDownload } from '@ui/design/_common/retention_lab/guide_to_bookmark/_makers/makeGAParmasActionClickAddBookmarkButtonAfterDownload';
import { makeGAParamsActionClickGlobalMainPageFloatingButton } from '@ui/main_page/main/makers/makeGAParamsActionClickGlobalMainPageFloatingButton';
import { makeGAParamsActionClickGlobalMainPageGoToEditorCTA } from '@ui/main_page/main/makers/makeGAParamsActionClickGlobalMainPageGoToEditorCTA';
import { makeGAParamsActionClickGlobalMainPageGoToTemplateCTA } from '@ui/main_page/main/makers/makeGAParamsActionClickGlobalMainPageGoToTemplateCTA';
import { makeGAParamsActionClickGlobalMainPageStartNowButton } from '@ui/main_page/main/makers/makeGAParamsActionClickGlobalMainPageStartNowButton';
import { makeGAParamsActionClickKoreaMainPageFloatingButton } from '@ui/main_page/main/makers/makeGAParamsActionClickKoreaMainPageFloatingButton';
import { makeGAParamsActionClickMainPageFloatingSignUpButton } from '@ui/main_page/main/makers/makeGAParamsActionClickMainPageFloatingSignUpButton';
import { makeGAParamsActionClickMainPageSignUpCTA } from '@ui/main_page/main/makers/makeGAParamsActionClickMainPageSignUpCTA';
import { makeGAParamsActionClickAIWritingEditTextButton } from './features/ai/ai_writing/_makers/makeGAParamsActionClickAIWritingEditTextButton';
import { makeGAParamsActionClickAIDesignAssistantPopupButton } from './features/ai/makers/makeGAParamsActionClickAIDesignAssistantButton';
import { makeGAParamsActionClickAIDesignAssistantPopupAIChatBotButton } from './features/ai/makers/makeGAParamsActionClickAIDesignAssistantPopupAIChatBotButton';
import { makeGAParamsActionClickAIDesignAssistantPopupAIDrawingButton } from './features/ai/makers/makeGAParamsActionClickAIDesignAssistantPopupAIDrawingButton';
import { makeGAParamsActionClickAIDesignAssistantPopupAIWritingButton } from './features/ai/makers/makeGAParamsActionClickAIDesignAssistantPopupAIWritingButton';
import { makeGAParamsActionClickTemplateChatbotItem } from './features/ai/makers/makeGAParamsActionClickTemplateChatbotItem';
import { makeGAParamsActionSearchTemplateRecommendChatBotPayload } from './features/ai/makers/makeGAParamsActionSearchTemplateRecommendChatBot';
import { makeGAParamsActionViewRecommendedTemplateInChatBot } from './features/ai/makers/makeGAParamsActionViewRecommendedTemplateInChatBot';
import { makeGAParamsActionClickAIPresentationButton } from './features/ai/presentation/makers/makeGAParamsActionClickAIPresentationButton';
import { makeGAParamsActionClickAIPresentationCreateNewDesignButton } from './features/ai/presentation/makers/makeGAParamsActionClickAIPresentationCreateNewDesignButton';
import { makeGAParamsActionClickAIPresentationEditOutline6PageButton } from './features/ai/presentation/makers/makeGAParamsActionClickAIPresentationEditOutline6PageButton';
import { makeGAParamsActionClickAIPresentationEditOutline7PageButton } from './features/ai/presentation/makers/makeGAParamsActionClickAIPresentationEditOutline7PageButton';
import { makeGAParamsActionClickAIPresentationEditOutline8PageButton } from './features/ai/presentation/makers/makeGAParamsActionClickAIPresentationEditOutline8PageButton';
import { makeGAParamsActionClickAIPresentationEditOutlinePreviousButton } from './features/ai/presentation/makers/makeGAParamsActionClickAIPresentationEditOutlinePreviousButton';
import { makeGAParamsActionClickAIPresentationEditOutlineRegenerateButton } from './features/ai/presentation/makers/makeGAParamsActionClickAIPresentationEditOutlineRegenerateButton';
import { makeGAParamsActionClickAIPresentationEditOutlineTemplateSelectButton } from './features/ai/presentation/makers/makeGAParamsActionClickAIPresentationEditOutlineTemplateSelectButton';
import { makeGAParamsActionClickAIPresentationEditOutlineTitleButton } from './features/ai/presentation/makers/makeGAParamsActionClickAIPresentationEditOutlineTitleButton';
import { makeGAParamsActionClickAIPresentationGenerateOutline6PageButton } from './features/ai/presentation/makers/makeGAParamsActionClickAIPresentationGenerateOutline6PageButton';
import { makeGAParamsActionClickAIPresentationGenerateOutline7PageButton } from './features/ai/presentation/makers/makeGAParamsActionClickAIPresentationGenerateOutline7PageButton';
import { makeGAParamsActionClickAIPresentationGenerateOutline8PageButton } from './features/ai/presentation/makers/makeGAParamsActionClickAIPresentationGenerateOutline8PageButton';
import { makeGAParamsActionClickAIPresentationGenerateOutlineButton } from './features/ai/presentation/makers/makeGAParamsActionClickAIPresentationGenerateOutlineButton';
import { makeGAParamsActionClickAIPresentationGenerateOutlineOtherExampleButton } from './features/ai/presentation/makers/makeGAParamsActionClickAIPresentationGenerateOutlineOtherExampleButton';
import { makeGAParamsActionClickAIPresentationLoadingCloseButton } from './features/ai/presentation/makers/makeGAParamsActionClickAIPresentationLoadingCloseButton';
import { makeGAParamsActionClickAIPresentationLoadingFloatingButton } from './features/ai/presentation/makers/makeGAParamsActionClickAIPresentationLoadingFloatingButton';
import { makeGAParamsActionClickAIPresentationOverwriteButton } from './features/ai/presentation/makers/makeGAParamsActionClickAIPresentationOverwriteButton';
import { makeGAParamsActionClickAIPresentationRetryButton } from './features/ai/presentation/makers/makeGAParamsActionClickAIPresentationRetryButton';
import { makeGAParamsActionClickAIPresentationTemplateSelectButton } from './features/ai/presentation/makers/makeGAParamsActionClickAIPresentationTemplateSelectButton';
import { makeGAParamsActionClickAIPresentationTemplateSelectGenerateButton } from './features/ai/presentation/makers/makeGAParamsActionClickAIPresentationTemplateSelectGenerateButton';
import { makeGAParamsActionClickAIPresentationTemplateSelectPreviousButton } from './features/ai/presentation/makers/makeGAParamsActionClickAIPresentationTemplateSelectPreviousButton';
import { makeGAParamsActionClickChangeTemplateSizeButton } from './features/flag_popup/makers/makeGAParamsActionClickChangeTemplateSizeButton';
import { makeGAParamsActionClickAfterDownloadSurveyCloseButton } from './features/header_bar/download_popup/makers/makeGAParamsActionClickAfterDownloadSurveyCloseButton';
import { makeGAParamsActionClickAfterDownloadSurveyDoNotShowAgainButton } from './features/header_bar/download_popup/makers/makeGAParamsActionClickAfterDownloadSurveyDoNotShowAgainButton';
import { makeGAParamsActionClickRequestPopupRequestRoleDelete } from './features/header_bar/draft_popup/makers/makeGAParamsActionClickRequestPopupRequestRoleDelete';
import { makeGAParamsActionClickRequestPopupRequestRoleEdit } from './features/header_bar/draft_popup/makers/makeGAParamsActionClickRequestPopupRequestRoleEdit';
import { makeGAParamsActionClickRequestPopupRequestRoleFeedback } from './features/header_bar/draft_popup/makers/makeGAParamsActionClickRequestPopupRequestRoleFeedback';
import { makeGAParamsActionClickRequestPopupRequestRoleSelectButton } from './features/header_bar/draft_popup/makers/makeGAParamsActionClickRequestPopupRequestRoleSelectButton';
import { makeGAParamsActionClickRequestPopupRequestRoleView } from './features/header_bar/draft_popup/makers/makeGAParamsActionClickRequestPopupRequestRoleView';
import {
  makeGAParamsActionClickChangeViewModeButton,
  makeGAParamsActionClickEditModeButton,
  makeGAParamsActionClickFeedbackModeButton,
  makeGAParamsActionClickViewModeButton,
} from './features/header_bar/main_menu/makers/makeGAParamsActionClickChangeViewModeButton';
import { makeGAParamsActionClickCreateCopyButton } from './features/header_bar/main_menu/makers/makeGAParamsActionClickCreateCopyButton';
import { makeGAParamsActionClickFindTextButton } from './features/header_bar/main_menu/makers/makeGAParamsActionClickFindTextButton';
import { makeGAParamsActionClickHelpButton } from './features/header_bar/main_menu/makers/makeGAParamsActionClickHelpButton';
import { makeGAParamsActionClickMainMenuDesignTitle } from './features/header_bar/main_menu/makers/makeGAParamsActionClickMainMenuDesignTitle';
import { makeGAParamsActionClickNewDesignButton } from './features/header_bar/main_menu/makers/makeGAParamsActionClickNewDesignButton';
import { makeGAParamsActionClickHeaderDesignTitle } from './features/header_bar/makers/makeGAParamsActionClickHeaderDesignTitle';
import { makeGAParamsActionClickMiricanvasLogoButton } from './features/header_bar/makers/makeGAParamsActionClickMiricanvasLogoButton';
import { makeGAParamsActionClickMoreMenuButton } from './features/header_bar/makers/makeGAParamsActionClickMoreMenuButton';
import { makeGAParamsActionClickMoreMenuDesignEmbeddingButton } from './features/header_bar/makers/makeGAParamsActionClickMoreMenuDesignEmbeddingButton';
import { makeGAParamsActionClickMoreMenuDownloadButton } from './features/header_bar/makers/makeGAParamsActionClickMoreMenuDownloadButton';
import { makeGAParamsActionClickMoreMenuDraftButton } from './features/header_bar/makers/makeGAParamsActionClickMoreMenuDraftButton';
import { makeGAParamsActionClickMoreMenuPrintoutButton } from './features/header_bar/makers/makeGAParamsActionClickMoreMenuPrintoutButton';
import { makeGAParamsActionClickMoreMenuSlideShowButton } from './features/header_bar/makers/makeGAParamsActionClickMoreMenuSlideShowButton';
import {
  makeGAParamsActionClickHeaderBarDownloadButton,
  makeGAParamsActionClickHeaderBarLoginButton,
  makeGAParamsActionClickSlideShowButton,
} from './features/header_bar/makers/makeGAParamsHeaderBar';
import { makeGAParamsActionClickDesignShareResource } from './features/header_bar/share_modal/makers/makeGAParamsActionClickDesignShareResource';
import {
  makeGAParamsActionShareMemberInviteEmailToggle,
  makeGAParamsActionShareMemberInviteTabButton,
} from './features/header_bar/share_modal/makers/makeGAParamsActionClickShareMemberInvite';
import {
  makeGAParamsActionShareDetailOpitonButton,
  makeGAParamsActionShareDetailOpitonCopyToggle,
  makeGAParamsActionShareDetailOpitonLikeAndCommentToggle,
  makeGAParamsActionShareDetailOpitonShareToggle,
  makeGAParamsActionShareDetailOpitonSlideShowToggle,
  makeGAParamsActionShareLinkCopyButton,
  makeGAParamsActionSharePageStyleDropdownButton,
  makeGAParamsActionSharePageStyleQuizeButton,
  makeGAParamsActionSharePageStyleScrollButton,
  makeGAParamsActionSharePageStyleSlideButton,
  makeGAParamsActionSharePreviewButton,
  makeGAParamsActionShareSNSFacebookButton,
  makeGAParamsActionShareSNSKakaoStoryButton,
  makeGAParamsActionShareSNSKakaoTalkButton,
  makeGAParamsActionShareSNSNaverBandButton,
  makeGAParamsActionShareSNSNaverBlogButton,
  makeGAParamsActionShareSNSTwitterButton,
  makeGAParamsActionShareTemplateLinkShareButton,
} from './features/header_bar/share_modal/makers/makeGAParamsActionClickShareWebPublish';
import {
  makeGAParamsActionClickAudio,
  makeGAParamsActionSearchAudio,
  makeGAParamsActionViewAudioItem,
} from './features/left_panel/audio/makers/makeGAParamsAudioPanel';
import { makeGAParamsActionClickBackgroundItem } from './features/left_panel/background/makers/makeGAParamsActionClickBackgroundItem';
import { makeGAParamsActionSearchBackgroundItem } from './features/left_panel/background/makers/makeGAParamsActionSearchBackgroundItem';
import { makeGAParamsActionViewBackgroundItem } from './features/left_panel/background/makers/makeGAParamsActionViewBackgroundItem';
import {
  makeGAActionClickBackgroundColorChipButton,
  makeGAActionClickBackgroundEditButton,
  makeGAActionClickBackgroundPatternTabButton,
  makeGAActionClickBackgroundPictureTabButton,
} from './features/left_panel/background/makers/makeGAParamsBackgroundPanel';
import {
  makeGAParamsActionClickKeywordCategoryFinalIndicatorButton,
  makeGAParamsActionClickKeywordCategoryIndicatorButton,
} from './features/left_panel/common/makers/makeGAParamsActionClickKeywordCategory';
import {
  makeGAParamsActionClickLeftPanelExpandButton,
  makeGAParamsActionClickLeftPanelMenuButton,
  makeGAParamsActionClickLeftPanelUploadMenuButton,
} from './features/left_panel/common/makers/makeGAParamsActionClickLeftPanelMenuButton';
import {
  makeGAParamsActionClickMoreInfoButton,
  makeGAParamsActionClickMoreInfoPopoverPriceInfoButton,
} from './features/left_panel/common/makers/makeGAParamsActionClickMoreInfoButton';
import { makeGAParamsActionDragLeftPanelResizeButton } from './features/left_panel/common/makers/makeGAParamsActionDragLeftPanelResizeButton';
import { makeGAParamsActionClickElementItem } from './features/left_panel/element/makers/makeGAParamsActionClickElementItem';
import { makeGAParamsActionSearchElementItem } from './features/left_panel/element/makers/makeGAParamsActionSearchElementItem';
import { makeGAParamsActionViewElementItem } from './features/left_panel/element/makers/makeGAParamsActionViewElementItem';
import {
  makeGAParamsActionClickPicture,
  makeGAParamsActionSearchPicture,
  makeGAParamsActionViewPictureItem,
} from './features/left_panel/picture/makers/makeGAParamsPicturePanel';
import { makeGAParamsActionClickTemplateSearchFilterTierButton } from './features/left_panel/template/makers/makeGAParamsActionClickTemplateSearchFilter';
import {
  makeGAParamsActionClickTemplate,
  makeGAParamsActionClickTemplateSearchFilterButton,
  makeGAParamsActionClickTemplateSearchLanguageButton,
  makeGAParamsActionClickTemplateTypeFilterButton,
  makeGAParamsActionSearchTemplate,
  makeGAParamsActionViewTemplateItem,
} from './features/left_panel/template/makers/makeGAParamsTemplatePanel';
import { makeGAParamsActionClickAddTextButton } from './features/left_panel/text/makers/makeGAParamsActionClickAddTextButton';
import { makeGAParamsActionClickTextItem } from './features/left_panel/text/makers/makeGAParamsActionClickTextItem';
import { makeGAParamsActionSearchTextItem } from './features/left_panel/text/makers/makeGAParamsActionSearchTextItem';
import { makeGAParamsActionViewTextItem } from './features/left_panel/text/makers/makeGAParamsActionViewTextItem';
import {
  makeGAParamsActionClickApplyThemeAllPage,
  makeGAParamsActionClickCurrentThemeInitializeButton,
  makeGAParamsActionClickRecommendThemeMoreInfoButton,
  makeGAParamsActionClickTheme,
  makeGAParamsActionClickThemeSelectButton,
  makeGAParamsActionClickThemeShuffleButton,
  makeGAParamsActionSearchTheme,
  makeGAParamsActionViewTheme,
} from './features/left_panel/theme/makers/makeGAParamsThemePanel';
import { makeGAParamsActionClickUploadItem } from './features/left_panel/upload/makers/makeGAParamsActionClickUploadItem';
import { makeGAParamsActionSearchUploadItem } from './features/left_panel/upload/makers/makeGAParamsActionSearchUploadItem';
import { makeGAParamsActionViewUploadItem } from './features/left_panel/upload/makers/makeGAParamsActionViewUploadItem';
import {
  makeGAParamsActionClickVideo,
  makeGAParamsActionClickVideoEditorPromotionBanner,
  makeGAParamsActionClickYoutubeMakeButton,
  makeGAParamsActionClickYoutubeMoveButton,
  makeGAParamsActionClickYoutubeTab,
  makeGAParamsActionSearchVideo,
  makeGAParamsActionViewVideo,
} from './features/left_panel/video/makers/makeGAParamsVideoPanel';
import {
  makeGAParamsActionClickBrowseVideoTemplateFromDownloadComplete,
  makeGAParamsActionClickBrowseVideoTemplateFromDownloadProgress,
  makeGAParamsActionClickMakeVideoWithMyDesign,
  makeGAParamsActionDownload,
} from './features/modal/download/makers/makeGAParamsDownloadModal';
import {
  makeGAParamsActionClickFreeSubscriptionModalCloseButton,
  makeGAParamsActionClickFreeSubscriptionModalStartForFreeButton,
} from './features/modal/free_trial/makers/makeGAParamsFreeSubscriptionModal';
import { makeGAParamsActionGlobalTrialWelcomePopupButton } from './features/modal/global_trial/makers/makeGAParamsActionGlobalTrialWelcomePopupButton';
import { makeGAParamsOpenGlobalTrialUpgradePopup } from './features/modal/global_trial/makers/makeGAParamsOpenGlobalTrialUpgradePopup';
import { makeGAParamsActionClickGlobalUpdateModalNextButton } from './features/modal/global_update/makers/makeGAParamsGlobalUpdateModal';
import { makeGAParamsActionInviteFriendLinkCopy } from './features/modal/invite_friend/makers/makeGAParamsInviteFriend';
import { makeGAParamsActionClickNewFeatureAIPresentationUse } from './features/modal/new_feature/makers/makeGAParamsActionClickNewFeatureAIPresentationUse';
import {
  makeGAParamsActionSignInUser,
  makeGAParamsActionSignUpUser,
} from './features/modal/signin/makers/makeGAParamsSignIn';
import { makeGAParamsActionClickPageAnimationPreset } from './features/property_panel/animation/makers/makeGAParamsAnimationPropertyPanel';
import { makeGAParmasActionClickPropertyElementAnimation } from './features/property_panel/animation/makers/makeGAParmasActionClickPropertyElementAnimation';
import { makeGAParamsActionClickPropertyDeleteBackground } from './features/property_panel/background/makeGAParamsActionClickPropertyDeleteBackground';
import { makeGAParamsActionClickPropertyInitBackground } from './features/property_panel/background/makeGAParamsActionClickPropertyInitBackground';
import { makeGAParamsActionClickPropertyMakeBackground } from './features/property_panel/background/makeGAParamsActionClickPropertyMakeBackground';
import { makeGAParmasActionClickPropertyBackgroundColor } from './features/property_panel/background/makeGAParmasActionClickPropertyBackgroundColor';
import { makeGAParamsActionClickPropertyColor } from './features/property_panel/color/makeGAParamsActionClickPropertyColor';
import { makeGAParamsActionClickPropertyEffect } from './features/property_panel/effect/makeGAParamsActionClickPropertyEffect';
import { makeGAParamsActionClickPropertyElementMove } from './features/property_panel/element_move/makers/makeGAParamsActionClickPropertyElementMove';
import { makeGAParamsActionClickPropertyAdjustment } from './features/property_panel/etc/makeGAParamsActionClickPropertyAdjustment';
import { makeGAParamsActionClickPropertyDelete } from './features/property_panel/etc/makeGAParamsActionClickPropertyDelete';
import { makeGAParamsActionClickPropertyEditData } from './features/property_panel/etc/makeGAParamsActionClickPropertyEditData';
import { makeGAParamsActionClickPropertyPlay } from './features/property_panel/etc/makeGAParamsActionClickPropertyPlay';
import { makeGAParamsActionClickPropertyStyling } from './features/property_panel/etc/makeGAParamsActionClickPropertyStyling';
import { makeGAParamsActionclickPropertyForm } from './features/property_panel/etc/makeGAParamsActionclickPropertyForm';
import { makeGAParamsActionClickPropertyFilter } from './features/property_panel/filter/makeGAParamsActionClickPropertyFilter';
import { makeGAParamsActionClickPropertyMakeGroup } from './features/property_panel/group/makeGAParamsActionClickPropertyMakeGroup';
import { makeGAParamsActionClickPropertyUngroup } from './features/property_panel/group/makeGAParamsActionClickPropertyUngroup';
import {
  makeGAParamsActionClickPropertyLink,
  makeGAParamsActionClickPropertyLock,
  makeGAParamsActionClickPropertyStyleCopy,
} from './features/property_panel/header/makers/makeGAParamsPropertyHeader';
import { makeGAParamsPropertyElementInformation } from './features/property_panel/information/makeGAParamsPropertyElementInformation';
import { makeGAParamsActionClickPropertyMore } from './features/property_panel/more/makeGAParamsActionClickPropertyMore';
import { makeGAParamsActionClickPropertyMoreAlign } from './features/property_panel/more/makeGAParamsActionClickPropertyMoreAlign';
import { makeGAParamsActionClickPropertyMoreCopy } from './features/property_panel/more/makeGAParamsActionClickPropertyMoreCopy';
import { makeGAParamsActionClickPropertyMoreDelete } from './features/property_panel/more/makeGAParamsActionClickPropertyMoreDelete';
import { makeGAParamsActionClickPropertyMoreLayer } from './features/property_panel/more/makeGAParamsActionClickPropertyMoreLayer';
import { makeGAParamsActionClickPropertyMoreMultiSelect } from './features/property_panel/more/makeGAParamsActionClickPropertyMoreMultiSelect';
import { makeGAParamsActionClickPropertyMorePaste } from './features/property_panel/more/makeGAParamsActionClickPropertyMorePaste';
import { makeGAParamsActionClickPropertyInitPicture } from './features/property_panel/picture/makeGAParamsActionClickPropertyInitPicture';
import { makeGAParamsActionClickPropertyElementSize } from './features/property_panel/size/makeGAParamsActionClickPropertyElementSize';
import {
  makeGAParamsActionClickPhotoPropertyAlign,
  makeGAParamsActionClickPhotoPropertyCrop,
  makeGAParamsActionClickPhotoPropertyFlip,
  makeGAParamsActionClickPhotoPropertyOrder,
  makeGAParamsActionClickPhotoPropertyResetPhoto,
  makeGAParamsActionClickPhotoPropertySetAsBackground,
  makeGAParamsActionClickPhotoPropertyTransparency,
} from './features/property_panel/sub_header/makers/makeGAParamsPhotoPropertySubHeader';
import {
  makeGAParamsActionClickPropertyAlign,
  makeGAParamsActionClickPropertyCrop,
  makeGAParamsActionClickPropertyFlip,
  makeGAParamsActionClickPropertyZIndex,
} from './features/property_panel/sub_header/makers/makeGAParamsPropertySubHeader';
import { makeGAParamsPropertyElementTransparency } from './features/property_panel/transparency/makeGAParamsPropertyElementTransparency';
import { makeGAParamsActionClickTemplateSearchPageBannerButton } from './features/template_search/makers/makeGAParmasActionClickTemplateSearchPageBannerButton';
import {
  makeGAParamsActionClickTemplateKeywordBanner,
  makeGAParamsActionViewTemplateKeywordBanner,
} from './features/template_search/makers/makeGAParmasActionTemplateKeywordBanner';
import { makeGAparamsActionClickTemplateSearchPagePriceFilterFree } from './features/template_search/makers/makeGAparamsActionClickTemplateSearchPagePriceFilterFree';
import { makeGAparamsActionClickTemplateSearchPagePriceFilterPremium } from './features/template_search/makers/makeGAparamsActionClickTemplateSearchPagePriceFilterPremium';
import { makeGAParamsActionClickTemplateWorkspace } from './features/template_workspace/makers/makeGAParamsActionClickTemplateWorkspace';
import { makeGAParamsActionSearchTemplateWorkspace } from './features/template_workspace/makers/makeGAParamsActionSearchTemplateWorkspace';
import { makeGAParamsActionViewTemplateWorkspace } from './features/template_workspace/makers/makeGAParamsActionViewTemplateWorkspace';
import { makeGAParamsActionDetailTemplateWorkspace } from './features/template_workspace/makers/makeGAParamsActionDetailTemplateWorkspace';
import { makeGAParamsAddResourceToFolderUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsAddResourceToFolderUpgradePopup';
import { makeGAParamsChangeAnimationAppearanceUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsChangeAnimationAppearanceUpgradePopup';
import { makeGAParamsChangeAnimationDelayUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsChangeAnimationDelayUpgradePopup';
import { makeGAParamsChangeAnimationDirectionUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsChangeAnimationDirectionUpgradePopup';
import { makeGAParamsChangeAnimationOutroDirectionUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsChangeAnimationOutroDirectionUpgradePopup';
import { makeGAParamsChangeAnimationSpeedUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsChangeAnimationSpeedUpgradePopup';
import { makeGAParamsChangeAnimationUnitUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsChangeAnimationUnitUpgradePopup';
import { makeGAParamsChangeViewModeUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsChangeViewModeUpgradePopup';
import { makeGAParamsClickAudioSearchFilterBannerUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsClickAudioSearchFilterBannerUpgradePopup';
import { makeGAParamsClickAudioSearchHeaderBannerUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsClickAudioSearchHeaderBannerUpgradePopup';
import { makeGAParamsClickGenerateAIPresentationOutlineUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsClickGenerateAIPresentationOutlineUpgradePopup';
import { makeGAParamsClickHeaderProInfoUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsClickHeaderProInfoUpgradePopup';
import { makeGAParamsClickPickBannerUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsClickPickBannerUpgradePopup';
import { makeGAParamsClickTemplateSearchFilterBannerUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsClickTemplateSearchFilterBannerUpgradePopup';
import { makeGAParamsClickTemplateSearchHeaderBannerUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsClickTemplateSearchHeaderBannerUpgradePopup';
import { makeGAParamsClickTextSearchFilterBannerUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsClickTextSearchFilterBannerUpgradePopup';
import { makeGAParamsClickUploadInfoUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsClickUploadInfoUpgradePopup';
import { makeGAParamsClickVideoSearchFilterBannerUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsClickVideoSearchFilterBannerUpgradePopup';
import { makeGAParamsClickVideoSearchHeaderBannerUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsClickVideoSearchHeaderBannerUpgradePopup';
import { makeGAParamsCopyDesignHistoryUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsCopyDesignHistoryUpgradePopup';
import { makeGAParamsCreateUploadFolderUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsCreateUploadFolderUpgradePopup';
import { makeGAParamsDownloadGifRestrictionUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsDownloadGifRestrictionUpgradePopup';
import { makeGAParamsDownloadMp4RestrictionUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsDownloadMp4RestrictionUpgradePopup';
import { makeGAParamsDownloadPremiumResourceUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsDownloadPremiumResourceUpgradePopup';
import { makeGAParamsDownloadVideoCountRestrictionUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsDownloadVideoCountRestrictionUpgradePopup';
import { makeGAParamsInviteMemberChangeRoleUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsInviteMemberChangeRoleUpgradePopup';
import { makeGAParamsMakeTemplateUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsMakeTemplateUpgradePopup';
import { makeGAParamsOpenUnknownFlowUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsOpenUnknownFlowUpgradePopup';
import { makeGAParamsPublishDesignWebUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsPublishDesignWebUpgradePopup';
import { makeGAParamsRemoveBackgroundUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsRemoveBackgroundUpgradePopup';
import { makeGAParamsRevertDesignIndividualVersionUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsRevertDesignIndividualVersionUpgradePopup';
import { makeGAParamsRevertDesignUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsRevertDesignUpgradePopup';
import { makeGAParamsSetClamPageAnimationUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsSetClamPageAnimationUpgradePopup';
import { makeGAParamsSetCutePageAnimationUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsSetCutePageAnimationUpgradePopup';
import { makeGAParamsSetFancyPageAnimationUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsSetFancyPageAnimationUpgradePopup';
import { makeGAParamsSetSophisticatedPageAnimationUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsSetSophisticatedPageAnimationUpgradePopup';
import { makeGAParamsSharePremiumContentsUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsSharePremiumContentsUpgradePopup';
import { makeGAParamsUploadFontUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsUploadFontUpgradePopup';
import { makeGAParamsUseBrandKitUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsUseBrandKitUpgradePopup';
import { makeGAParamsUsePremiumContentsUpgradePopup } from './features/upgrade_popup/makers/makeGAParamsUsePremiumContentsUpgradePopup';
import { GAParameterMakerFnType } from './types/GAParameterMakerFnType';
import { makeGAParamsActionClickUploadItemMoreButtonForAI } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionClickUploadItemMoreButtonForAI';
import { makeGAParamsActionClickUploadItemMoveButtonForAI } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionClickUploadItemMoveButtonForAI';
import { makeGAParamsActionClickUploadItemDownloadButtonForAI } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionClickUploadItemDownloadButtonForAI';
import { makeGAParamsActionClickUploadItemDeleteButtonForAI } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionClickUploadItemDeleteButtonForAI';
import { makeGAParamsActionChangeTemplateWithPrintoutSize } from '@configs/google_analytics/features/flag_popup/makers/makeGAParamsActionChangeTemplateWithPrintoutSize';
import { makeGAParamsActionClickAIImageBackgroundRemovalButton } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionClickAIImageBackgroundRemovalButton';
import { makeGAParamsActionClickAIImagePreview } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionClickAIImagePreview';
import { makeGAParamsActionClickGenerateSuggestedElementButton } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionClickGenerateSuggestedElementButton';
import { makeGAParamsActionUploadForAI } from '@configs/google_analytics/features/left_panel/ai_drawing/makers/makeGAParamsActionUploadForAI';
import { makeGAParamsActionClickSuccessTemplateWorkspace } from '@configs/google_analytics/features/template_workspace/makers/makeGAParamsActionClickSuccessTemplateWorkspace';
import {
  makeGAParamsActionClickDesignDownloadButton,
  makeGAParamsActionClickDesignShareButton,
} from './features/header_bar/download_popup/makers/makeGAParamsActionClickDownloadMobileButton';
import { makeGAParamsActionSelectDownloadFileType } from '@configs/google_analytics/features/modal/download/makers/makeGAParamsActionSelectDownloadFileType';
import { makeGAParamsActionClickDownloadModalPrintDownloadButton } from '@configs/google_analytics/features/modal/download/makers/makeGAParamsActionClickDownloadModalPrintDownloadButton';
import { makeGAParamsActionClickDownloadModalMakePrintButton } from '@configs/google_analytics/features/modal/download/makers/makeGAParamsActionClickDownloadModalMakePrintButton';
import { makeGAParamsActionClickAppHomeMoreTemplateButton } from './features/app/home/makers/makeGAParamsActionClickAppHomeMoreTemplateButton';
import { makeGAParamsActionClickAIPresentationTemplateBanner } from './features/ai/presentation/makers/makeGAParamsActionClickAIPresentationTemplateBanner';

type GAParameterMakerMapType = {
  [E in EventType]?:
    | GAParameterMakerFnType<E>
    | AIDrawingGAParameterMakerFnType<E>
    | ObjectRemovalGAParameterMakerFnType<E>;
};

/**
 * <EventType ,GA 파라미터 생성 함수> 연결하는 곳
 */
export const GAParameterMakerMap: GAParameterMakerMapType = {
  ACTION_CLICK_PICTURE_ITEM: makeGAParamsActionClickPicture,
  ACTION_CLICK_TEMPLATE_ITEM: makeGAParamsActionClickTemplate,
  ACTION_CLICK_UPLOAD_ITEM: makeGAParamsActionClickUploadItem,
  ACTION_CLICK_BACKGROUND_ITEM: makeGAParamsActionClickBackgroundItem,
  ACTION_CLICK_ELEMENT_ITEM: makeGAParamsActionClickElementItem,
  ACTION_CLICK_COLLECTION_ITEM: makeGAParamsActionClickCollectionItem,
  ACTION_CLICK_ELEMENT_ITEM_IN_COLLECTION_ITEM: makeGAParamsActionClickElementItemInCollectionItem,
  ACTION_CLICK_TEXT_ITEM: makeGAParamsActionClickTextItem,
  ACTION_SEARCH_TEMPLATE_ITEM: makeGAParamsActionSearchTemplate,
  ACTION_SEARCH_PICTURE_ITEM: makeGAParamsActionSearchPicture,
  ACTION_SEARCH_UPLOAD_ITEM: makeGAParamsActionSearchUploadItem,
  ACTION_CLICK_VIDEO_ITEM: makeGAParamsActionClickVideo,
  ACTION_SEARCH_VIDEO_ITEM: makeGAParamsActionSearchVideo,
  ACTION_CLICK_YOUTUBE_TAB: makeGAParamsActionClickYoutubeTab,
  ACTION_SEARCH_THEME_ITEM: makeGAParamsActionSearchTheme,
  ACTION_SEARCH_BACKGROUND_ITEM: makeGAParamsActionSearchBackgroundItem,
  ACTION_SEARCH_TEXT_ITEM: makeGAParamsActionSearchTextItem,
  ACTION_SEARCH_ELEMENT_ITEM: makeGAParamsActionSearchElementItem,
  ACTION_CLICK_YOUTUBE_MOVE_BUTTON: makeGAParamsActionClickYoutubeMoveButton,
  ACTION_CLICK_YOUTUBE_MAKE_BUTTON: makeGAParamsActionClickYoutubeMakeButton,
  ACTION_CLICK_VIDEO_EDITOR_PROMOTION_BANNER: makeGAParamsActionClickVideoEditorPromotionBanner,
  ACTION_CLICK_SLIDE_SHOW_BUTTON: makeGAParamsActionClickSlideShowButton,
  ACTION_CLICK_HEADER_SHARE_BUTTON: makeGAParamsActionClickHeaderShareButton,
  ACTION_CLICK_FONT_FILTER: makeGAParamsActionFontFilter,
  ACTION_CLICK_FONT_FILTER_LIST: makeGAParamsActionFontFilterList,
  ACTION_CLICK_FONT_FILTER_RESET: makeGAParamsActionFontFilterReset,
  ACTION_CLICK_FONT_FILTER_APPLY: makeGAParamsActionFontFilterApply,
  ACTION_CLICK_TEXT_STYLE_RECOMMEND: makeGAParamsActionTextStyleRecommend,
  ACTION_CLICK_EYE_DROPPER: makeGAParamsActionEyeDropper,
  ACTION_CLICK_SOLID_COLOR_PICKER: makeGAParamsActionSolidColorPicker,
  ACTION_CLICK_GRADATION_COLOR_PICKER: makeGAParamsActionGradationColorPicker,
  ACTION_CLICK_BRAND_KIT: makeGAParamsActionBrandKit,
  ACTION_CLICK_PAGE_ANIMATION_PRESET: makeGAParamsActionClickPageAnimationPreset,
  ACTION_CLICK_MAIN_MENU_BUTTON: makeGAParamsActionClickMainMenuButton,
  ACTION_CLICK_HEADER_FOLDER_BUTTON: makeGAParamsActionClickHeaderFolderButton,
  ACTION_CLICK_WORKSPACE_MOVE_BUTTON: makeGAParamsActionClickWorkspaceMoveButton,
  ACTION_CLICK_DESIGN_RESIZE_BUTTON: makeGAParamsActionClickEditorResizeButton,
  ACTION_CLICK_RESIZE_DIRECT_INPUT_BUTTON: makeGAParamsActionClickResizeDirectInputButton,
  ACTION_CLICK_DESIGN_PUBLIC_TOGGLE_BUTTON: makeGAParamsActionClickDesignPublicToggleButton,
  ACTION_CLICK_OPEN_FONT_LIST: makeGAParamsActionFontListOpen,
  ACTION_CLICK_FONT_LIST_CATEGORY_ALL: makeGAParamsActionFontCategoryAll,
  ACTION_CLICK_FONT_LIST_CATEGORY_BRAND: makeGAParamsActionFontCategoryBrandKit,
  ACTION_CLICK_FONT_LIST_CATEGORY_FAVORITE: makeGAParamsActionFontCategoryFavorite,
  ACTION_CLICK_CHANGE_ALL_SAME_FONT: makeGAParamsActionChangeAllSameFont,
  ACTION_CLICK_FONT_LIST_RECENT: makeGAParamsActionFontListRecent,
  ACTION_CLICK_SELECT_FONT_LIST: makeGAParamsActionSelectFontList,
  ACTION_CLICK_TEXT_CONTENT_LOCK: makeGAParamsActionTextLock,
  ACTION_CLICK_CALENDAR: makeGAParamsActionClickCalendar,
  ACTION_CLICK_APPLY_CALENDAR: makeGAParamsActionApplyCalendar,
  ACTION_CLICK_THEME_ITEM: makeGAParamsActionClickTheme,
  ACTION_SEARCH_AUDIO_ITEM: makeGAParamsActionSearchAudio,
  ACTION_CLICK_AUDIO_ITEM: makeGAParamsActionClickAudio,
  ACTION_CLICK_ADD_TEXT_BUTTON: makeGAParamsActionClickAddTextButton,
  ACTION_CLICK_LEFT_PANEL_MENU_BUTTON: makeGAParamsActionClickLeftPanelMenuButton,
  ACTION_DRAG_LEFT_PANEL_RESIZE_BUTTON: makeGAParamsActionDragLeftPanelResizeButton,
  ACTION_CLICK_TEMPLATE_SEARCH_LANGUAGE_BUTTON: makeGAParamsActionClickTemplateSearchLanguageButton,
  ACTION_CLICK_HEADER_BAR_DOWNLOAD_BUTTON: makeGAParamsActionClickHeaderBarDownloadButton,
  ACTION_CLICK_TEXT_ANIMATION_TAB: makeGAParamsActionTextAnimationTab,
  ACTION_CLICK_PATTERN_BACKGROUND_COLOR: makeGAParamsActionPatternBackgroundColor,
  ACTION_CLICK_PATTERN_BACKGROUND_SIZE: makeGAParamsActionPatternBackgroundSize,
  ACTION_CLICK_PROPERTY_ALIGN: makeGAParamsActionClickPropertyAlign,
  ACTION_CLICK_PROPERTY_ZINDEX: makeGAParamsActionClickPropertyZIndex,
  ACTION_CLICK_PROPERTY_FLIP: makeGAParamsActionClickPropertyFlip,
  ACTION_CLICK_PROPERTY_CROP: makeGAParamsActionClickPropertyCrop,
  ACTION_CLICK_PROPERTY_LINK: makeGAParamsActionClickPropertyLink,
  ACTION_CLICK_PROPERTY_STYLE_COPY: makeGAParamsActionClickPropertyStyleCopy,
  ACTION_CLICK_PROPERTY_LOCK: makeGAParamsActionClickPropertyLock,
  ACTION_CLICK_BROWSE_VIDEO_TEMPLATE_DOWNLOAD_PROGRESS:
    makeGAParamsActionClickBrowseVideoTemplateFromDownloadProgress,
  ACTION_CLICK_BROWSE_VIDEO_TEMPLATE_DOWNLOAD_COMPLETE:
    makeGAParamsActionClickBrowseVideoTemplateFromDownloadComplete,
  ACTION_CLICK_MAKE_VIDEO_WITH_MY_DESIGN: makeGAParamsActionClickMakeVideoWithMyDesign,
  ACTION_CLICK_DARK_MODE_ACTIVE_BUTTON: makeGAParamsActionClickDarkModeActiveButton,
  ACTION_CLICK_LAYER_ACTIVE_BUTTON: makeGAParamsActionClickLayerActiveButton,
  ACTION_CLICK_PAGE_NUMBER_BUTTON: makeGAParamsActionClickPageNumberButton,
  ACTION_CLICK_PAGE_NUMBER_ACTIVE_BUTTON: makeGAParamsActionClickPageNumberActiveButton,
  ACTION_CLICK_TEMPLATE_CREATE_BUTTON: makeGAParamsActionClickTemplateCreateButton,
  ACTION_CLICK_CHANGE_TEMPLATE_SIZE_BUTTON: makeGAParamsActionClickChangeTemplateSizeButton,
  ACTION_CLICK_HEADER_BAR_LOGIN_BUTTON: makeGAParamsActionClickHeaderBarLoginButton,
  ACTION_SIGN_IN_USER: makeGAParamsActionSignInUser,
  ACTION_SIGN_UP_USER: makeGAParamsActionSignUpUser,
  ACTION_CLICK_BACKGROUND_EDIT_BUTTON: makeGAActionClickBackgroundEditButton,
  ACTION_CLICK_BACKGROUND_COLOR_CHIP_BUTTON: makeGAActionClickBackgroundColorChipButton,
  ACTION_CLICK_BACKGROUND_PICTURE_TAB_BUTTON: makeGAActionClickBackgroundPictureTabButton,
  ACTION_CLICK_BACKGROUND_PATTERN_TAB_BUTTON: makeGAActionClickBackgroundPatternTabButton,
  ACTION_CLICK_TEMPLATE_SEARCH_FILTER_TIER_BUTTON:
    makeGAParamsActionClickTemplateSearchFilterTierButton,
  ACTION_CLICK_MORE_INFO_BUTTON: makeGAParamsActionClickMoreInfoButton,
  ACTION_CLICK_MORE_INFO_POPOVER_PRICE_INFO_BUTTON:
    makeGAParamsActionClickMoreInfoPopoverPriceInfoButton,
  ACTION_CLICK_THEME_SHUFFLE_BUTTON: makeGAParamsActionClickThemeShuffleButton,
  ACTION_CLICK_THEME_SELECT_BUTTON: makeGAParamsActionClickThemeSelectButton,
  ACTION_CLICK_CURRENT_THEME_INITIALIZE_BUTTON: makeGAParamsActionClickCurrentThemeInitializeButton,
  ACTION_CLICK_RECOMMEND_THEME_MORE_INFO_BUTTON:
    makeGAParamsActionClickRecommendThemeMoreInfoButton,
  ACTION_CLICK_APPLY_THEME_ALL_PAGE: makeGAParamsActionClickApplyThemeAllPage,
  ACTION_CLICK_HEADER_DESIGN_TITLE: makeGAParamsActionClickHeaderDesignTitle,
  ACTION_CLICK_MAIN_MENU_DESIGN_TITLE: makeGAParamsActionClickMainMenuDesignTitle,
  ACTION_CLICK_LEFT_PANEL_EXPAND_BUTTON: makeGAParamsActionClickLeftPanelExpandButton,
  ACTION_CLICK_KEYWORD_CATEGORY_INDICATOR_BUTTON:
    makeGAParamsActionClickKeywordCategoryIndicatorButton,
  ACTION_CLICK_KEYWORD_CATEGORY_FINAL_INDICATOR_BUTTON:
    makeGAParamsActionClickKeywordCategoryFinalIndicatorButton,
  ACTION_CLICK_SHARE_PREVIEW_BUTTON: makeGAParamsActionSharePreviewButton,
  ACTION_CLICK_SHARE_LINK_COPY_BUTTON: makeGAParamsActionShareLinkCopyButton,
  ACTION_CLICK_SHARE_SNS_FACEBOOK_BUTTON: makeGAParamsActionShareSNSFacebookButton,
  ACTION_CLICK_SHARE_SNS_TWITTER_BUTTON: makeGAParamsActionShareSNSTwitterButton,
  ACTION_CLICK_SHARE_SNS_KAKAO_TALK_BUTTON: makeGAParamsActionShareSNSKakaoTalkButton,
  ACTION_CLICK_SHARE_SNS_KAKAO_STORY_BUTTON: makeGAParamsActionShareSNSKakaoStoryButton,
  ACTION_CLICK_SHARE_SNS_NAVER_BLOG_BUTTON: makeGAParamsActionShareSNSNaverBlogButton,
  ACTION_CLICK_SHARE_SNS_NAVER_BAND_BUTTON: makeGAParamsActionShareSNSNaverBandButton,
  ACTION_CLICK_SHARE_PAGE_STYLE_DROPDOWN_BUTTON: makeGAParamsActionSharePageStyleDropdownButton,
  ACTION_CLICK_SHARE_PAGE_STYLE_SLIDE_BUTTON: makeGAParamsActionSharePageStyleSlideButton,
  ACTION_CLICK_SHARE_PAGE_STYLE_SCROLL_BUTTON: makeGAParamsActionSharePageStyleScrollButton,
  ACTION_CLICK_SHARE_PAGE_STYLE_QUIZE_BUTTON: makeGAParamsActionSharePageStyleQuizeButton,
  ACTION_CLICK_SHARE_DETAIL_OPTION_BUTTON: makeGAParamsActionShareDetailOpitonButton,
  ACTION_CLICK_SHARE_DETAIL_OPTION_COPY_TOGGLE: makeGAParamsActionShareDetailOpitonCopyToggle,
  ACTION_CLICK_SHARE_DETAIL_OPTION_LIKE_AND_COMMENT_TOGGLE:
    makeGAParamsActionShareDetailOpitonLikeAndCommentToggle,
  ACTION_CLICK_SHARE_DETAIL_OPTION_SLIDE_SHOW_TOGGLE:
    makeGAParamsActionShareDetailOpitonSlideShowToggle,
  ACTION_CLICK_SHARE_DETAIL_OPTION_SHARE_TOGGLE: makeGAParamsActionShareDetailOpitonShareToggle,
  ACTION_USE_PREMIUM_CONTENTS_UPGRADE_POPUP: makeGAParamsUsePremiumContentsUpgradePopup,
  ACTION_DOWNLOAD_PREMIUM_RESOURCE_UPGRADE_POPUP: makeGAParamsDownloadPremiumResourceUpgradePopup,
  ACTION_CLICK_UPLOAD_INFO_UPGRADE_POPUP: makeGAParamsClickUploadInfoUpgradePopup,
  ACTION_ADD_RESOURCE_TO_FOLDER_UPGRADE_POPUP: makeGAParamsAddResourceToFolderUpgradePopup,
  ACTION_MAKE_TEMPLATE_UPGRADE_POPUP: makeGAParamsMakeTemplateUpgradePopup,
  ACTION_REVERT_DESIGN_UPGRADE_POPUP: makeGAParamsRevertDesignUpgradePopup,
  ACTION_REVERT_DESIGN_INDIVIDUAL_VERSION_UPGRADE_POPUP:
    makeGAParamsRevertDesignIndividualVersionUpgradePopup,
  ACTION_COPY_DESIGN_HISTORY_UPGRADE_POPUP: makeGAParamsCopyDesignHistoryUpgradePopup,
  ACTION_REMOVE_BACKGROUND_UPGRADE_POPUP: makeGAParamsRemoveBackgroundUpgradePopup,
  ACTION_CHANGE_VIEW_MODE_UPGRADE_POPUP: makeGAParamsChangeViewModeUpgradePopup,
  ACTION_PUBLISH_DESIGN_WEB_UPGRADE_POPUP: makeGAParamsPublishDesignWebUpgradePopup,
  ACTION_INVITE_MEMBER_CHANGE_ROLE_UPGRADE_POPUP: makeGAParamsInviteMemberChangeRoleUpgradePopup,
  ACTION_CREATE_UPLOAD_FOLDER_UPGRADE_POPUP: makeGAParamsCreateUploadFolderUpgradePopup,
  ACTION_CHANGE_ANIMATION_APPEARANCE_UPGRADE_POPUP:
    makeGAParamsChangeAnimationAppearanceUpgradePopup,
  ACTION_CHANGE_ANIMATION_SPEED_UPGRADE_POPUP: makeGAParamsChangeAnimationSpeedUpgradePopup,
  ACTION_CHANGE_ANIMATION_DIRECTION_UPGRADE_POPUP: makeGAParamsChangeAnimationDirectionUpgradePopup,
  ACTION_CHANGE_ANIMATION_OUTRO_DIRECTION_UPGRADE_POPUP:
    makeGAParamsChangeAnimationOutroDirectionUpgradePopup,
  ACTION_CHANGE_ANIMATION_DELAY_UPGRADE_POPUP: makeGAParamsChangeAnimationDelayUpgradePopup,
  ACTION_CHANGE_ANIMATION_UNIT_UPGRADE_POPUP: makeGAParamsChangeAnimationUnitUpgradePopup,
  ACTION_DOWNLOAD_MP4_RESTRICTION_UPGRADE_POPUP: makeGAParamsDownloadMp4RestrictionUpgradePopup,
  ACTION_DOWNLOAD_GIF_RESTRICTION_UPGRADE_POPUP: makeGAParamsDownloadGifRestrictionUpgradePopup,
  ACTION_SHARE_PREMIUM_CONTENTS_UPGRADE_POPUP: makeGAParamsSharePremiumContentsUpgradePopup,
  ACTION_UPLOAD_FONT_UPGRADE_POPUP: makeGAParamsUploadFontUpgradePopup,
  ACTION_USE_BRAND_KIT_UPGRADE_POPUP: makeGAParamsUseBrandKitUpgradePopup,
  ACTION_SET_CLAM_PAGE_ANIMATION_UPGRADE_POPUP: makeGAParamsSetClamPageAnimationUpgradePopup,
  ACTION_SET_FANCY_PAGE_ANIMATION_UPGRADE_POPUP: makeGAParamsSetFancyPageAnimationUpgradePopup,
  ACTION_SET_CUTE_PAGE_ANIMATION_UPGRADE_POPUP: makeGAParamsSetCutePageAnimationUpgradePopup,
  ACTION_SET_SOPHISTICATED_PAGE_ANIMATION_UPGRADE_POPUP:
    makeGAParamsSetSophisticatedPageAnimationUpgradePopup,
  ACTION_DOWNLOAD_VIDEO_COUNT_RESTRICTION_UPGRADE_POPUP:
    makeGAParamsDownloadVideoCountRestrictionUpgradePopup,
  ACTION_CLICK_HEADER_PRO_INFO_UPGRADE_POPUP: makeGAParamsClickHeaderProInfoUpgradePopup,
  ACTION_CLICK_PICK_BANNER_UPGRADE_POPUP: makeGAParamsClickPickBannerUpgradePopup,
  ACTION_CLICK_TEMPLATE_SEARCH_HEADER_BANNER_UPGRADE_POPUP:
    makeGAParamsClickTemplateSearchHeaderBannerUpgradePopup,
  ACTION_CLICK_VIDEO_SEARCH_HEADER_BANNER_UPGRADE_POPUP:
    makeGAParamsClickVideoSearchHeaderBannerUpgradePopup,
  ACTION_CLICK_AUDIO_SEARCH_HEADER_BANNER_UPGRADE_POPUP:
    makeGAParamsClickAudioSearchHeaderBannerUpgradePopup,
  ACTION_CLICK_TEMPLATE_SEARCH_FILTER_BANNER_UPGRADE_POPUP:
    makeGAParamsClickTemplateSearchFilterBannerUpgradePopup,
  ACTION_CLICK_VIDEO_SEARCH_FILTER_BANNER_UPGRADE_POPUP:
    makeGAParamsClickVideoSearchFilterBannerUpgradePopup,
  ACTION_CLICK_AUDIO_SEARCH_FILTER_BANNER_UPGRADE_POPUP:
    makeGAParamsClickAudioSearchFilterBannerUpgradePopup,
  ACTION_CLICK_TEXT_SEARCH_FILTER_BANNER_UPGRADE_POPUP:
    makeGAParamsClickTextSearchFilterBannerUpgradePopup,
  ACTION_CLICK_GENERATE_AI_PRESENTATION_OUTLINE_UPGRADE_POPUP:
    makeGAParamsClickGenerateAIPresentationOutlineUpgradePopup,
  ACTION_AI_TOOLS_UPGRADE_POPUP: makeGAParamsAIToolsUpgradePopup,
  ACTION_OBJECT_REMOVAL_UPGRADE_POPUP: makeGAParamsObjectRemovalUpgradePopup,
  ACTION_UPSCALE_UPGRADE_POPUP: makeGAParamsUpscaleUpgradePopup,
  ACTION_INPAINTING_UPGRADE_POPUP: makeGAParamsInpaintingUpgradePopup,
  ACTION_OPEN_UNKNOWN_FLOW_UPGRADE_POPUP: makeGAParamsOpenUnknownFlowUpgradePopup,
  ACTION_CLICK_SHARE_TEMPLATE_LINK_SHARE_BUTTON: makeGAParamsActionShareTemplateLinkShareButton,
  ACTION_CLICK_SHARE_MEMBER_INVITE_TAB_BUTTON: makeGAParamsActionShareMemberInviteTabButton,
  ACTION_CLICK_SHARE_MEMBER_INVITE_EMAIL_TOGGLE: makeGAParamsActionShareMemberInviteEmailToggle,
  ACTION_VIEW_AUDIO_ITEM: makeGAParamsActionViewAudioItem,
  ACTION_VIEW_BACKGROUND_ITEM: makeGAParamsActionViewBackgroundItem,
  ACTION_VIEW_ELEMENT_ITEM: makeGAParamsActionViewElementItem,
  ACTION_VIEW_PICTURE_ITEM: makeGAParamsActionViewPictureItem,
  ACTION_VIEW_TEXT_ITEM: makeGAParamsActionViewTextItem,
  ACTION_VIEW_THEME_ITEM: makeGAParamsActionViewTheme,
  ACTION_VIEW_UPLOAD_ITEM: makeGAParamsActionViewUploadItem,
  ACTION_VIEW_VIDEO_ITEM: makeGAParamsActionViewVideo,
  ACTION_CLICK_MAIN_MENU_NEW_DESIGN_BUTTON: makeGAParamsActionClickNewDesignButton,
  ACTION_CLICK_MAIN_MENU_CREATE_COPY_BUTTON: makeGAParamsActionClickCreateCopyButton,
  ACTION_CLICK_MAIN_MENU_FIND_TEXT_BUTTON: makeGAParamsActionClickFindTextButton,
  ACTION_CLICK_MAIN_MENU_HELP_BUTTON: makeGAParamsActionClickHelpButton,
  ACTION_VIEW_TEMPLATE_ITEM: makeGAParamsActionViewTemplateItem,
  ACTION_CLICK_GUIDE_LINE_MENU_BUTTON: makeGAParamsActionClickGuideLineMenuButton,
  ACTION_CLICK_GUIDE_LINE_TOGGLE: makeGAParamsActionClickGuideLineToggle,
  ACTION_CLICK_GUIDE_LINE_LOCK_TOGGLE: makeGAParamsActionClickGuideLineLockToggle,
  ACTION_CLICK_GUIDE_LINE_RULER_TOGGLE: makeGAParamsActionClickGuideLineRulerToggle,
  ACTION_CLICK_SNAP_GUIDE_TOGGLE: makeGAParamsActionClickSnapGuideToggle,
  ACTION_CLICK_ROW_GUIDE_LINE_BUTTON: makeGAParamsActionClickRowGuideLineButton,
  ACTION_CLICK_COLUMN_GUIDE_LINE_BUTTON: makeGAParamsActionClickColumnGuideLineButton,
  ACTION_CLICK_GUIDE_LINE_RESET_BUTTON: makeGAParamsActionClickGuideLineResetButton,
  ACTION_CLICK_AUTO_SAVE_TOGGLE: makeGAParamsActionClickAutoSaveToggle,
  ACTION_CLICK_MORE_MENU_BUTTON: makeGAParamsActionClickMoreMenuButton,
  ACTION_CLICK_MORE_MENU_DRAFT_BUTTON: makeGAParamsActionClickMoreMenuDraftButton,
  ACTION_CLICK_MORE_MENU_PRINTOUT_BUTTON: makeGAParamsActionClickMoreMenuPrintoutButton,
  ACTION_CLICK_MORE_MENU_DOWNLOAD_BUTTON: makeGAParamsActionClickMoreMenuDownloadButton,
  ACTION_CLICK_MORE_MENU_SHARE_BUTTON: makeGAParamsActionClickMoreMenuShareButton,
  ACTION_CLICK_MORE_MENU_SLIDE_SHOW_BUTTON: makeGAParamsActionClickMoreMenuSlideShowButton,
  ACTION_CLICK_MORE_MENU_DESIGN_EMBEDDING_BUTTON:
    makeGAParamsActionClickMoreMenuDesignEmbeddingButton,
  ACTION_CLICK_DRAFT_REQUEST_POPUP_REQUEST_ROLE_SELECT_BUTTON:
    makeGAParamsActionClickRequestPopupRequestRoleSelectButton,
  ACTION_CLICK_DRAFT_REQUEST_POPUP_REQUEST_ROLE_SELECT_EDIT_MODE:
    makeGAParamsActionClickRequestPopupRequestRoleEdit,
  ACTION_CLICK_DRAFT_REQUEST_POPUP_REQUEST_ROLE_SELECT_FEEDBACK_MODE:
    makeGAParamsActionClickRequestPopupRequestRoleFeedback,
  ACTION_CLICK_DRAFT_REQUEST_POPUP_REQUEST_ROLE_SELECT_VIEW_MODE:
    makeGAParamsActionClickRequestPopupRequestRoleView,
  ACTION_CLICK_DRAFT_REQUEST_POPUP_REQUEST_ROLE_SELECT_DELETE:
    makeGAParamsActionClickRequestPopupRequestRoleDelete,
  ACTION_CLICK_MAIN_MENU_CHANGE_VIEW_MODE_BUTTON: makeGAParamsActionClickChangeViewModeButton,
  ACTION_CLICK_MAIN_MENU_EDIT_MODE_BUTTON: makeGAParamsActionClickEditModeButton,
  ACTION_CLICK_MAIN_MENU_FEEDBACK_MODE_BUTTON: makeGAParamsActionClickFeedbackModeButton,
  ACTION_CLICK_MAIN_MENU_VIEW_MODE_BUTTON: makeGAParamsActionClickViewModeButton,
  ACTION_CLICK_INVITE_FRIEND_LINK_COPY_BUTTON: makeGAParamsActionInviteFriendLinkCopy,
  ACTION_CLICK_TEMPLATE_TYPE_FILTER_BUTTON: makeGAParamsActionClickTemplateTypeFilterButton,
  ACTION_CLICK_TEMPLATE_SEARCH_FILTER_BUTTON: makeGAParamsActionClickTemplateSearchFilterButton,
  ACTION_DOWNLOAD_EVENT: makeGAParamsActionDownload,
  ACTION_DOWNLOAD_FILE_TYPE_SELECT_EVENT: makeGAParamsActionSelectDownloadFileType,
  ACTION_CLICK_DOWNLOAD_MODAL_PRINT_DOWNLOAD_BUTTON:
    makeGAParamsActionClickDownloadModalPrintDownloadButton,
  ACTION_CLICK_DOWNLOAD_MODAL_MAKE_PRINT_BUTTON:
    makeGAParamsActionClickDownloadModalMakePrintButton,
  ACTION_CLICK_QR_CODE_MAKE_BUTTON: makeGAParamsActionClickQRCodeMakeButton,
  ACTION_CLICK_QR_CODE_MAKE_MOBILE_BUTTON: makeGAParamsActionClickQRCodeMakeMobileButton,
  ACTION_CLICK_QR_CODE_SYMBOL_MARK_SWITCH: makeGAParamsActionClickQRCodeSymbolMarkSwitch,
  ACTION_CLICK_QR_CODE_SYMBOL_MARK_ICON: makeGAParamsActionClickQRCodeSymbolMarkIcon,
  ACTION_CLICK_QR_CODE_SYMBOL_MARK_UPLOAD_BUTTON:
    makeGAParamsActionClickQRCodeSymbolMarkUploadButton,
  ACTION_UPLOAD_QR_CODE_SYMBOL_MARK_IMAGE: makeGAParamsActionUploadQRCodeSymbolMarkImage,
  ACTION_CLICK_LEFT_PANEL_AI_DRAWING_MENU_BUTTON:
    makeGAParamsActionClickLeftPanelAIDrawingMenuButton,
  ACTION_CLICK_GENERATE_AI_IMAGE_BUTTON: makeGAParamsActionClickGenerateAIImageButton,
  ACTION_CLICK_CANCEL_GENERATING_AI_IMAGE_BUTTON:
    makeGAParamsActionClickCancelGeneratingAIImageButton,
  ACTION_CLICK_GENERATED_AI_IMAGE_WHEN_ALL_TASKS_ARE_UNFINISHED:
    makeGAParamsActionClickGeneratedAIImageWhenAllTasksAreUnfinished,
  ACTION_CLICK_GENERATED_AI_IMAGE_WHEN_ALL_TASKS_ARE_FINISHED:
    makeGAParamsActionClickGeneratedAIImageWhenAllTasksAreFinished,
  ACTION_CLICK_RECREATE_AI_IMAGE_BUTTON: makeGAParamsActionClickRecreateAIImageButton,
  ACTION_CLICK_BACK_TO_GENERATE_STEP_BUTTON: makeGAParamsActionClickBackToGenerateStepButton,
  ACTION_CLICK_RECREATE_WITH_GENERATED_AI_IMAGE_BUTTON:
    makeGAParamsActionClickRecreateWithGeneratedAIImageButton,
  ACTION_VIEW_RESULT_WHEN_ALL_TASKS_ARE_FINISHED:
    makeGAParamsActionViewResultWhenAllTasksAreFinished,
  ACTION_CLICK_SHOW_STYLE_LIST_BUTTON: makeGAParamsActionClickShowStyleListButton,
  ACTION_CLICK_STYLE_ITEM: makeGAParamsActionClickStyleItem,
  ACTION_CLICK_USE_EXAMPLE_BUTTON: makeGAParamsActionClickUseExampleButton,
  ACTION_CLICK_STYLE_LIST_BACK_BUTTON: makeGAParamsActionClickStyleListBackButton,
  ACTION_CLICK_CATEGORY_PACK_ITEM: makeGAParamsActionClickCategoryPackItem,
  ACTION_CLICK_AI_IMAGE_BACKGROUND_REMOVAL_BUTTON:
    makeGAParamsActionClickAIImageBackgroundRemovalButton,
  ACTION_CLICK_AI_IMAGE_PREVIEW: makeGAParamsActionClickAIImagePreview,
  ACTION_CLICK_GENERATE_SUGGESTED_ELEMENT_BUTTON:
    makeGAParamsActionClickGenerateSuggestedElementButton,
  ACTION_UPLOAD_FOR_AI: makeGAParamsActionUploadForAI,
  ACTION_CLICK_GLOBAL_UPDATE_MODAL_NEXT_BUTTON: makeGAParamsActionClickGlobalUpdateModalNextButton,
  ACTION_CLICK_AI_DESIGN_ASSISTANT_POPUP_BUTTON:
    makeGAParamsActionClickAIDesignAssistantPopupButton,
  ACTION_CLICK_AI_DESIGN_ASSISTANT_POPUP_AI_DRAWING_BUTTON:
    makeGAParamsActionClickAIDesignAssistantPopupAIDrawingButton,
  ACTION_CLICK_AI_DESIGN_ASSISTANT_POPUP_AI_WRITING_BUTTON:
    makeGAParamsActionClickAIDesignAssistantPopupAIWritingButton,
  ACTION_CLICK_AI_DESIGN_ASSISTANT_POPUP_AI_CHATBOT_BUTTON:
    makeGAParamsActionClickAIDesignAssistantPopupAIChatBotButton,
  ACTION_CLICK_DESIGN_SHARE_RESOURCE: makeGAParamsActionClickDesignShareResource,
  ACTION_SEARCH_TEMPLATE_RECOMMEND_CHATBOT: makeGAParamsActionSearchTemplateRecommendChatBotPayload,
  ACTION_VIEW_RECOMMENDED_TEMPLATE_IN_CHATBOT: makeGAParamsActionViewRecommendedTemplateInChatBot,
  ACTION_CLICK_PROPERTY_ELEMENT_MOVE: makeGAParamsActionClickPropertyElementMove,
  ACTION_CLICK_PROPERTY_OVERLAPPING_ELEMENT_SELECT:
    makeGAParamsActionClickPropertyOverlappingElementSelect,
  ACTION_CLICK_PROPERTY_ELEMENT_SIZE: makeGAParamsActionClickPropertyElementSize,
  ACTION_CLICK_PROPERTY_ELEMENT_ANIMATION: makeGAParmasActionClickPropertyElementAnimation,
  ACTION_CLICK_PROPERTY_ELEMENT_TRANSPARENCY: makeGAParamsPropertyElementTransparency,
  ACTION_CLICK_PROPERTY_ELEMENT_INFORMATION: makeGAParamsPropertyElementInformation,
  ACTION_CLICK_PROPERTY_COLOR: makeGAParamsActionClickPropertyColor,
  ACTION_CLICK_PROPERTY_FILTER: makeGAParamsActionClickPropertyFilter,
  ACTION_CLICK_PROPERTY_ADJUSTMENT: makeGAParamsActionClickPropertyAdjustment,
  ACTION_CLICK_PROPERTY_EFFECT: makeGAParamsActionClickPropertyEffect,
  ACTION_CLICK_PROPERTY_MAKE_BACKGROUND: makeGAParamsActionClickPropertyMakeBackground,
  ACTION_CLICK_PROPERTY_DELETE_BACKGROUND: makeGAParamsActionClickPropertyDeleteBackground,
  ACTION_CLICK_PROPERTY_INIT_BACKGROUND: makeGAParamsActionClickPropertyInitBackground,
  ACTION_CLICK_PROPERTY_INIT_PICTURE: makeGAParamsActionClickPropertyInitPicture,
  ACTION_CLICK_PROPERTY_MORE: makeGAParamsActionClickPropertyMore,
  ACTION_CLICK_PROPERTY_MORE_COPY: makeGAParamsActionClickPropertyMoreCopy,
  ACTION_CLICK_PROPERTY_MORE_PASTE: makeGAParamsActionClickPropertyMorePaste,
  ACTION_CLICK_PROPERTY_MORE_MULTI_SELECT: makeGAParamsActionClickPropertyMoreMultiSelect,
  ACTION_CLICK_PROPERTY_MORE_LAYER: makeGAParamsActionClickPropertyMoreLayer,
  ACTION_CLICK_PROPERTY_MORE_ALIGN: makeGAParamsActionClickPropertyMoreAlign,
  ACTION_CLICK_PROPERTY_MORE_DELETE: makeGAParamsActionClickPropertyMoreDelete,
  ACTION_CLICK_PROPERTY_EDIT_DATA: makeGAParamsActionClickPropertyEditData,
  ACTION_CLICK_PROPERTY_PLAY: makeGAParamsActionClickPropertyPlay,
  ACTION_CLICK_PROPERTY_STYLING: makeGAParamsActionClickPropertyStyling,
  ACTION_CLICK_PROPERTY_FONT: makeGAParamsActionClickPropertyFont,
  ACTION_CLICK_PROPERTY_TEXT_SIZE: makeGAParamsActionClickPropertyTextSize,
  ACTION_CLICK_PROPERTY_TEXT_COLOR: makeGAParmasActionClickPropertyTextColor,
  ACTION_CLICK_PROPERTY_BACKGROUND_COLOR: makeGAParmasActionClickPropertyBackgroundColor,
  ACTION_CLICK_PROPERTY_FORM: makeGAParamsActionclickPropertyForm,
  ACTION_CLICK_PROPERTY_TEXT_ADJUSTMENT: makeGAParamsActionClickPropertyTextAdjustment,
  ACTION_CLICK_PROPERTY_UNGROUP: makeGAParamsActionClickPropertyUngroup,
  ACTION_CLICK_PROPERTY_MAKE_GROUP: makeGAParamsActionClickPropertyMakeGroup,
  ACTION_CLICK_PROPERTY_DELETE: makeGAParamsActionClickPropertyDelete,
  ACTION_CLICK_TEMPLATE_CHATBOT_ITEM: makeGAParamsActionClickTemplateChatbotItem,
  ACTION_CLICK_UPLOAD_ITEM_MORE_BUTTON: makeGAParamsActionClickUploadItemMoreButton,
  ACTION_CLICK_UPLOAD_ITEM_MOVE_BUTTON: makeGAParamsActionClickUploadItemMoveButton,
  ACTION_CLICK_UPLOAD_ITEM_DOWNLOAD_BUTTON: makeGAParamsActionClickUploadItemDownloadButton,
  ACTION_CLICK_UPLOAD_ITEM_DELETE_BUTTON: makeGAParamsActionClickUploadItemDeleteButton,
  ACTION_CLICK_UPLOAD_ITEM_MORE_BUTTON_FOR_AI: makeGAParamsActionClickUploadItemMoreButtonForAI,
  ACTION_CLICK_UPLOAD_ITEM_MOVE_BUTTON_FOR_AI: makeGAParamsActionClickUploadItemMoveButtonForAI,
  ACTION_CLICK_UPLOAD_ITEM_DOWNLOAD_BUTTON_FOR_AI:
    makeGAParamsActionClickUploadItemDownloadButtonForAI,
  ACTION_CLICK_UPLOAD_ITEM_DELETE_BUTTON_FOR_AI: makeGAParamsActionClickUploadItemDeleteButtonForAI,
  ACTION_CLICK_AI_WRITING_EDIT_TEXT_BUTTON: makeGAParamsActionClickAIWritingEditTextButton,
  ACTION_CLICK_AI_PRESENTATION_TEMPLATE_BANNER: makeGAParamsActionClickAIPresentationTemplateBanner,
  ACTION_CLICK_AI_PRESENTATION_BUTTON: makeGAParamsActionClickAIPresentationButton,
  ACTION_CLICK_AI_PRESENTATION_GENERATE_OUTLINE_6PAGE_BUTTON:
    makeGAParamsActionClickAIPresentationGenerateOutline6PageButton,
  ACTION_CLICK_AI_PRESENTATION_GENERATE_OUTLINE_7PAGE_BUTTON:
    makeGAParamsActionClickAIPresentationGenerateOutline7PageButton,
  ACTION_CLICK_AI_PRESENTATION_GENERATE_OUTLINE_8PAGE_BUTTON:
    makeGAParamsActionClickAIPresentationGenerateOutline8PageButton,
  ACTION_CLICK_AI_PRESENTATION_GENERATE_OUTLINE_OTHER_EXAMPLE_BUTTON:
    makeGAParamsActionClickAIPresentationGenerateOutlineOtherExampleButton,
  ACTION_CLICK_AI_PRESENTATION_GENERATE_OUTLINE_BUTTON:
    makeGAParamsActionClickAIPresentationGenerateOutlineButton,
  ACTION_CLICK_AI_PRESENTATION_EDIT_OUTLINE_PREVIOUS_BUTTON:
    makeGAParamsActionClickAIPresentationEditOutlinePreviousButton,
  ACTION_CLICK_AI_PRESENTATION_EDIT_OUTLINE_6PAGE_BUTTON:
    makeGAParamsActionClickAIPresentationEditOutline6PageButton,
  ACTION_CLICK_AI_PRESENTATION_EDIT_OUTLINE_7PAGE_BUTTON:
    makeGAParamsActionClickAIPresentationEditOutline7PageButton,
  ACTION_CLICK_AI_PRESENTATION_EDIT_OUTLINE_8PAGE_BUTTON:
    makeGAParamsActionClickAIPresentationEditOutline8PageButton,
  ACTION_CLICK_AI_PRESENTATION_EDIT_OUTLINE_REGENERATE_BUTTON:
    makeGAParamsActionClickAIPresentationEditOutlineRegenerateButton,
  ACTION_CLICK_AI_PRESENTATION_EDIT_OUTLINE_TITLE_BUTTON:
    makeGAParamsActionClickAIPresentationEditOutlineTitleButton,
  ACTION_CLICK_AI_PRESENTATION_EDIT_OUTLINE_TEMPLATE_SELECT_BUTTON:
    makeGAParamsActionClickAIPresentationEditOutlineTemplateSelectButton,
  ACTION_CLICK_AI_PRESENTATION_TEMPLATE_SELECT_PREVIOUS_BUTTON:
    makeGAParamsActionClickAIPresentationTemplateSelectPreviousButton,
  ACTION_CLICK_AI_PRESENTATION_TEMPLATE_SELECT_BUTTON:
    makeGAParamsActionClickAIPresentationTemplateSelectButton,
  ACTION_CLICK_AI_PRESENTATION_TEMPLATE_GENERATE_BUTTON:
    makeGAParamsActionClickAIPresentationTemplateSelectGenerateButton,
  ACTION_CLICK_AI_PRESENTATION_LOADING_CLOSE_BUTTON:
    makeGAParamsActionClickAIPresentationLoadingCloseButton,
  ACTION_CLICK_AI_PRESENTATION_LOADING_FLOATING_BUTTON:
    makeGAParamsActionClickAIPresentationLoadingFloatingButton,
  ACTION_CLICK_AI_PRESENTATION_OVERWRITE_BUTTON:
    makeGAParamsActionClickAIPresentationOverwriteButton,
  ACTION_CLICK_AI_PRESENTATION_CREATE_NEW_DESIGN_BUTTON:
    makeGAParamsActionClickAIPresentationCreateNewDesignButton,
  ACTION_CLICK_AI_PRESENTATION_RETRY_BUTTON: makeGAParamsActionClickAIPresentationRetryButton,
  ACTION_CLICK_OBJECT_REMOVAL_GUIDE_BUTTON: makeGAParamsActionClickObjectRemovalGuideButton,
  ACTION_VIEW_OBJECT_REMOVAL_GUIDE_POPOVER: makeGAParamsActionViewObjectRemovalGuidePopover,
  ACTION_CLOSE_OBJECT_REMOVAL_GUIDE_POPOVER: makeGAParamsActionCloseObjectRemovalGuidePopover,
  ACTION_CLICK_HEADER_PRINTOUT_BUTTON: makeGAParamsActionClickHeaderPrintoutButton,
  ACTION_CLICK_PHOTO_EXTRA_EDIT_TOOL_BUTTON: makeGAParamsActionClickPhotoExtraEditToolButton,
  ACTION_CLICK_OBJECT_REMOVAL_TOOL_BUTTON: makeGAParamsActionClickObjectRemovalToolButton,
  ACTION_CLICK_OBJECT_REMOVAL_AREA_SELECTION_BUTTON:
    makeGAParamsActionClickObjectRemovalAreaSelectionButton,
  ACTION_CLICK_OBJECT_REMOVAL_ERASE_BUTTON: makeGAParamsActionClickObjectRemovalEraseButton,
  ACTION_CLICK_OBJECT_REMOVAL_HEADER_BUTTON: makeGAParamsActionClickObjectRemovalHeaderButton,
  ACTION_CLICK_OBJECT_REMOVAL_UNDO_REDO_BUTTON: makeGAParamsActionClickObjectRemovalUndoRedoButton,
  ACTION_CLICK_OBJECT_REMOVAL_BACK_BUTTON: makeGAParamsActionClickObjectRemovalBackButton,
  ACTION_CLICK_OBJECT_REMOVAL_EDIT_OTHER_BUTTON:
    makeGAParamsActionClickObjectRemovalEditOtherButton,
  ACTION_VIEW_TEMPLATE_WORKSPACE: makeGAParamsActionViewTemplateWorkspace,
  ACTION_SEARCH_TEMPLATE_WORKSPACE: makeGAParamsActionSearchTemplateWorkspace,
  ACTION_CLICK_TEMPLATE_WORKSPACE: makeGAParamsActionClickTemplateWorkspace,
  ACTION_CLICK_SUCCESS_TEMPLATE_WORKSPACE: makeGAParamsActionClickSuccessTemplateWorkspace,
  ACTION_VIEW_TEMPLATE_MY_LIST: makeGAParamsActionViewTemplateMyList,
  ACTION_DETAIL_TEMPLATE_WORKSPACE: makeGAParamsActionDetailTemplateWorkspace,
  ACTION_DETAIL_TEMPLATE_MY_LIST: makeGAParamsActionDetailTemplateMyList,
  ACTION_SEARCH_TEMPLATE_MY_LIST: makeGAParamsActionSearchTemplateMyList,
  ACTION_CLICK_TEMPLATE_MY_LIST: makeGAParamsActionClickTemplateMyList,
  ACTION_CLICK_SUCCESS_TEMPLATE_MY_LIST: makeGAParamsActionClickSuccessTemplateMyList,
  ACTION_CLICK_PHOTO_PROPERTY_CROP: makeGAParamsActionClickPhotoPropertyCrop,
  ACTION_CLICK_PHOTO_PROPERTY_TRANSPARENCY: makeGAParamsActionClickPhotoPropertyTransparency,
  ACTION_CLICK_PHOTO_PROPERTY_ALIGN: makeGAParamsActionClickPhotoPropertyAlign,
  ACTION_CLICK_PHOTO_PROPERTY_ORDER: makeGAParamsActionClickPhotoPropertyOrder,
  ACTION_CLICK_PHOTO_PROPERTY_FLIP: makeGAParamsActionClickPhotoPropertyFlip,
  ACTION_CLICK_PHOTO_PROPERTY_SET_AS_BACKGROUND:
    makeGAParamsActionClickPhotoPropertySetAsBackground,
  ACTION_CLICK_PHOTO_PROPERTY_RESET_PHOTO: makeGAParamsActionClickPhotoPropertyResetPhoto,
  ACTION_CLICK_FREE_SUBSCRIPTION_POPUP_START_FOR_FREE_BUTTON:
    makeGAParamsActionClickFreeSubscriptionModalStartForFreeButton,
  ACTION_CLICK_FREE_SUBSCRIPTION_POPUP_CLOSE_BUTTON:
    makeGAParamsActionClickFreeSubscriptionModalCloseButton,
  ACTION_CLICK_AFTER_DOWNLOAD_SURVEY_DO_NOT_SHOW_AGAIN_BUTTON:
    makeGAParamsActionClickAfterDownloadSurveyDoNotShowAgainButton,
  ACTION_CLICK_AFTER_DOWNLOAD_SURVEY_CLOSE_BUTTON:
    makeGAParamsActionClickAfterDownloadSurveyCloseButton,
  ACTION_CLICK_MIRICANVAS_LOGO_BUTTON: makeGAParamsActionClickMiricanvasLogoButton,
  ACTION_CLICK_ADD_BOOKMARK_BUTTON_WHEN_DOWNLOADING:
    makeGAParamsActionClickAddBookMarkButtonWhenDownloading,
  ACTION_CLICK_ADD_BOOKMARK_BUTTON_AFTER_DOWNLOAD:
    makeGAParamsActionClickAddBookmarkButtonAfterDownload,
  ACTION_CLICK_CONFIRM_BUTTON_ADD_BOOKMARK_GUIDE_POPUP:
    makeGAParamsActionClickConfirmButtonAddBookmark,
  ACTION_CLICK_CLOSE_BUTTON_ADD_BOOKMARK_GUIDE_POPUP:
    makeGAParamsActionClickCloseButtonAddBookmarkGuidePopup,
  ACTION_CLICK_CARET_BUTTON_OPEN_LEFT_MENUS: makeGAParamsActionClickCaretButtonOpenLeftMenus,
  ACTION_CLICK_CARET_BUTTON_CLOSE_LEFT_MENUS: makeGAParamsActionClickCaretButtonCloseLeftMenus,
  ACTION_RESIZE_PRINTOUT_TEMPLATE_SIZE: makeGAParamsActionResizePrintoutTemplateSize,
  ACTION_OVERWRITE_PRINTOUT_TEMPLATE: makeGAParamsActionOverwritePrintoutTemplate,
  ACTION_CHANGE_TEMPLATE_WITH_PRINTOUT_SIZE: makeGAParamsActionChangeTemplateWithPrintoutSize,
  ACTION_BY_AB_TEST: makeGAParamsActionByAbTest,
  ACTION_CLICK_TEMPLATE_SEARCH_PAGE_BANNER_BUTTON:
    makeGAParamsActionClickTemplateSearchPageBannerButton,
  ACTION_CLICK_LEFT_PANEL_UPLOAD_MENU_BUTTON: makeGAParamsActionClickLeftPanelUploadMenuButton,
  ACTION_CLICK_MAIN_HEADER_SIGN_UP_BUTTON: makeGAParamsActionClickMainHeaderSignUpButton,
  ACTION_CLICK_MAIN_PAGE_FLOATING_SIGN_UP_BUTTON:
    makeGAParamsActionClickMainPageFloatingSignUpButton,
  ACTION_CLICK_MAIN_PAGE_SIGN_UP_CTA: makeGAParamsActionClickMainPageSignUpCTA,
  ACTION_CLICK_GLOBAL_MAIN_PAGE_GO_TO_TEMPLATE_CTA:
    makeGAParamsActionClickGlobalMainPageGoToTemplateCTA,
  ACTION_CLICK_GLOBAL_MAIN_PAGE_GO_TO_EDITOR_CTA:
    makeGAParamsActionClickGlobalMainPageGoToEditorCTA,
  ACTION_CLICK_KOREA_MAIN_PAGE_FLOATING_BUTTON: makeGAParamsActionClickKoreaMainPageFloatingButton,
  ACTION_CLICK_GLOBAL_MAIN_PAGE_FLOATING_BUTTON:
    makeGAParamsActionClickGlobalMainPageFloatingButton,
  ACTION_CLICK_GLOBAL_MAIN_PAGE_START_NOW_BUTTON:
    makeGAParamsActionClickGlobalMainPageStartNowButton,
  ACTION_VIEW_APP_HOME: makeGAParamsActionAppHomeView,
  ACTION_CLICK_APP_HOME_BOTTOM_TAB: makeGAParamsActionAppHomeBottomTab,
  ACTION_CLICK_APP_HOME_WORKSPACE_BOTTOM_TAB: makeGAParamsActionAppHomeWorkspaceBottomTab,
  ACTION_CLICK_APP_HOME_CARD_NEWS: makeGAParamsActionAppHomeCardNews,
  ACTION_CLICK_APP_HOME_DETAIL_PAGE: makeGAParamsActionAppHomeDetailPage,
  ACTION_APP_EDITOR_CRASH: makeGAParamsActionAppEditorCrash,
  ACTION_CLICK_DESIGN_DOWNLOAD_BUTTON: makeGAParamsActionClickDesignDownloadButton,
  ACTION_APP_DESIGN_SHARE: makeGAParamsActionClickDesignShareButton,
  ACTION_CLICK_APP_HOME_PRESENTATION: makeGAParamsActionAppHomePresentation,
  ACTION_CLICK_APP_HOME_YOUTUBE_THUMBNAIL: makeGAParamsActionAppHomeYoutubeThumbnail,
  ACTION_CLICK_APP_HOME_SNS_SQUARE: makeGAParamsActionAppHomeSnsSquare,
  ACTION_CLICK_APP_HOME_WEB_POSTER_LANDSCAPE: makeGAParamsActionAppHomeWebPosterLandscape,
  ACTION_CLICK_APP_HOME_VIDEO: makeGAParamsActionAppHomeVideo,
  ACTION_CLICK_APP_HOME_MORE: makeGAParamsActionAppHomeMore,
  ACTION_CLICK_APP_HOME_START_EMPTY: makeGAParamsActionAppHomeStartEmpty,
  ACTION_CLICK_APP_HOME_START_EMPTY_SELECT_TYPE: makeGAParamsActionAppHomeStartEmptySelectType,
  ACTION_CLICK_APP_HOME_SEARCH: makeGAParamsActionAppHomeSearch,
  ACTION_CLICK_GLOBAL_TRIAL_WELCOME_POPUP_BUTTON: makeGAParamsActionGlobalTrialWelcomePopupButton,
  ACTION_OPEN_GLOBAL_TRIAL_UPGRADE_POPUP: makeGAParamsOpenGlobalTrialUpgradePopup,
  ACTION_USER_INITIALIZE: makeGAParamsActionUserInitialize,
  ACTION_CLICK_TEMPLATE_KEYWORD_BANNER: makeGAParamsActionClickTemplateKeywordBanner,
  ACTION_VIEW_TEMPLATE_KEYWORD_BANNER: makeGAParamsActionViewTemplateKeywordBanner,
  ACTION_CLICK_TEMPLATE_FAVORITE_BUTTON: makeGAParamsActionClickTemplateFavoriteButton,
  ACTION_CLICK_FAVORITE_PAGE_ENTRY_POINT: makeGAParamsActionClickFavoritePageEntryPoint,
  ACTION_CLICK_MY_LIST_PICK_BANNER_UPGRADE_POPUP: makeGAParamsClickMyListPickBannerUpgradePopup,
  ACTION_USE_MY_LIST_PREMIUM_CONTENTS_UPGRADE_POPUP:
    makeGAParamsUseMyListPremiumContentsUpgradePopup,
  ACTION_CLICK_AI_PRESENTATION_BUTTON_IN_AI_TOOLS:
    makeGAParamsActionClickAIPresentationButtonInAITools,
  ACTION_CLICK_TEMPLATE_SEARCH_PAGE_PRICE_FILTER_FREE:
    makeGAparamsActionClickTemplateSearchPagePriceFilterFree,
  ACTION_CLICK_TEMPLATE_SEARCH_PAGE_PRICE_FILTER_PREMIUM:
    makeGAparamsActionClickTemplateSearchPagePriceFilterPremium,
  ACTION_CLICK_NEW_FEATURE_AI_PRESENTATION_USE_BUTTON:
    makeGAParamsActionClickNewFeatureAIPresentationUse,
  ACTION_CLICK_APP_HOME_MORE_TEMPLATE_BUTTON: makeGAParamsActionClickAppHomeMoreTemplateButton,
};
