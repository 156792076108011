import { ObjectRemovalGTMTriggerEventParams } from '@configs/google_analytics/types/ObjectRemovalGTMTriggerEventParams';

/**
 * 데이터 레이어에 string 또는 undefined 값을 전송해야하므로,
 * 모든 속성에 대해 undefined 값을 할당한 객체를 초기화에 사용한다.
 */
export const DEFAULT_OBJECT_REMOVAL_EVENT_PAYLOAD: {
  [P in keyof ObjectRemovalGTMTriggerEventParams]: undefined;
} = {
  event: undefined,
  log_type: undefined,
  team_idx: undefined,
  account_id: undefined,
  design_idx: undefined,
  image_url: undefined,
  action_label: undefined,
  action_depth: undefined,
  etc1: undefined,
  etc2: undefined,
  etc3: undefined,
  etc4: undefined,
  etc5: undefined,
  plan_type: undefined,
  language_setting: undefined,
  action_type: undefined,
} satisfies ObjectRemovalGTMTriggerEventParams;
