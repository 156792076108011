import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionSearchBackgroundItem: GAParameterMakerFnType<
  'ACTION_SEARCH_BACKGROUND_ITEM'
> = (payload) => {
  return {
    event: 'search_background',
    log_type: 'search_background',
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
    search_status: payload.data.search_status,
    search_type: payload.data.search_type,
    search_term: payload.data.search_term,
    search_background_type: payload.data.search_background_type,
  };
};
