import Bowser from 'bowser';
import { DeviceType, DeviceTypeEnum } from '@configs/devices/device_type';

export const getDeviceType = (userAgent: string = getNavigatorUserAgent()): DeviceType => {
  if (!userAgent) {
    return DeviceTypeEnum.UNKNOWN;
  }

  if (isIpad(userAgent)) {
    return DeviceTypeEnum.TABLET;
  }

  if (isMac(userAgent)) {
    return DeviceTypeEnum.DESKTOP;
  }

  const parsedResult = Bowser.parse(userAgent);

  switch (parsedResult.platform.type) {
    case DeviceTypeEnum.DESKTOP:
    case DeviceTypeEnum.MOBILE:
    case DeviceTypeEnum.TABLET:
      return parsedResult.platform.type;
    default:
      return DeviceTypeEnum.UNKNOWN;
  }
};

export const getNavigatorUserAgent = () => {
  return typeof navigator !== 'undefined' ? navigator.userAgent : '';
};

/**
 * 아이패드 os 사파리 에서는 ua 가 mac 으로 설정된다. 따라서 추가적인 검증이 필요함
 * @returns {boolean}
 * @private
 */
export const isIpad = (userAgent: string): boolean => {
  if (typeof window === 'undefined') {
    // SSR 환경에서는 navigator 를 볼 수 없어 아이패드인지 확실하게 구분 할 수 없음
    return false;
  }

  const hasIPadStringInUserAgent = userAgent.toLowerCase().indexOf('ipad') !== -1;
  const hasIPhoneStringInUserAgent = userAgent.toLowerCase().indexOf('iphone') !== -1;

  if (hasIPhoneStringInUserAgent) {
    return false;
  }

  if (
    !hasIPadStringInUserAgent &&
    navigator.userAgent.match(/Mac/) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2
  ) {
    return true;
  }

  return hasIPadStringInUserAgent;
};

export const isMac = (userAgent: string): boolean => {
  const hasIPadStringInUserAgent = userAgent.toLowerCase().indexOf('ipad') !== -1;
  const hasIPhoneStringInUserAgent = userAgent.toLowerCase().indexOf('iphone') !== -1;

  if (userAgent.match(/Mac/) && !hasIPadStringInUserAgent && !hasIPhoneStringInUserAgent) {
    return true;
  }

  return false;
};

export const isAndroid = (): boolean => {
  if (!navigator) {
    return false;
  }

  return /android/i.test(navigator.userAgent);
};

/**
 * 모바일 또는 태블릿 웹뷰인지
 * */
export const isWebView = () => {
  if (typeof window === 'undefined') {
    // SSR 환경에서는 navigator 를 볼 수 없어 아이패드인지 확실하게 구분 할 수 없음
    return false;
  }

  if ((window as unknown as { isWebView: boolean })?.isWebView) return true;
};

/**
 * 모바일 웹뷰인지
 * */
export const isMobileWebView = () => {
  const deviceType = getDeviceType();
  return isWebView() && deviceType === DeviceTypeEnum.MOBILE;
};

/**
 * 태블릿 웹뷰인지
 * */
export const isTabletWebView = () => {
  const deviceType = getDeviceType();
  return isWebView() && deviceType === DeviceTypeEnum.TABLET;
};

/**
 * 모바일 웹 브라우저인지
 * */
export const isMobileWebBrowser = () => {
  return getDeviceType() === DeviceTypeEnum.MOBILE && !isWebView();
};

/**
 * 태블릿 웹 브라우저인지
 * */
export const isTabletWebBrowser = () => {
  return getDeviceType() === DeviceTypeEnum.TABLET && !isWebView();
};

/**
 * 데스크탑인지
 * */
export const isDesktop = () => {
  return getDeviceType() === DeviceTypeEnum.DESKTOP;
};

/**
 * 태블릿인지
 * */
export const isTablet = () => {
  return getDeviceType() === DeviceTypeEnum.TABLET;
};

export const isMobile = () => {
  return getDeviceType() === DeviceTypeEnum.MOBILE;
};

export const isTouchScreenDevice = () => {
  return typeof window === 'undefined'
    ? false
    : window.matchMedia('(hover: none) and (pointer: coarse)').matches;
};
