import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickAIPresentationGenerateOutlineOtherExampleButton: GAParameterMakerFnType<
  'ACTION_CLICK_AI_PRESENTATION_GENERATE_OUTLINE_OTHER_EXAMPLE_BUTTON'
> = () => ({
  log_type: 'generic_event_mican',
  event: 'gtm_trigger_event',
  location1: '에디터',
  location2: '프레젠테이션 자동 생성',
  location3: '개요 만들기',
  location4: '다른 예시',
  action1: '클릭',
  label1: '프레젠테이션 자동 생성_개요 만들기_다른 예시',
});
