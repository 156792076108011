import { atom, useAtom, useSetAtom } from 'jotai';
import { getRootStore } from '@configs/jotai/RootProvider';
import {
  DesignResourcePanelMenuType,
  designResourcePanelBarMenuEnum,
} from '@constants/DesignResourcePanelMenu';

export const selectedDesignResourceMenuAtom = atom<DesignResourcePanelMenuType>(
  designResourcePanelBarMenuEnum.TEMPLATE
);

export const useDesignResourceMenuStore = () => {
  const [selectedDesignResourceMenu, setSelectedDesignResourceMenu] = useAtom(
    selectedDesignResourceMenuAtom
  );

  return {
    selectedDesignResourceMenu,
    setSelectedDesignResourceMenu,
  };
};

export const useSetDesignResourceMenu = () => {
  return useSetAtom(selectedDesignResourceMenuAtom);
};

export const selectedDesignResourceMenuSnapshotValue = () =>
  getRootStore().get(selectedDesignResourceMenuAtom);
