import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionSearchTemplateMyList: GAParameterMakerFnType<
  'ACTION_SEARCH_TEMPLATE_MY_LIST'
> = (payload) => {
  return {
    beginning_template: payload.data.beginning_template,
    event: 'search_template_mylist',
    log_type: 'search_template_mylist',
    search_status: payload.data.search_status,
    search_type: payload.data.search_type,
    account_id: payload.data.account_id?.toString(),
    plan_type: payload.data.plan_type,
    team_idx: payload.data.team_idx?.toString(),
  };
};
