import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickTemplateFavoriteButton: GAParameterMakerFnType<
  'ACTION_CLICK_TEMPLATE_FAVORITE_BUTTON'
> = (payload) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: payload.data.location,
    action1: '클릭',
    label1: '찜',
    label2: payload.data.label2,
  };
};
