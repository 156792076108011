import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type GAParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionFontFilter: GAParameterMakerFnType<
  'ACTION_CLICK_FONT_FILTER'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '텍스트속성',
    location3: '폰트리스트_필터',
    location4: '필터적용',
    action1: '클릭',
    label1: '폰트리스트_필터',
  } satisfies GAParams;
};

export const makeGAParamsActionFontFilterList: GAParameterMakerFnType<
  'ACTION_CLICK_FONT_FILTER_LIST'
> = (payload) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '텍스트속성',
    location3: '폰트리스트_필터',
    location4: payload.data.categoryName,
    action1: '클릭',
    label1: `폰트리스트_필터_${payload.data.categoryName}_${payload.data.value}`,
  } satisfies GAParams;
};

export const makeGAParamsActionFontFilterReset: GAParameterMakerFnType<
  'ACTION_CLICK_FONT_FILTER_RESET'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '텍스트속성',
    location3: '폰트리스트_필터',
    location4: '초기화',
    action1: '클릭',
    label1: '폰트리스트_필터_초기화',
  } satisfies GAParams;
};

export const makeGAParamsActionFontFilterApply: GAParameterMakerFnType<
  'ACTION_CLICK_FONT_FILTER_APPLY'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '텍스트속성',
    location3: '폰트리스트_필터',
    location4: '필터적용',
    action1: '클릭',
    label1: '폰트리스트_필터_필터적용',
  } satisfies GAParams;
};

export const makeGAParamsActionFontListOpen: GAParameterMakerFnType<
  'ACTION_CLICK_OPEN_FONT_LIST'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '텍스트속성',
    location3: '폰트리스트',
    location4: '열기버튼',
    action1: '클릭',
    label1: '폰트리스트_열기',
  } satisfies GAParams;
};

export const makeGAParamsActionFontCategoryAll: GAParameterMakerFnType<
  'ACTION_CLICK_FONT_LIST_CATEGORY_ALL'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '텍스트속성',
    location3: '폰트리스트',
    location4: '전체',
    action1: '클릭',
    label1: '폰트리스트_카테고리_전체',
  } satisfies GAParams;
};

export const makeGAParamsActionFontCategoryBrandKit: GAParameterMakerFnType<
  'ACTION_CLICK_FONT_LIST_CATEGORY_BRAND'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '텍스트속성',
    location3: '폰트리스트',
    location4: '브랜드',
    action1: '클릭',
    label1: '폰트리스트_카테고리_브랜드',
  } satisfies GAParams;
};

export const makeGAParamsActionFontCategoryFavorite: GAParameterMakerFnType<
  'ACTION_CLICK_FONT_LIST_CATEGORY_FAVORITE'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '텍스트속성',
    location3: '폰트리스트',
    location4: '즐겨찾기',
    action1: '클릭',
    label1: '폰트리스트_카테고리_즐겨찾기',
  } satisfies GAParams;
};

export const makeGAParamsActionChangeAllSameFont: GAParameterMakerFnType<
  'ACTION_CLICK_CHANGE_ALL_SAME_FONT'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '텍스트 속성',
    location3: '폰트리스트',
    location4: '같은폰트모두변경',
    action1: '클릭',
    label1: '폰트리스트_같은폰트모두변경',
  } satisfies GAParams;
};

export const makeGAParamsActionFontListRecent: GAParameterMakerFnType<
  'ACTION_CLICK_FONT_LIST_RECENT'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '텍스트 속성',
    location3: '폰트리스트',
    location4: '최근',
    action1: '클릭',
    label1: '폰트리스트_최근',
  } satisfies GAParams;
};

export const makeGAParamsActionSelectFontList: GAParameterMakerFnType<
  'ACTION_CLICK_SELECT_FONT_LIST'
> = (payload) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '텍스트 속성',
    location3: '폰트리스트',
    location4: '폰트선택',
    action1: '클릭',
    action2: payload.data.selectedFontName,
    label1: '에디터_텍스트속성_폰트리스트_폰트선택',
  } satisfies GAParams;
};

export const makeGAParamsActionClickPropertyFont: GAParameterMakerFnType<
  'ACTION_CLICK_PROPERTY_FONT'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '요소속성',
    location3: '글꼴',
    action1: '클릭',
    label1: '요소속성_글꼴',
  } satisfies GAParams;
};

export const makeGAParamsActionClickPropertyTextSize: GAParameterMakerFnType<
  'ACTION_CLICK_PROPERTY_TEXT_SIZE'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '요소속성',
    location3: '글자크기',
    action1: '클릭',
    label1: '요소속성_글자크기',
  } satisfies GAParams;
};

export const makeGAParmasActionClickPropertyTextColor: GAParameterMakerFnType<
  'ACTION_CLICK_PROPERTY_TEXT_COLOR'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '요소속성',
    location3: '글자색',
    action1: '클릭',
    label1: '요소속성_글자색',
  } satisfies GAParams;
};

export const makeGAParamsActionClickPropertyTextAdjustment: GAParameterMakerFnType<
  'ACTION_CLICK_PROPERTY_TEXT_ADJUSTMENT'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '요소속성',
    location3: '글자조정',
    action1: '클릭',
    label1: '요소속성_글자조정',
  } satisfies GAParams;
};
