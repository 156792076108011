import { EventPayload } from '@event/_types/EventPayload';
import { ActionClickChangeTemplateSizeButtonType } from '../types/ActionClickChangeTemplateSizeButtonPayload';

export const getChangeTemplateSizeButtonGAText = (
  params: EventPayload<'ACTION_CLICK_CHANGE_TEMPLATE_SIZE_BUTTON'>
): ActionClickChangeTemplateSizeButtonType => {
  switch (params.data.newArrangeType) {
    case 'FILL':
      return '채우기';
    case 'FIT':
      return '맞추기';
    case 'ORIGINAL':
      return '원본크기';
    default:
      break;
  }
  if (params.data.doRollback) {
    return '이전크기로돌아갈래요';
  }

  return '페이지사이즈로적용';
};
