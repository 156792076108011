import {
  GetCouponBoxCouponsParams,
  GetWorkspaceCouponsParams,
} from '@miri-unicorn/miricanvas-api-client';

export const couponQueryKeys = {
  getCouponBoxCoupons: (param: GetCouponBoxCouponsParams) =>
    ['getCouponBoxCoupons', param] as const,
  getWorkspaceCoupons: (teamIdx: number, params?: GetWorkspaceCouponsParams) =>
    ['getWorkspaceCoupons', teamIdx, params] as const,
};
