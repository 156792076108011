import env from '../../../env';

export const BuildEnv = {
  NEXT_PUBLIC_ENV: env.NEXT_PUBLIC_ENV,
  /**
   * 로컬이냐 vs 스테이징, 프로덕션이냐
   */
  IS_LOCAL: env.NODE_ENV !== 'production' || env.NEXT_PUBLIC_LOCAL_PROD === 'Y',
  /**
   * 프로덕션이냐 vs 로컬, 스테이징이냐
   */
  IS_PRODUCTION: env.NEXT_PUBLIC_ENV === 'production',
  /**
   * 스테이징이냐
   */
  IS_STAGING: env.NEXT_PUBLIC_ENV === 'staging',
  /**
   * 개발이냐
   */
  IS_DEVELOP: env.NEXT_PUBLIC_ENV === 'develop',
  NEXT_PUBLIC_LOCAL_PROD: env.NEXT_PUBLIC_LOCAL_PROD,

  DOMAIN: env.DOMAIN,

  NEXT_PUBLIC_USE_PROXY: env.NEXT_PUBLIC_USE_PROXY,

  NEXT_PUBLIC_PAYMENT_GATEWAY_API_URL: env.NEXT_PUBLIC_PAYMENT_GATEWAY_API_URL,

  NEXT_PUBLIC_PAYMENT_GATEWAY_SCRIPT_URL: env.NEXT_PUBLIC_PAYMENT_GATEWAY_SCRIPT_URL,
  NEXT_PUBLIC_APP_VERSION: env.NEXT_PUBLIC_APP_VERSION,
} as const;
