import { getDeviceType } from '@configs/devices/device_util';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import {
  ActionCLickKeywordCategoryFinalIndicatorButtonReturnPayload,
  ActionCLickKeywordCategoryIndicatorButtonReturnPayload,
} from '../types/ActionClickKeywordCategory';

export const makeGAParamsActionClickKeywordCategoryIndicatorButton: GAParameterMakerFnType<
  'ACTION_CLICK_KEYWORD_CATEGORY_INDICATOR_BUTTON'
> = (params) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    action1: '클릭',
    account_id: `${params.data.account_id}`,
    label1: '인디케이터',
    device_type: getDeviceType(),
  } satisfies ActionCLickKeywordCategoryIndicatorButtonReturnPayload;
};

export const makeGAParamsActionClickKeywordCategoryFinalIndicatorButton: GAParameterMakerFnType<
  'ACTION_CLICK_KEYWORD_CATEGORY_FINAL_INDICATOR_BUTTON'
> = (params) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    action1: '클릭',
    account_id: `${params.data.account_id}`,
    label1: '최종인디케이터',
    device_type: getDeviceType(),
  } satisfies ActionCLickKeywordCategoryFinalIndicatorButtonReturnPayload;
};
