import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';
import { isValidDate, toFormattedString } from '@utils/functions/date_util';

type ActionByAbTestParams = GAParam<GTMTriggerEventParams>;

/**
 * AB테스트 수집
 * @link
 * @description AB테스트 시 데이터 수집
 * @params {string} location1 - AB테스트 flag 키
 * @params {string | undefined} location2 - AB테스트 시작일 (ex. 2024-01-01 09:00:00)
 * @params {number} label1 - AB테스트 variation index
 * @params {string | undefined} label2 - AB테스트 description
 * @params {number | undefined} label3 - AB테스트 ratio
 * @params {string | undefined} account_id -  유저ID
 */
export const makeGAParamsActionByAbTest: GAParameterMakerFnType<'ACTION_BY_AB_TEST'> = (
  payload
) => {
  /**
   * @see https://miridih.slack.com/archives/C070DQKBEAF/p1718932452854449
   * 날짜 방어코드 강화
   * */
  const formatDateTime = (dateStr?: string): string | undefined => {
    if (!dateStr) return '올바른_날짜_아님';

    const cleanedDateStr = dateStr.replace(/\s+/g, '');
    const date = new Date(cleanedDateStr);

    if (!isValidDate(date)) return '올바른_날짜_아님';

    return toFormattedString(date, '-');
  };

  return {
    event: 'abtest_user_miricanvas',
    log_type: 'abtest_user_miricanvas',
    location1: payload.data.flagKey,
    location2: formatDateTime(payload.data.variation?.startDate),
    action1: '유저집단',
    label1: payload.data.variation?.index?.toString(),
    label2: payload.data.variation?.description,
    label3: payload.data.variation?.ratio?.toString(),
    account_id: payload.data.accountId,
  } satisfies ActionByAbTestParams;
};

export type ActionByAbTestPayload = {
  flagKey: string;
  variation: {
    variationKey?: string;
    index?: number;
    startDate?: string;
    description?: string;
    ratio?: number;
  };
  accountId: string | undefined;
};
