import {
  GetExternalTeamFolderListParams,
  GetHanmiTeamFolderListParams,
} from '@miri-unicorn/miricanvas-api-client';

export const externalFolderQueryKeys = {
  getExternalTeamFolderList: (apiClientGroup: string, params?: GetExternalTeamFolderListParams) =>
    ['getExternalTeamFolderList', apiClientGroup, params] as const,
  getHanmiTeamFolderList: (params?: GetHanmiTeamFolderListParams) =>
    ['getHanmiTeamFolderList', params] as const,
};
