import {
  GetDesignResourceListParams,
  GetRecentDesignResourceListParams,
} from '@miri-unicorn/miricanvas-api-client';

export const audioElementQueryKeys = {
  getAudioElementListInfinite: (params: GetDesignResourceListParams) => [
    'getAudioElementListInfinite',
    params,
  ],
  getRecentAudioListInfinite: (params: GetRecentDesignResourceListParams) => [
    'getRecentAudioListInfinite',
    params,
  ],
  getRecentAudioListInfiniteDefault: () => ['getRecentAudioListInfinite'],
};
