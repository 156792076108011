import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { makeAudioTempoToKoreanMap } from '../utils/makeAudioTempoToKorean';

export const makeGAParamsActionClickAudio: GAParameterMakerFnType<'ACTION_CLICK_AUDIO_ITEM'> = (
  payload
) => {
  return {
    log_type: 'action_audio',
    event: 'action_audio',
    action_item: payload.data.key.toString(),
    action_item_type: payload.data.resourceType,
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
    search_status: payload.data.search_status,
    search_term: payload.data.keyword,
    search_type: payload.data.searchType,
    search_audio_type: payload.data.typeList.toString(),
    search_audio_tempo: makeAudioTempoToKoreanMap(payload.data.tempo),
    search_audio_category: payload.data.typeList[0] === 'SFX' ? '효과음' : '배경음',
    priority: payload.data.verticalViewSequence.toString(),
    search_keyword_category: '',
  };
};

export const makeGAParamsActionSearchAudio: GAParameterMakerFnType<'ACTION_SEARCH_AUDIO_ITEM'> = (
  payload
) => {
  return {
    log_type: 'search_audio',
    event: 'search_audio',
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
    search_status: payload.data.search_status,
    search_term: payload.data.keyword,
    search_type: payload.data.searchType,
    search_audio_type: payload.data.typeList.toString(),
    search_audio_tempo: makeAudioTempoToKoreanMap(payload.data.tempo),
    search_audio_category: payload.data.typeList[0] === 'SFX' ? '효과음' : '배경음',
  };
};

export const makeGAParamsActionViewAudioItem: GAParameterMakerFnType<'ACTION_VIEW_AUDIO_ITEM'> = (
  payload
) => {
  return {
    log_type: 'view_audio',
    event: 'view_audio',
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
    search_status: payload.data.search_status,
    search_term: payload.data.keyword,
    search_type: payload.data.searchType,
    search_audio_type: payload.data.typeList.toString(),
    search_audio_tempo: makeAudioTempoToKoreanMap(payload.data.tempo),
    search_audio_category: payload.data.typeList[0] === 'SFX' ? '효과음' : '배경음',
    view_item_key: payload.data.view_item_key.join(', '),
    view_item_type: payload.data.view_item_type.join(','),
    view_load_num: payload.data.view_load_num,
  };
};
