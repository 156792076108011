import { getDeviceType } from '@configs/devices/device_util';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { getDesignResourcePanelMenuTypeText } from '@constants/DesignResourcePanelMenu';
import {
  ActionClickLeftPanelExpandButtonReturnPayload,
  ActionClickLeftPanelMenuButtonReturnPayload,
  ActionClickLeftPanelUploadMenuButtonReturnPayload,
} from '../types/ActionClickLeftPanelMenuButtonPayload';

export const makeGAParamsActionClickLeftPanelMenuButton: GAParameterMakerFnType<
  'ACTION_CLICK_LEFT_PANEL_MENU_BUTTON'
> = (payload) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '편집메뉴',
    action1: '클릭',
    label1: getDesignResourcePanelMenuTypeText(payload.data.menu_item),
    account_id: payload.data.account_id?.toString(),
    device_type: getDeviceType(),
  } satisfies ActionClickLeftPanelMenuButtonReturnPayload;
};

export const makeGAParamsActionClickLeftPanelExpandButton: GAParameterMakerFnType<
  'ACTION_CLICK_LEFT_PANEL_EXPAND_BUTTON'
> = (payload) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '편집메뉴',
    action1: '클릭',
    label1: payload.data.is_expand ? '메뉴펼치기' : '메뉴숨기기',
    account_id: payload.data.account_id?.toString(),
    device_type: getDeviceType(),
  } satisfies ActionClickLeftPanelExpandButtonReturnPayload;
};

export const makeGAParamsActionClickLeftPanelUploadMenuButton: GAParameterMakerFnType<
  'ACTION_CLICK_LEFT_PANEL_UPLOAD_MENU_BUTTON'
> = (payload) => {
  const location1 = payload.data?.isMobile ? '모바일-에디터' : '에디터';
  const label1 = payload.data?.isMobile
    ? '모바일_에디터_업로드패널_업로드CTA'
    : '에디터_업로드패널_업로드CTA';

  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: location1,
    location2: '업로드패널',
    location3: '업로드CTA',
    action1: '클릭',
    label1: label1,
  } satisfies ActionClickLeftPanelUploadMenuButtonReturnPayload;
};
