import { Plan } from '@miri-unicorn/miricanvas-api-client';

export const isFreePlan = (type: Plan = 'FREE'): boolean => {
  return type === 'FREE';
};

export const isProPlan = (type: Plan = 'FREE'): boolean => {
  return type === 'PRO';
};

export const isPro100GBPlan = (type: Plan = 'FREE'): boolean => {
  return type === 'PRO_100GB';
};

export const showCrown = (type: Plan = 'FREE'): boolean => {
  return isProPlan(type) || isPro100GBPlan(type);
};

export const isEnterprisePlan = (type: Plan = 'FREE'): boolean => {
  return type === 'ENTERPRISE';
};

export const isEnterpriseLitePlan = (type: Plan = 'FREE'): boolean => {
  return type === 'ENTERPRISE_LITE';
};

export const isEduPlan = (type: Plan = 'FREE'): boolean => {
  return type === 'EDU';
};

export const isEduOrEduPlus = (type: Plan = 'FREE'): boolean => {
  return ['EDU', 'EDU_PLUS'].includes(type);
};

export const isFreeOrEduOrEduPlus = (type: Plan = 'FREE'): boolean => {
  return ['FREE', 'EDU', 'EDU_PLUS'].includes(type);
};

export const isEduPlusOrOffice = (type: Plan): boolean => {
  return ['EDU_PLUS_OFFICE', 'EDU_PLUS'].includes(type);
};

export const isPaymentRequiredPlan = (type: Plan = 'FREE'): boolean => {
  const planToBePurchased: Plan[] = ['PRO', 'PRO_100GB', 'ENTERPRISE', 'ENTERPRISE_LITE'];
  return planToBePurchased.includes(type);
};

export const isEnterpriseLiteOrEduPlan = (type: Plan = 'FREE'): boolean => {
  return ['ENTERPRISE_LITE', 'EDU'].includes(type);
};

/**
 * @param currentPlan 현재 사용자의 플랜
 * @param nextPlan 다음 구독한 사용자의 플랜
 */
export const isUnsubscribingNextPlan = (
  currentPlan: Plan = 'FREE',
  nextPlan: Plan = 'FREE'
): boolean => {
  return currentPlan !== 'FREE' && nextPlan === 'FREE';
};

export const isDownGradeToProORPro100GB = (
  currentPlan: Plan = 'FREE',
  nextPlan: Plan = 'FREE'
): boolean => {
  return currentPlan === 'ENTERPRISE' && (nextPlan === 'PRO' || nextPlan === 'PRO_100GB');
};

export const canUserChangePlan = (plan: Plan = 'FREE'): boolean => {
  return plan !== 'ENTERPRISE' && plan !== 'ENTERPRISE_LITE' && plan !== 'EDU';
};

export const isProORPro100GB = (currentPlan: Plan = 'FREE'): boolean => {
  return currentPlan === 'PRO' || currentPlan === 'PRO_100GB';
};

export const isPremiumPlanUser = (currentPlan: Plan = 'FREE'): boolean => {
  return ['PRO', 'PRO_100GB', 'ENTERPRISE', 'EDU_PLUS', 'EDU_PLUS_OFFICE'].includes(currentPlan);
};

/**
 *  Plan type 에 해당하는 UI 용 문자열 key 값 반환.
 */
export const planToString = (plan: Plan) => {
  const planToStringMap: Record<Plan, string> = {
    ENTERPRISE: 'SHARED.PLAN.ENTERPRISE',
    ENTERPRISE_LITE: 'SHARED.PLAN.ENTERPRISE_LITE',
    PRO: 'SHARED.PLAN.PRO',
    PRO_100GB: 'SHARED.PLAN.PRO_100GB',
    EDU: 'SHARED.PLAN.EDU',
    FREE: 'SHARED.PLAN.FREE',
    EDU_PLUS: '',
    EDU_PLUS_OFFICE: '',
  };

  return planToStringMap[plan];
};
