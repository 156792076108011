import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickFavoritePageEntryPoint: GAParameterMakerFnType<
  'ACTION_CLICK_FAVORITE_PAGE_ENTRY_POINT'
> = (payload) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: payload.data.location,
    action1: '클릭',
    label1: '찜페이지_진입',
  };
};
