import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickMoreMenuButtonParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickMoreMenuButton: GAParameterMakerFnType<
  'ACTION_CLICK_MORE_MENU_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '상단바',
    location3: '인쇄물제작더보기',
    action1: '클릭',
    label1: '상단바_인쇄물제작더보기',
  } satisfies ActionClickMoreMenuButtonParams;
};
