import i18next from 'i18next';
import { Language } from '@miri-unicorn/miricanvas-api-client';
import { LANGUAGE_TYPE_LIST, LanguageEnum, LanguageType } from '@constants/LanguageType';

/** 디폴트 언어 -=> 영어*/
export const DEFAULT_LANGUAGE = 'en' satisfies LanguageType;

export const getLanguageList = (): typeof LANGUAGE_TYPE_LIST => {
  return LANGUAGE_TYPE_LIST;
};

/**
 * 파라미터로 입력받은 언어타입이 현재 서비스 중인 언어가 맞는지 확인하여 boolean 값 리턴하는 함수
 * @param {string} languageType
 * @returns 현재 서비스 중인 언어이면 true 리턴
 */
export const isServiceableLanguageType = (
  languageType: string | undefined
): languageType is LanguageType => {
  return LANGUAGE_TYPE_LIST.includes(languageType as LanguageType);
};

/**
 * 언어 변경 selector 에서 display 되어야 할 각 LanguageType 별 문자열을 리턴하는 함수
 * @param languageType
 * @returns 언어 selector UI 상 display 되어야 할 문자열
 */
export const getLanguageSelectorDisplayText = (languageType: LanguageType): string => {
  const displayText: Record<LanguageType, string> = {
    ko: '한국어',
    en: 'English',
    ja: '日本語',
    de: 'Deutsch',
    es: 'Español',
    fr: 'Français',
    it: 'Italiano',
    nl: 'Nederlands',
    pt: 'Português',
  };
  return displayText[languageType];
};

/**
 *  각 언어별 한글 표현 저장하는 Map
 */
export const languageKorNameToKorMap: Map<Language, string> = new Map([
  ['ko', '한국어'],
  ['en', '영어'],
  ['ja', '일본어'],
  ['de', '독일어'],
  ['es', '스페인어'],
  ['fr', '프랑스어'],
  ['it', '이탈리아어'],
  ['nl', '네덜란드어'],
  ['pt', '포르투갈어'],
]);

export const isKoreanLanguageSet = () => {
  return i18next.language === LanguageEnum.KO;
};
