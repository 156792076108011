import { useCallback } from 'react';
import { Login200, login } from '@miri-unicorn/miricanvas-api-client';
import { useQueryClient } from '@configs/react-query';
import { queryKeys } from '@configs/react-query/queryKeys';
import { useQuery } from '@configs/react-query/useQuery';
import { DEFAULT_USER } from '../_constants/user';

// TODO: @이태식 파일 분리해주세요 UNICORN-32798
export const useUserQuery = () => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: queryKeys.getUser(),
    queryFn: () => login({ loginAutomatically: true }),
    options: {
      /**
       * 로그인 api 에러 발생 시, api 무한호출 되는 현상이 있어서, default user return
       * 추후 기획 논의하여 이 경우에 대해 유저에게 안내를 해주도록 하는게 좋은 방향
       */
      onError: () => queryClient.setQueryData(queryKeys.getUserIp(), { data: DEFAULT_USER }),
    },
  });
};

export const useFetchUserQuery = () => {
  const queryClient = useQueryClient();
  const fetchUserQuery = async () => {
    return await queryClient.fetchQuery({
      queryKey: queryKeys.getUser(),
      queryFn: () => login({ loginAutomatically: true }),
    });
  };

  return { fetchUserQuery };
};

export const useGetUserSnapshot = () => {
  const queryClient = useQueryClient();

  return () => queryClient.getQueryData<Login200>(queryKeys.getUser())?.data;
};

export const useInvalidateUserQuery = () => {
  const queryClient = useQueryClient();

  const invalidateUserQuery = useCallback(() => {
    return queryClient.invalidateQueries({ queryKey: queryKeys.getUser() });
  }, [queryClient]);

  return { invalidateUserQuery };
};
