import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickTextStyleRecommend = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionTextStyleRecommend: GAParameterMakerFnType<
  'ACTION_CLICK_TEXT_STYLE_RECOMMEND'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '텍스트옵션',
    location3: '추천스타일적용하기',
    action1: '클릭',
    label1: '텍스트속성창_추천스타일적용하기',
  } satisfies ActionClickTextStyleRecommend;
};
