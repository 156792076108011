import { UpgradePopupFlowStep } from '@configs/google_analytics/features/upgrade_popup/types/UpgradePopupPayload';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsOpenGlobalTrialUpgradePopup: GAParameterMakerFnType<
  'ACTION_OPEN_GLOBAL_TRIAL_UPGRADE_POPUP'
> = (payload) => ({
  event: 'purchase_flow_develop',
  log_type: 'purchase_flow_develop',
  flow_name1: payload.data.flow_name1,
  flow_type: UpgradePopupFlowStep[payload.data.flow_type],
  action_type: payload.data.action_type,
  team_idx: payload.data.team_idx.toString(),
  billing_cycle: payload.data.billing_cycle,
  plan_type: payload.data.plan_type,
  currency: payload.data.currency,
  payment_orders_idx: payload.data.payment_orders_idx?.toString(),
  account_id: payload.data.account_id.toString(),
});
