import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickTemplateMyList: GAParameterMakerFnType<
  'ACTION_CLICK_TEMPLATE_MY_LIST'
> = (payload) => {
  return {
    log_type: 'action_template_mylist',
    event: 'action_template_mylist',
    search_status: payload.data.search_status,
    action_item: payload.data.action_item.toString(),
    search_type: payload.data.search_type,
    license: payload.data.license,
    priority2: payload.data.priority2.toString(),
    account_id: payload.data.account_id?.toString(),
    plan_type: payload.data.plan_type,
    team_idx: payload.data.team_idx?.toString(),
  };
};
