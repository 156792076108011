const subscribers = new Set<VoidFunction>();

const handleResize = () => {
  subscribers.forEach((callback) => callback());
};

export const subscribeViewportSize = (callback: VoidFunction) => {
  if (subscribers.size === 0) {
    window.addEventListener('resize', handleResize);
  }
  subscribers.add(callback);

  return () => {
    subscribers.delete(callback);
    if (subscribers.size === 0) {
      window.removeEventListener('resize', handleResize);
    }
  };
};
