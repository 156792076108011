import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickAfterDownloadSurveyCloseButtonParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickAfterDownloadSurveyCloseButton: GAParameterMakerFnType<
  'ACTION_CLICK_AFTER_DOWNLOAD_SURVEY_CLOSE_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '다운로드 직후 설문',
    location3: '닫기',
    action1: '클릭',
    label1: '에디터_다운로드직후설문_닫기',
  } satisfies ActionClickAfterDownloadSurveyCloseButtonParams;
};
