import { createGlobalStyle } from 'styled-components';
import { StyledReset } from '@miri-unicorn/miricanvas-ds';
import { DeviceType } from '@configs/devices/device_type';
import { CommonZIndex } from '@styles/CommonZIndex';
import { DevToolsStyle } from '@styles/DevToolsStyle';
import { SAFE_AREA_INSET_BOTTOM, SAFE_AREA_INSET_TOP } from '@ui/common/_constants/safe_area_inset';

/* 여기에 글로벌 스타일 적용 */
export const GlobalStyle = createGlobalStyle<{
  deviceType?: DeviceType;
  isZendeskWidgetOpen: boolean;
}>`
  #__next, #root {
    height: 100%;
    height: 100dvh;
    isolation: isolate;
    z-index: ${CommonZIndex.ROOT};
    background-color: ${({ theme }) => theme.token.s_color_background_default};
  }

  #portal_container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${CommonZIndex.MODAL_CONTAINER};
  }

  #__mds_tooltip_continer_ {
    position: absolute;
    z-index: ${CommonZIndex.TOOLTIP_CONTAINER};
  }

  #__onboarding_tooltip_container_  {
    position: absolute;
    z-index: ${CommonZIndex.ONBOARDING_TOOLTIP_CONTAINER};
  }

  @layer miricanvas-ds-base {
    ${StyledReset};
  }
  ${DevToolsStyle};

  .reactgrid-content  {
    .rg-pane {
      .rg-cell {
        color: ${({ theme }) => theme.token.s_color_contents_default} !important;
        border-color: ${({ theme }) => theme.token.s_color_border_default} !important;
        justify-content: center;
        font: ${({ theme }) => theme.token.s_typography_body_M};

        &.rg-header-cell {
          background-color: ${({ theme }) => theme.token.s_color_surface_lightness} !important;
          font: ${({ theme }) => theme.token.s_typography_body_S};
        }

        &[class~="rg-text-cell"][class~="valid"][class~="invalid-cell"] {
          border-color: ${({ theme }) => theme.token.s_color_semantic_negative_default} !important;
          border-left: 1px solid  ${({ theme }) =>
            theme.token.s_color_semantic_negative_default} !important;
          border-top: 1px solid  ${({ theme }) =>
            theme.token.s_color_semantic_negative_default} !important;
        }
      }

      .rg-cell-focus, .rg-cell-highlight {
        border-color: ${({ theme }) => theme.token.s_color_brand_primary_light} !important;
      }
      .rg-touch-fill-handle{
        width: 8px;
        height: 8px;
        padding: 1px;

        .rg-fill-handle {
          width: 100%;
          height: 100%;
          background-color: ${({ theme }) => theme.token.s_color_brand_primary_light} !important;
          border-radius: 4px;
        }
      }

      .rg-partial-area {
        &.rg-partial-area-selected-range {
          border-color: ${({ theme }) => theme.token.s_color_brand_primary_light} !important;
          background-color: ${({ theme }) => theme.token.s_color_brand_primary_surface} !important;
        }
      }
    }

    .rg-celleditor {
      border-color: ${({ theme }) => theme.token.s_color_brand_primary_light};
      padding: 2px 4px;
    }

    .rg-celleditor input {
      border: none;
      font: ${({ theme }) => theme.token.s_typography_body_M};
      text-align: center;
    }
  }

  /*
* 에디터 페이지에 있는 젠데스크위 젯을 위한 스타일처리.
* 에디터 페이지에서는 현재 1.0 코드에 존재하는 헬프센터 도움말 팝업을 (젠데스크 위젯을 포함하고 있음) 캔버스 command 로 호출해서 열고있다. 
* 추후 에디터 페이지에서 젠데스크 위젯열기를 2.0 useZendeskWidget.ts 으로 변경하게 된다면 없애면 된다.
* 지금 못바꾸는 이유 - 헬프센터 도움말 팝업에 '우편함'기능이 2.0에는 미개발 상황이라 아직 1.0 것을 써야한다. 
*/
  ${({ deviceType }) => {
    return (
      deviceType === 'mobile' &&
      `
        iframe#webWidget {
         padding-top: ${SAFE_AREA_INSET_TOP};
         padding-bottom: ${SAFE_AREA_INSET_BOTTOM};
         background-color: #000;
        }`
    );
  }}

  /*
  * 새롭게 만든 2.0 젠데스크 위젯에 safeArea css 가 생성되지 않는 현상을 위한 스타일 처리 
  * @see useZendeskWidget.ts
  */
  ${({ isZendeskWidgetOpen }) =>
    isZendeskWidgetOpen &&
    `
      iframe#webWidget {
        padding-top: ${SAFE_AREA_INSET_TOP};
        padding-bottom: ${SAFE_AREA_INSET_BOTTOM};
        background-color: #000;
      }
    `}

    /**
    커스텀 헬프센터 도움말 팝업 버튼을 쓸거기 때문에, 젠데스크 위젯이 로드하는 기본 버튼 UI 는 감추기
    @see HelpCenterPopoverWithAnchor.tsx
     */
  iframe#launcher {
    display: none!important;
  }

  :root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
  }
`;
