import { Trans as _Trans } from 'react-i18next';
import { ComponentProps, useMemo } from 'react';
import { NamespaceEnum } from '@configs/i18next/_types/NameSpace';

/**
 *
 * /**
 * 각각의 namespace에 맞는 키값만 번역이 적용되기 때문에 일부 하드 코딩 과정을 거쳤음
 * 추후에 namespace를 적절하게 적용 할 수 있는 방식을 국제화 라우팅 작업을 진행할때 translate 함수를 구체화 할 필요가 있음
 */

/**
 * ### Trans Component
 * namespace 를 매번 넣을 필요 없게 만든 Trans 컴포넌트
 * @important 꼭 i18nKey 로 key 값을 넘겨야 함
 * @example ```tsx
 *  <Trans i18nKey={'SHARED.TRANSLATE_KEY'}/>
 * ```
 */
export const Trans = (props: Omit<ComponentProps<typeof _Trans>, 'ns'>) => {
  const ns = useMemo(() => {
    const key = props.i18nKey as string;
    /** @todo 임시 로직 */
    if (key.startsWith(NamespaceEnum.SHARED.toUpperCase())) {
      return NamespaceEnum.SHARED;
    }
    if (key.startsWith(NamespaceEnum.EDITOR.toUpperCase())) {
      return NamespaceEnum.EDITOR;
    }
    if (key.startsWith(NamespaceEnum.HOMEPAGE.toUpperCase())) {
      return NamespaceEnum.HOMEPAGE;
    }
    return NamespaceEnum.SHARED;
  }, [props.i18nKey]);

  return <_Trans {...props} ns={ns} />;
};
