import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';
import { getDownloadPPTOptionGAText } from '../utils/download_ga_util';

type ActionClickVideoDownloadModal = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickBrowseVideoTemplateFromDownloadProgress: GAParameterMakerFnType<
  'ACTION_CLICK_BROWSE_VIDEO_TEMPLATE_DOWNLOAD_PROGRESS'
> = () => {
  return {
    log_type: 'generic_event_mican',
    event: 'gtm_trigger_event',
    location1: '에디터',
    location2: '다운로드 진행',
    location3: '동영상 템플릿 보러가기',
    action1: '클릭',
    label1: '다운로드_진행_동영상템플릿보러가기',
  } satisfies ActionClickVideoDownloadModal;
};

export const makeGAParamsActionClickBrowseVideoTemplateFromDownloadComplete: GAParameterMakerFnType<
  'ACTION_CLICK_BROWSE_VIDEO_TEMPLATE_DOWNLOAD_COMPLETE'
> = () => {
  return {
    log_type: 'generic_event_mican',
    event: 'gtm_trigger_event',
    location1: '에디터',
    location2: '다운로드 완료',
    location3: '동영상 템플릿 보러가기',
    action1: '클릭',
    label1: '다운로드_완료_동영상템플릿보러가기',
  } satisfies ActionClickVideoDownloadModal;
};

export const makeGAParamsActionClickMakeVideoWithMyDesign: GAParameterMakerFnType<
  'ACTION_CLICK_MAKE_VIDEO_WITH_MY_DESIGN'
> = () => {
  return {
    log_type: 'generic_event_mican',
    event: 'gtm_trigger_event',
    location1: '에디터',
    location2: '다운로드 완료',
    location3: '동영상 만들기',
    action1: '클릭',
    label1: '다운로드_완료_동영상만들기',
  } satisfies ActionClickVideoDownloadModal;
};

export const makeGAParamsActionDownload: GAParameterMakerFnType<'ACTION_DOWNLOAD_EVENT'> = (
  params
) => {
  const pptOptionKoreanText = getDownloadPPTOptionGAText(params.data.ppt_options);

  return {
    log_type: 'action_download',
    event: 'action_download',
    design_idx: params.data.design_idx,
    template_key: params.data.template_key,
    file_extension_type: params.data.file_extension_type,
    ppt_options: pptOptionKoreanText,
    transparent_bg: params.data.transparent_bg ? 'True' : 'False',
    image_merge: params.data.image_merge ? 'True' : 'False',
  };
};
