import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickMoreMenuDraftButtonParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickMoreMenuDraftButton: GAParameterMakerFnType<
  'ACTION_CLICK_MORE_MENU_DRAFT_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '상단바더보기',
    location3: '승인요청',
    action1: '클릭',
    label1: '상단바_승인요청',
  } satisfies ActionClickMoreMenuDraftButtonParams;
};
