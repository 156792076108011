import { useCallback, useState } from 'react';
import { useEventListener, useIsomorphicLayoutEffect } from '@utils/hooks';

type WindowSize = {
  width: number;
  height: number;
};

/**
 * ### useWindowSize 커스텀 훅
 * - 윈도우 현재의 사이즈를 반환한다.
 * @deprecated use useViewportWidth instead
 * @see useViewportWidth
 */
export const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  });

  const handleSize = useCallback(() => {
    setWindowSize({
      width: window?.innerWidth,
      height: window?.innerHeight,
    });
  }, []);

  useEventListener('resize', handleSize);

  // Set size at the first client-side load
  useIsomorphicLayoutEffect(() => {
    handleSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowSize;
};
