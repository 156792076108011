import { Login200Data } from '@miri-unicorn/miricanvas-api-client';
import { EditorConfig } from '@business/editor/editor_configs/_types/editorConfig';

/**
 * 에디터에서 선택된 팀을 결정하는 조건 우선순위
 *   - (SSR 문제로 제외) session storage (브라우저 ‘탭’ 종속 데이터)
 *     - 2.0 패키지 모드에서는 판단하지 않도록 함
 *     - 1.0에서도 제거 예정
 *   - DB에 저장된 editor config의 selected_team_idx 값
 *   - 내가 속한 팀 중 첫번째 무료 플랜팀
 *   - 내가 속한 팀 중 첫번째 팀
 */
export const getSelectedTeamIdxFromUser = (
  user?: Pick<Login200Data, 'editorConfig' | 'teamList'>
) => {
  if (!user) {
    return undefined;
  }

  const selectedTeamIdxFromEditorConfig = Number(
    (JSON.parse(user.editorConfig || '{}') as EditorConfig).selected_team_idx
  );
  const selectedTeamIdxExists = user.teamList?.some(
    (team) => team.idx === selectedTeamIdxFromEditorConfig
  );

  if (selectedTeamIdxExists) {
    return selectedTeamIdxFromEditorConfig;
  }

  return (
    user.teamList?.find((team) => team.plan === 'FREE')?.idx || user.teamList?.[0]?.idx || undefined
  );
};
