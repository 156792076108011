import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickFontFilter = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionBrandKit: GAParameterMakerFnType<'ACTION_CLICK_BRAND_KIT'> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '색상팝업',
    location3: '브랜드키트탭',
    action1: '클릭',
    label1: '에디터_색상팝업_브랜드키트탭',
  } satisfies ActionClickFontFilter;
};
