import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickShareButtonParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickDesignPublicToggleButton: GAParameterMakerFnType<
  'ACTION_CLICK_DESIGN_PUBLIC_TOGGLE_BUTTON'
> = ({ data: { designPublicValue, accountId, deviceType } }) => {
  return {
    event: 'ma_design_share',
    log_type: 'ma_design_share',
    location1: '웹게시',
    location2: 'generic_event_mican',
    action1: '클릭',
    label1: designPublicValue,
    account_id: accountId,
    device_type: deviceType,
  } satisfies ActionClickShareButtonParams;
};
