'use client';

import { ReactNode, useState } from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MILLI_SECONDS_OF_ONE_MINUTE } from '@constants/milli_seconds';

export type QueryProviderProps = {
  dehydratedState: unknown;
  children: ReactNode;
};

/**
 * ssr 에서 사용되는 QueryProvider
 * @todo set default option
 */
export const QueryProvider = ({ children, dehydratedState }: QueryProviderProps) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: MILLI_SECONDS_OF_ONE_MINUTE * 5, // 캐시 데이터가 5분 동안은 신선하다고 판단합니다.
            cacheTime: MILLI_SECONDS_OF_ONE_MINUTE * 5, // @default (gcTime) 쿼리 데이터가 5분후에 가비지 대상이 됩니다.
            retry: 0, // 쿼리가 실패한 경우 재시도하지 않습니다.
            refetchOnWindowFocus: false, // 윈도우 포커스가 변경되었을 때 쿼리를 재실행하지 않습니다.
            refetchOnReconnect: true, // @default 웹 브라우저가 네트워크 연결을 재개했을 때 모든 쿼리를 자동으로 다시 가져옵니다.
            networkMode: 'always', // https://miridih.slack.com/archives/CQEPRHWHW/p1698985879527959 쿼리가 항상 서버로부터 데이터를 가져옵니다.
          },
        },
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={dehydratedState}>{children}</Hydrate>
    </QueryClientProvider>
  );
};
