import React from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from '@configs/i18next';

/**
 * bindhook 에서 기본적으로 호출하는 hook 을 반환한다.  (translate 같이 거의 모든 컴포넌트마다 호출 해줘야 하는 hook 을 대상)
 * - 호출 hook 리스트
 *   - useTranslate
 */
const useDefaultHooksResults = () => {
  // hook 내부니까 예외
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { translate } = useTranslation();
  const theme = useTheme();

  return {
    translate,
    theme,
  };
};

/**
 * 커스텀훅과 뷰를 연결해주는 함수
 *
 * @example
 * 커스텀훅에 로직 작성
 * ```tsx
 * const useCustomHook = ({userInfo}) => {
 *  //대충 어떤 로직....
 *  return {
 *      name,
 *      age,
 *      email
 *  }
 * }
 *
 * bindHook 을 이용해서 뷰와 커스텀 훅을 연결시킴
 * const UserView = bindHook(useCustomHook, ({name, age, email}) => {
 *  return (
 *    <div>
 *        <p>{name}</p>
 *        <p>{age}</p>
 *        <p>{email}</p>
 *   </div>
 *  )
 * })
 *
 * 실제 사용 예시
 * <UserView userInfo={userInfo} />
 * ```
 */
export const bindHook = <T, K>(
  hook: (HookProps: T) => K,
  view: (viewProps: K & ReturnType<typeof useDefaultHooksResults>) => JSX.Element,
  useMemo = false
) => {
  // React.memo 를 효과적으로 사용하기 위해서는 커스텀 훅에서 컴포넌트에 전달해주는 값들이 useCallback 이나, useMemo 로 감싸져 있어야함.
  if (useMemo) {
    return React.memo((props: T) => {
      return view({ ...hook(props), ...useDefaultHooksResults() });
    });
  }
  return (props: T) => {
    return view({ ...hook(props), ...useDefaultHooksResults() });
  };
};
