import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import {
  GTMTriggerEventParams,
  GTMUserParams,
} from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickQRCodeSymbolMarkIconParams = GAParam<GTMTriggerEventParams & GTMUserParams>;

export const makeGAParamsActionClickQRCodeSymbolMarkIcon: GAParameterMakerFnType<
  'ACTION_CLICK_QR_CODE_SYMBOL_MARK_ICON'
> = (payload) => {
  return {
    log_type: 'generic_event_mican',
    event: 'gtm_trigger_event',
    location1: '에디터',
    location2: 'QR/바코드',
    location3: '심벌마크',
    action1: '클릭',
    label1: '아이콘',
    label2: payload.data.icon_key,
    account_id: payload.data.account_id,
  } satisfies ActionClickQRCodeSymbolMarkIconParams;
};
