import { useMemo } from 'react';
import { DEFAULT_USER } from '@business/user/_constants/user';
import { useUserQuery } from '@business/user/_queries/useUserQuery';
import { getSelectedTeamIdxFromUser } from '@business/user/_services/_pures/getSelectedTeamIdxFromUser';

export const useSelectedTeamIdx = () => {
  const { data, isError } = useUserQuery();

  const user = useMemo(() => {
    // 로그인 api 에러 발생 시, api 무한호출 되는 현상이 있어서, default user return
    if (isError) {
      return DEFAULT_USER;
    }

    return data?.data;
  }, [data?.data, isError]);

  const selectedTeamIdx = useMemo(() => getSelectedTeamIdxFromUser(user), [user]);

  return Boolean(selectedTeamIdx) ? selectedTeamIdx : undefined;
};
