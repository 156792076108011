import { ReactElement, useMemo } from 'react';
import { useCurrentUser } from '@business/user/_services/_hooks/useCurrentUser';
import { AbBucketing } from '@configs/ab-test/AbBucketing';
import { CollectAbGtag } from '@configs/ab-test/CollectAbGtag';
import { LanguageType } from '@constants/LanguageType';

const isUserInABTest = (accountId?: number) => {
  if (!accountId) return false;
  const remainder = accountId % 10;
  return remainder === 0;
};

/**
   @see https://miridih.slack.com/archives/CSLLD28QL/p1718170072262989?thread_ts=1716437090.350679&cid=CSLLD28QL
   @see https://miridih.slack.com/archives/C06VDSGAEDU/p1723009931389949
   트래픽 과부하로 콜드스타트 지연 발생 여지 있음
   1차로 해외유저에게 먼저 오픈 (6월)
   2차로 국내유저 10% 먼저 오픈 (8월)
*/
const withAbBucketingDecision = (Component: () => ReactElement) => {
  return ({ lang }: { lang: LanguageType }) => {
    const { isLogin, isLoadingUser, user } = useCurrentUser();
    const isUserInTest = useMemo(() => isUserInABTest(user?.accountId), [user?.accountId]);

    // 글로벌은 전체오픈
    if (lang !== 'ko') {
      return <Component />;
    }

    // ko, ja 언어 사용자 && 회원에 한해 10% 대상으로 먼저 오픈
    if (isLoadingUser) {
      return <></>;
    }

    return isLogin && isUserInTest ? <Component /> : <></>;
  };
};

export const AbBucketingWithDecision = withAbBucketingDecision(() => (
  <AbBucketing>{({ data }) => <CollectAbGtag abData={data} />}</AbBucketing>
));
