import { StyledObject, css } from 'styled-components';

/**
 * 메인 페이지에서 사용하는 미디어 쿼리
 * - desktop : 1280 이상 ~
 * - tablet :768(TABLET_BREAK_MIN) 이상 ~ 1280(TABLET_BREAK_MAX) 미만
 * - mobile : 500(MOBILE_BREAK_MIN) 이상 ~ 767(MOBILE_BREAK_MAX) 미만
 * - x small mobile : 500(X_SMALL_MOBILE_BREAK_MAX) 미만
 */
export const X_SMALL_MOBILE_BREAK_MAX = 499;

export const MOBILE_BREAK_MIN = X_SMALL_MOBILE_BREAK_MAX + 1;
export const MOBILE_BREAK_MAX = 767; // 모바일 최대

export const TABLET_BREAK_MIN = MOBILE_BREAK_MAX + 1; // 태블릿 최소
export const TABLET_BREAK_MAX = 1279;

export const DESKTOP_BREAK_MIN = TABLET_BREAK_MAX + 1;

/**
 * 메인 페이지에서 desktop 인 경우 미디어 쿼리
 * - 1280 이상 ~
 */
const over1280Desktop = (styles: StyledObject) => {
  return css`
    @media (min-width: ${DESKTOP_BREAK_MIN}px) {
      ${styles};
    }
  `;
};

/**
 * 메인 페이지에서 desktop 인 경우 미디어 쿼리
 * - ~ 1280 미만
 */
const under1280Desktop = (styles: StyledObject) => {
  return css`
    @media (max-width: ${TABLET_BREAK_MAX}px) {
      ${styles};
    }
  `;
};

/**
 * 메인 페이지에서 tablet 인 경우 미디어 쿼리
 * - 768 이상 ~ 1280 미만
 */
const under1280Tablet = (styles: StyledObject) => {
  return css`
    @media (min-width: ${TABLET_BREAK_MIN}px) and (max-width: ${DESKTOP_BREAK_MIN - 1}px) {
      ${styles}
    }
  `;
};

/**
 * tablet 이상 너비인 경우 미디어 쿼리
 * - 768 이상 ~
 */
const tabletOver = (styles: StyledObject) => {
  return css`
    @media screen and (min-width: ${TABLET_BREAK_MIN}px) {
      ${styles}
    }
  `;
};

/**
 * - ~ 768 미만
 */
const mobile = (styles: StyledObject) => {
  return css`
    @media screen and (max-width: ${MOBILE_BREAK_MAX}px) {
      ${styles}
    }
  `;
};

/**
 * 메인 페이지에서 "모바일" 화면 중 500 이상 ~ 768 미만 화면일 경우 미디어 쿼리
 * - 500 이상 ~ 768 미만
 */
const over500Mobile = (styles: StyledObject) => {
  return css`
    @media screen and (min-width: ${MOBILE_BREAK_MIN}px) and (max-width: ${MOBILE_BREAK_MAX}px) {
      ${styles}
    }
  `;
};

/**
 * 메인 페이지에서 "모바일" 화면 중 500 미만 화면일 경우 미디어 쿼리
 * -  ~ 500 미만
 */
const under500Mobile = (styles: StyledObject) => {
  return css`
    @media screen and (max-width: ${MOBILE_BREAK_MIN - 1}px) {
      ${styles}
    }
  `;
};

/**
 * 메인 페이지에서 사용하는 미디어 쿼리
 */
export const main_page_media = {
  over1280Desktop,
  under1280Desktop,
  under1280Tablet,
  tabletOver,
  mobile,
  over500Mobile,
  under500Mobile,
};
