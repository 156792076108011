import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type GAParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickPropertyStyling: GAParameterMakerFnType<
  'ACTION_CLICK_PROPERTY_STYLING'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '요소속성',
    location3: '스타일링',
    action1: '클릭',
    label1: '요소속성_스타일링',
  } satisfies GAParams;
};
