import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

export const makeGAParamsActionClickChangeViewModeButton: GAParameterMakerFnType<
  'ACTION_CLICK_MAIN_MENU_CHANGE_VIEW_MODE_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '상단바',
    location3: '모드',
    location4: '1뎁스',
    action1: '클릭',
    label1: '상단바_모드_1뎁스',
  } satisfies GAParam<GTMTriggerEventParams>;
};

export const makeGAParamsActionClickEditModeButton: GAParameterMakerFnType<
  'ACTION_CLICK_MAIN_MENU_EDIT_MODE_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '상단바',
    location3: '모드',
    location4: '편집모드',
    action1: '클릭',
    label1: '상단바_모드_편집모드',
  } satisfies GAParam<GTMTriggerEventParams>;
};

export const makeGAParamsActionClickFeedbackModeButton: GAParameterMakerFnType<
  'ACTION_CLICK_MAIN_MENU_FEEDBACK_MODE_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '상단바',
    location3: '모드',
    location4: '피드백모드',
    action1: '클릭',
    label1: '상단바_모드_피드백모드',
  } satisfies GAParam<GTMTriggerEventParams>;
};

export const makeGAParamsActionClickViewModeButton: GAParameterMakerFnType<
  'ACTION_CLICK_MAIN_MENU_VIEW_MODE_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '상단바',
    location3: '모드',
    location4: '보기모드',
    action1: '클릭',
    label1: '상단바_모드_보기모드',
  } satisfies GAParam<GTMTriggerEventParams>;
};
