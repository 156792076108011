import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickGuideLineToggleParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickGuideLineToggle: GAParameterMakerFnType<
  'ACTION_CLICK_GUIDE_LINE_TOGGLE'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '전체메뉴',
    location3: '안내선설정',
    location4: '안내선표시',
    action1: '클릭',
    label1: '설정_안내선_보기',
  } satisfies ActionClickGuideLineToggleParams;
};
