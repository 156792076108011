import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickMainMenuButtonParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickMainMenuButton: GAParameterMakerFnType<
  'ACTION_CLICK_MAIN_MENU_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '상단바',
    location3: '전체메뉴',
    action1: '클릭',
    label1: '에디터_상단바_전체메뉴',
  } satisfies ActionClickMainMenuButtonParams;
};
