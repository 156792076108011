import { readFromBinaryFile } from 'exif-js';
import { WebViewEvent } from '@miri-unicorn/miricanvas-webview';
import { isWebView } from '@configs/devices/device_util';
import { emitWebViewEvent } from '@configs/webview/emitWebViewEvent';
import {
  AUDIO_M4A,
  AUDIO_MP3,
  IMAGE_GIF,
  IMAGE_JPEG,
  IMAGE_JPG,
  IMAGE_PNG,
  IMAGE_SVG_XML,
  VIDEO_MOV,
  VIDEO_MP4,
} from '@constants/FileMimeTypes';
import { mimeToExtensionMap } from '@constants/fileMimeTypetoExtensionMap';
import { LogUtil } from '@utils/functions/log_util';
import { urlToBlob } from '@utils/functions/url_util';
import { fetchDataURL, fetchImageElement } from './element_util';

/**
 * bytes to GB
 * @param bytes
 * @param decimal
 */
export const convertBytesToGiB = (bytes: number, decimal = 2): number => {
  return Number((bytes / Math.pow(1024, 3)).toFixed(decimal));
};

/**
 * 파일 사이즈 형태의 문자열로 반환
 * @param size bytes
 */
export const convertBytesToFileSize = (size: number): string => {
  let _size = size;
  const unit = 1024;

  if (unit > _size) {
    return `${parseFloat(_size.toFixed(2))}Bytes`;
  }

  _size /= unit;

  if (unit > _size) {
    return `${parseFloat(_size.toFixed(2))}KB`;
  }

  _size /= unit;

  if (unit > _size) {
    return `${parseFloat(_size.toFixed(2))}MB`;
  }

  _size /= unit;

  if (unit > _size) {
    return `${parseFloat(_size.toFixed(2))}GB`;
  }

  return '';
};

/*** url 로 파일 다운로드 받기  */
export const downloadByFileUrl = ({
  url,
  isOpenFromNewTab = false,
  fileName = '',
}: {
  url: string;
  isOpenFromNewTab?: boolean;
  fileName?: string;
}) => {
  if (isWebView()) {
    emitWebViewEvent(WebViewEvent.ON_CLICK_ORIGIN_DOWNLOAD, { url, fileName });
    return;
  }
  if (typeof window === 'undefined') return;
  const a = document.createElement('a');
  a.href = url;
  a.download = '';
  a.target = isOpenFromNewTab ? '_black' : '';
  // 구형 브라우저 호환 코드
  document.body.appendChild(a);

  a.click();

  setTimeout(() => {
    document.body.removeChild(a);
  }, 0);
};

export const readAsDataURL = (blob: Blob): Promise<string> => {
  const fileReader: FileReader = new FileReader();
  return new Promise<string>((resolve, reject) => {
    fileReader.readAsDataURL(blob);
    try {
      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };
      fileReader.onerror = (e) => {
        fileReader.abort();
        reject(e);
      };
    } catch (e) {
      reject(e);
    }
  });
};

export const cloneFiles = (files: File | FileList): File[] => {
  const clonedFiles: File[] = [];
  if (files instanceof File) {
    clonedFiles.push(new File([files], files.name, { type: files.type }));
  } else if (files instanceof FileList) {
    for (let i = 0; i < files.length; i++) {
      clonedFiles.push(files[i]);
    }
  }
  return clonedFiles;
};

export const readAsArrayBufferAsync = async (file: Blob): Promise<ArrayBuffer> => {
  return (await readFileAsync((fileReader) => fileReader.readAsArrayBuffer(file)))
    .result as ArrayBuffer;
};

export const readFileAsync = (
  readFunction: (fileReader: FileReader) => void
): Promise<FileReader> => {
  const fileReader: FileReader = new FileReader();
  return new Promise<FileReader>((resolve, reject) => {
    try {
      fileReader.onload = () => resolve(fileReader);
      fileReader.onerror = (e) => {
        fileReader.abort();
        reject(e);
      };

      readFunction(fileReader);
    } catch (e) {
      reject(e);
    }
  });
};

export const isAudioFile = (file: File): boolean => {
  return file.type === AUDIO_M4A || file.type === AUDIO_MP3;
};

export const isVideoFile = (file: File): boolean => {
  return file.type === VIDEO_MP4 || file.type === VIDEO_MOV;
};

const checkFileHasOrientation = async (file: File): Promise<boolean> => {
  switch (file.type) {
    case IMAGE_JPEG:
    case IMAGE_JPG:
      const arrayBuffer: ArrayBuffer = await readAsArrayBufferAsync(file);
      const exif = readFromBinaryFile(arrayBuffer);
      return exif && exif.Orientation > 0;
    default:
      return false;
  }
};
/**
 * 이미지 파일의 회전값을 제거한다.
 * @description 회전 값이 들어간 이미지를 합성하면 회전된 상태로 합성되는 현상,
 * 업로드할 때 사진의 회전정보를 제거 후 업로드하도록 변경
 * @see [1.0 web code](https://github.com/miridih/miricanvas-web/blob/develop/packages/miricanvas-shared-web/src/common/util/FileUtil.ts#L120)
 */
export const removeImageFileOrientation = async (file: File): Promise<File> => {
  let hasOrientation: boolean;
  try {
    hasOrientation = await checkFileHasOrientation(file);
  } catch (e) {
    LogUtil.error.dev('라벨수정부탁', e);
    hasOrientation = true;
  }

  return hasOrientation ? cloneImageFileByCanvas(file) : file;
};

const cloneImageFileByCanvas = async (file: File): Promise<File> => {
  const makeBlobFromCanvas = async (_canvas: HTMLCanvasElement): Promise<Blob> => {
    return new Promise<Blob>((resolve, reject) => {
      try {
        _canvas.toBlob((blob: Blob | null) => {
          blob ? resolve(blob) : reject(new Error('blob is null'));
        }, file.type);
      } catch (e) {
        reject(e);
      }
    });
  };
  const img: HTMLImageElement = await fetchImageElement(await fetchDataURL(file));
  const canvas = document.createElement('canvas');

  canvas.width = img.width;
  canvas.height = img.height;

  const ctx = canvas.getContext('2d');
  ctx?.drawImage(img, 0, 0, img.width, img.height);

  return new File([await makeBlobFromCanvas(canvas)], file.name, { type: file.type });
};

export const getHeaderFileTypes = async (file: File | Blob) => {
  const arrayBuffer: ArrayBuffer = await readAsArrayBufferAsync(file);
  const arr: Uint8Array = new Uint8Array(arrayBuffer).subarray(0, 4);
  let header = '';
  arr.forEach((unit) => (header += unit.toString(16)));

  /** ID3 태그가 없는 mp3 파일 */
  if (header.startsWith('fffb')) {
    return [AUDIO_MP3];
  }

  switch (header) {
    case 'ffd8ffdb':
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
    case 'ffd8ffed':
    case 'ffd8ffee':
    case 'ffd8ffeb':
      return [IMAGE_JPEG, IMAGE_JPG];

    case '89504e47':
      return [IMAGE_PNG];

    case '47494638':
      return [IMAGE_GIF];

    case '00018':
    case '00020':
    case '0001c':
    case '00014':
      // m4a는 mp4와 헤더가 같음
      return [VIDEO_MP4, AUDIO_M4A];
    case '4944332':
    case '4944333':
    case '4944334':
      return [AUDIO_MP3];

    default:
      return [IMAGE_SVG_XML];
  }
};

/**
 * @param url
 * @param fileName
 * @param disabledCache
 * CORS 오류 방지를 위해 필요 시, 브라우저 캐시를 사용하지 않는 옵션을 제공함.
 */
export const urlToFile = async ({
  url,
  fileName,
  disableCache = false,
}: {
  url: string;
  // 확장자가 포함되지 않은 파일명을 기대한다.
  fileName: string;
  disableCache?: boolean;
}): Promise<File> => {
  const blob = await urlToBlob({ imageUrl: url, disableCache });
  const headerFileType = (await getHeaderFileTypes(blob))[0];
  const fullFileName = `${fileName}.${mimeToExtensionMap[headerFileType] || 'bin'}`;
  return new File([blob], fullFileName, { type: headerFileType.toString() });
};

export const convertFileFromBase64 = (base64: string, fileName: string, type: string) => {
  const imageContent = atob(base64);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }
  const parseImageType = type === 'image/jpg' ? 'image/jpeg' : type;
  // console.log ('default', 'image/jpeg')
  const blob = new Blob([buffer], { type: parseImageType });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type: parseImageType });
};

/**
 * `File`의 배열을 `FileList`로 변환한다
 */
export const convertFileArrayToFileList = (files: Array<File>): FileList => {
  const dataTransfer = new DataTransfer();
  files.forEach((file) => dataTransfer.items.add(file));
  return dataTransfer.files;
};
