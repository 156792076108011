import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickAIPresentationTemplateSelectPreviousButton: GAParameterMakerFnType<
  'ACTION_CLICK_AI_PRESENTATION_TEMPLATE_SELECT_PREVIOUS_BUTTON'
> = () => ({
  log_type: 'generic_event_mican',
  event: 'gtm_trigger_event',
  location1: '에디터',
  location2: '프레젠테이션 자동 생성',
  location3: '템플릿 선택',
  location4: '뒤로가기',
  action1: '클릭',
  label1: '프레젠테이션 자동 생성_템플릿 선택_뒤로가기',
});
