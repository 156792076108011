import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickAutoSaveToggleParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickAutoSaveToggle: GAParameterMakerFnType<
  'ACTION_CLICK_AUTO_SAVE_TOGGLE'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '전체메뉴',
    location3: '자동저장',
    action1: '클릭',
    label1: '설정_자동저장',
  } satisfies ActionClickAutoSaveToggleParams;
};
