import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { ActionClickAIChatbotTemplatePayload } from '../_types/ActionClickAIChatbotTemplatePayload';

type GAParams = GAParam<ActionClickAIChatbotTemplatePayload>;

export const makeGAParamsActionClickTemplateChatbotItem: GAParameterMakerFnType<
  'ACTION_CLICK_TEMPLATE_CHATBOT_ITEM'
> = (payload) =>
  ({
    ...payload.data,
    event: 'action_template_chatbot',
    log_type: 'action_template_chatbot',
    account_id: payload.data.account_id,
    conversation_id: payload.data.conversation_id,
    design_idx: payload.data.design_idx,
    inflow_type: payload.data.inflow_type,
    last_search_term: payload.data.last_search_term,
    plan_type: payload.data.plan_type,
    preferred_language: payload.data.preferred_language,
    team_idx: payload.data.team_idx,
    template_key: payload.data.template_key,
    template_page_type: payload.data.template_page_type,
  } satisfies GAParams);
