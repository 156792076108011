import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionUserInitialize: GAParameterMakerFnType<'ACTION_USER_INITIALIZE'> = (
  params
) => {
  return {
    event: 'config_user_initialize',
    log_type: 'config_user_initialize',
    user_id: params.data.user_id?.toString(),
    team_idx: params.data.team_idx?.toString(),
    plan_type: params.data.plan_type,
  };
};
