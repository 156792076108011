import { DesignResourcePanelMenuType } from '@constants/DesignResourcePanelMenu';

export const LEFT_PANEL_PADDING_X = 24;
export const LEFT_PANEL_BAR_WIDTH = 72;
export const LEFT_PANEL_MIN_WIDTH = 156;
export const INITIAL_LEFT_PANEL_WIDTH = 360;
// 패널 크기 조정 시, 좌패널이 닫히는 최대 너비
export const MAX_PANEL_WIDTH_TO_CLOSE = 280;
// 핸들 클릭 시 좌패널 확장 사이즈 (내부 요소 544 + 패딩 24 * 2)
export const PANEL_WIDTH_EXPANDED_BY_CLICK = 544 + LEFT_PANEL_PADDING_X * 2;

export const LEFT_PANEL_SEARCH_FILTER_POPOVER_WIDTH =
  INITIAL_LEFT_PANEL_WIDTH - LEFT_PANEL_PADDING_X * 2;

// HANDLE SIZE
export const HANDLE_HEIGHT = 97;
export const HANDLE_WIDTH = 30;

// HANDLE CONDITION
export const HANDLE_ENABLED_MENU: DesignResourcePanelMenuType[] = [
  'TEMPLATE',
  'ELEMENTS',
  'PICTURE',
  'CREATOR',
];
export const MIN_VW_SHOW_HANDLE = 1044;

// TEMPLATE PANEL
// 템플릿 패널에서, 검색창과 타입 드롭다운을 세로로 보여주는 최대 너비
export const MAX_PANEL_WIDTH_TO_SHOW_VERTICAL_SEARCH_IN_TEMPLATE_PANEL = 580;

// 좌패널 리사이징 손잡이 드래그로 판단하는 조건
export const DRAG_CONDITION = {
  // 마우스 누른 후, 픽셀 단위의 움직인 거리
  MIN_MOUSE_MOVE_DISTANCE: 50,
  // 마우스 누른 후, 떼기 직전까지 ms단위의 누른 시간
  MIN_MOUSE_DOWN_HOLD_TIME: 750,
} as const;
