import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { getSearchActionStatus } from '@configs/google_analytics/utils/getSearchStatus';
import { makeTextTabKeyToKoreanMap } from '../utils/makeTextTabKeyToKoreanMap';

export const makeGAParamsActionClickTextItem: GAParameterMakerFnType<'ACTION_CLICK_TEXT_ITEM'> = (
  payload
) => {
  return {
    event: 'action_text',
    log_type: 'action_text',
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
    search_status: getSearchActionStatus(payload.data.keyword),
    search_type: payload.data.search_type,
    search_term: payload.data.keyword,
    search_text_type: makeTextTabKeyToKoreanMap(payload.data.tabKey),
    action_item: payload.data.key.toString(),
    action_item_type: payload.data.resourceType,
    search_keyword_category: payload.data.keywordCategoryName,
    priority: payload.data.verticalViewSequence?.toString(),
    priority2: payload.data.horizontalViewSequence?.toString(),
  };
};
