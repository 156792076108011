export const MILLI_SECONDS_OF_ONE_SECOND = 1000;
/** 1분에 해당하는 밀리 초 */
export const MILLI_SECONDS_OF_ONE_MINUTE = 60 * MILLI_SECONDS_OF_ONE_SECOND;
/** 1시간에 해당하는 밀리 초 */
export const MILLI_SECONDS_OF_ONE_HOUR = 60 * MILLI_SECONDS_OF_ONE_MINUTE;
/** 24시간에 해당하는 밀리 초 */
export const MILLI_SECONDS_OF_ONE_DAY = 24 * MILLI_SECONDS_OF_ONE_HOUR;

/** 60fps에서 한 프레임당 ms */
export const MILLI_SECONDS_PER_60FPS_FRAME = 16;
/** 30fps에서 한 프레임당 ms */
export const MILLI_SECONDS_PER_30FPS_FRAME = 33;
