import {
  MutationFunction,
  UseMutationOptions,
  useMutation as _useMutation,
} from '@tanstack/react-query';

export const useMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'> | undefined
) => _useMutation(mutationFn, options);
