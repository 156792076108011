import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';
import { getSearchActionStatus } from '@configs/google_analytics/utils/getSearchStatus';

export const makeGAParamsActionClickVideo: GAParameterMakerFnType<'ACTION_CLICK_VIDEO_ITEM'> = (
  payload
) => {
  return {
    log_type: 'action_video',
    event: 'action_video',
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
    action_item: payload.data.key,
    action_item_type: payload.data.resourceType,
    search_keyword_category: payload.data.keywordCategoryName,

    // payload.data.keyword 를 미리 검사해서 search_status 를 결정한 채로 넘겨주게
    search_status: getSearchActionStatus(payload.data.keyword),
    search_term: payload.data.keyword,
    search_type: payload.data.searchType,

    // 클릭을 통한 동영상 채택은 항상 고정
    search_video_type: '동영상',
    priority: payload.data.verticalViewSequence,
    priority2: payload.data.horizontalViewSequence,
  };
};

export const makeGAParamsActionSearchVideo: GAParameterMakerFnType<'ACTION_SEARCH_VIDEO_ITEM'> = (
  payload
) => {
  return {
    log_type: 'search_video',
    event: 'search_video',
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
    search_status: getSearchActionStatus(payload.data.keyword),
    search_term: payload.data.keyword,
    search_type: payload.data.searchType,
  };
};

export const makeGAParamsActionViewVideo: GAParameterMakerFnType<'ACTION_VIEW_VIDEO_ITEM'> = (
  payload
) => {
  return {
    log_type: 'view_video',
    event: 'view_video',
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
    search_status: getSearchActionStatus(payload.data.keyword),
    search_term: payload.data.keyword,
    search_type: payload.data.searchType,
    view_item_key: payload.data.view_item_key.join(','),
    view_item_type: payload.data.view_item_type.join(', '),
    view_load_num: payload.data.view_load_num,
  };
};

type ActionClickVideoEditorPromotionBanner = GAParam<GTMTriggerEventParams>;
type ActionClickYoutubeTab = GAParam<GTMTriggerEventParams>;
type ActionClickYoutubeMoveButton = GAParam<GTMTriggerEventParams>;
type ActionClickYoutubeMakeButton = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickVideoEditorPromotionBanner: GAParameterMakerFnType<
  'ACTION_CLICK_VIDEO_EDITOR_PROMOTION_BANNER'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '동영상',
    location3: '동영상제작유도배너',
    action1: '클릭',
    label1: '동영상_동영상제작유도배너',
  } satisfies ActionClickVideoEditorPromotionBanner;
};

export const makeGAParamsActionClickYoutubeTab: GAParameterMakerFnType<
  'ACTION_CLICK_YOUTUBE_TAB'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '동영상',
    location3: 'YouTube 탭',
    action1: '클릭',
    label1: '동영상_YouTube탭',
  } satisfies ActionClickYoutubeTab;
};

export const makeGAParamsActionClickYoutubeMoveButton: GAParameterMakerFnType<
  'ACTION_CLICK_YOUTUBE_MOVE_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '동영상',
    location3: 'YouTube 탭',
    location4: 'YouTube 이동',
    action1: '클릭',
    label1: '동영상_YouTube탭_YouTube이동',
  } satisfies ActionClickYoutubeMoveButton;
};

export const makeGAParamsActionClickYoutubeMakeButton: GAParameterMakerFnType<
  'ACTION_CLICK_YOUTUBE_MAKE_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '동영상',
    location3: 'YouTube 탭',
    location4: '만들기',
    action1: '클릭',
    label1: '동영상_YouTube탭_만들기',
  } satisfies ActionClickYoutubeMakeButton;
};
