import isPropValid from '@emotion/is-prop-valid';

/**
 * https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
 * styled-comoponents v6 부터 shouldForwardProp 이 기본으로 제공되지 않음
 * 만약 아래 세팅이 없다면 styled-components 에 제공되는 props 가 실제 dom tree 에 attribute 로 추가됨
 */
export const shouldForwardProp = (propName: string, target: any) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
};
