import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import {
  GTMTriggerEventParams,
  GTMUserParams,
} from '@configs/google_analytics/types/GTMTriggerEventParams';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

type ActionSelectDownloadFileType = GAParam<GTMTriggerEventParams & GTMUserParams>;

export const makeGAParamsActionSelectDownloadFileType: GAParameterMakerFnType<
  'ACTION_DOWNLOAD_FILE_TYPE_SELECT_EVENT'
> = (payload) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '상단바',
    location3: '다운로드',
    location4: '파일형식',
    action1: '클릭',
    label1: '상단바_다운로드_파일형식_클릭',
    label2: payload.data.previous_selected_file_type,
    label3: payload.data.current_selected_file_type,
    account_id: payload.data.account_id,
  } satisfies ActionSelectDownloadFileType;
};
