import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type GAParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickPropertyMoreLayer: GAParameterMakerFnType<
  'ACTION_CLICK_PROPERTY_MORE_LAYER'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '요소속성',
    location3: '더보기',
    location4: '순서',
    action1: '클릭',
    label1: '요소속성_더보기_순서',
  } satisfies GAParams;
};
