import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionViewTemplateWorkspace: GAParameterMakerFnType<
  'ACTION_VIEW_TEMPLATE_WORKSPACE'
> = (payload) => {
  return {
    log_type: 'view_template_workspace',
    event: 'view_template_workspace',
    search_status: payload.data.search_status,
    search_term: payload.data.search_term,
    source_term: payload.data.source_term,
    search_type: payload.data.search_type,
    search_template_type: payload.data.search_template_type,
    beginning_template: payload.data.beginning_template,
    view_item_key: payload.data.view_item_key.join(', '),
    view_item_type: payload.data.view_item_type.join(', '),
    view_load_num: payload.data.view_load_num.toString(),
    account_id: payload.data.account_id?.toString(),
    plan_type: payload.data.plan_type,
    team_idx: payload.data.team_idx?.toString(),
  };
};
