/**
 * url 전체를 반환하는 함수
 */
export const pathToFullUrl = (path = '') => {
  if (typeof window !== undefined) {
    return `${location.origin}${path}`;
  }
};

/**
 * 주어진 URL의 해시 부분에 특정 문자열(target)이 포함되어 있는지 확인하는 함수입니다.
 *
 * @param target - 찾을 문자열
 * @param url - 검사할 URL.
 * @returns boolean - target이 해시 부분에 포함되어 있으면 true, 그렇지 않으면 false를 반환합니다.
 */
export const hasHash = (target: string, url: string): boolean => {
  const hashPart = url.split('#')[1];

  if (!hashPart) {
    return false;
  }

  const pairs = hashPart.split('&');

  for (const pair of pairs) {
    const [key, value] = pair.split('=');
    if (key === target || value === target) {
      return true;
    }
  }

  return false;
};

/** 브라우저 네비게이션 발생없이, 해시만 URL에서 삭제
 * 주의: 서버 사이드 렌더링 시에는 사용하지 말 것.
 */
export const removeHash = (): void => {
  const { pathname, search } = location;
  history.replaceState('', document.title, `${pathname}${search}`);
};

export const getQueryString = (target: string, url?: string): string => {
  if (typeof window === 'undefined') {
    return '';
  }
  const effectiveUrl = url || window.location.href;

  const queryStringPart = effectiveUrl.split('?')[1];

  if (!queryStringPart) {
    return '';
  }

  const pairs = queryStringPart.split('&');

  for (const pair of pairs) {
    const [key, value] = pair.split('=');
    if (key === target) {
      return value;
    }
  }

  return '';
};

export const removeQueryString = (parameter: string) => {
  const { pathname, search } = location;

  const queryStringPart = search.split('?')[1];

  if (!queryStringPart) {
    return;
  }

  const regexp = new RegExp(`&?${parameter}=\\w+`);
  const newQueryStringPart = queryStringPart.replace(regexp, '');

  history.replaceState(
    '',
    document.title,
    `${pathname}${newQueryStringPart.length ? `?${newQueryStringPart}` : ''}`
  );
};

/**
 *
 * @param url
 * @param property 쿼리스트링 이름
 * @param newValue property 의 값
 * @example
 * `replaceQueryString(https://www.naver.com?size=400, 'size', '200')` returns `https://www.naver.com?size=200`
 */
export const replaceQueryString = (url: string, property: string, newValue: string) => {
  // url 에 빈문자열이 왔을때 에러처리
  if (!url || typeof url !== 'string') {
    return url;
  }

  const urlObj = new URL(url);

  // 기존 url 에 property 가 없을경우 바로 리턴
  if (!urlObj.searchParams.has(property)) {
    return url;
  }

  urlObj.searchParams.set(property, newValue);
  return urlObj.toString();
};

export const getFileExtension = (url?: string): string => {
  const extension = url?.split(/[#?]/)?.[0]?.split('.')?.pop()?.trim() ?? '';
  return extension !== url ? extension : '';
};

/**
 *
 * @param imageUrl
 * @param callback
 * @param disableCache
 * CORS 오류 방지를 위해 필요 시, 브라우저 캐시를 사용하지 않는 옵션을 제공함.
 */
export const urlToBlob = async ({
  imageUrl,
  callback,
  disableCache = false,
}: {
  imageUrl: string;
  callback?: (xhr: XMLHttpRequest, progress: number) => void;
  disableCache?: boolean;
}): Promise<Blob> => {
  /**
   * dataUrl은 뒤에 임의의 쿼리 스트링이 붙으면 유효성이 깨지므로
   * imageUrl일 경우에만 임의의 쿼리 스트링으로 캐시를 사용하지 않도록 한다.
   * dataUrl은 어차피 항상 캐시를 사용하지 않는다.
   */
  const url =
    disableCache && !isDataUrl(imageUrl) ? `${imageUrl}?t=${new Date().getTime()}` : imageUrl;
  return new Promise<Blob>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onprogress = (e) => {
      if (e.lengthComputable && callback) {
        callback(xhr, (e.loaded / e.total) * 100);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    xhr.onload = function (e: ProgressEvent) {
      if (this.status === 200) {
        resolve(this.response);
      } else {
        reject();
        xhr.abort();
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    xhr.onerror = function (e: ProgressEvent) {
      xhr.abort();
    };
    xhr.send();
  });
};

export const getSubdomain = (hostname: string): string => {
  const subdomain = hostname.split('.')[0];
  return subdomain !== hostname ? subdomain : '';
};

export const getCurrentLocation = () => {
  if (typeof window === 'undefined') {
    return null;
  }
  return new URL(window.location.href);
};

export const isDataUrl = (url: string): boolean => {
  return url.startsWith('data:');
};

export const extractTemplateIdxAndTitleAtQueryString = (qs: string) => {
  const qsList = qs.split('-');

  const encodedTemplateIdx = qsList.shift();

  return {
    encodedTemplateIdx,
    templateTitle: qsList.join(' '),
  };
};
