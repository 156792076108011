import { useEffect } from 'react';
import { EventPayload } from '../_types/EventPayload';
import { EventType } from '../_types/EventType';
import { eventSubject } from '../eventSubject';

export const useEventsListener = (
  types: readonly EventType[],
  handler: <EVENT_TYPE extends EventType>(
    eventType: EVENT_TYPE,
    eventPayload: EventPayload<EVENT_TYPE>
  ) => void
) => {
  useEffect(() => {
    const subscription = eventSubject.subscribe((subjectValue) => {
      if (!types.includes(subjectValue.type)) {
        return;
      }
      handler(subjectValue.type, subjectValue.payload);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [types, handler]);
};
