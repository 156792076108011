import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickPicture: GAParameterMakerFnType<'ACTION_CLICK_PICTURE_ITEM'> = (
  payload
) => {
  return {
    log_type: 'action_image',
    event: 'action_image',
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    beginning_element: payload.data.beginning_element,
    beginning_element_type: payload.data.beginning_element_type,
    source_tab: payload.data.sourceTab,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
    action_item: payload.data.key,
    action_item_type: payload.data.resourceType,
    search_keyword_category: payload.data.keywordCategoryName,
    search_status: payload.data.search_status,
    search_term: payload.data.keyword,
    search_type: payload.data.searchType,
    search_color: payload.data.color,
    search_shape: payload.data.aspectRatio,
    priority: payload.data.verticalViewSequence,
    priority2: payload.data.horizontalViewSequence,
  };
};

export const makeGAParamsActionSearchPicture: GAParameterMakerFnType<
  'ACTION_SEARCH_PICTURE_ITEM'
> = (payload) => {
  return {
    log_type: 'search_image',
    event: 'search_image',
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    beginning_element: payload.data.beginning_element,
    beginning_element_type: payload.data.beginning_element_type,
    source_tab: payload.data.sourceTab,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
    search_status: payload.data.search_status,
    search_term: payload.data.keyword,
    search_type: payload.data.searchType,
    search_color: payload.data.color,
    search_shape: payload.data.aspectRatio,
  };
};

export const makeGAParamsActionViewPictureItem: GAParameterMakerFnType<
  'ACTION_VIEW_PICTURE_ITEM'
> = (payload) => {
  return {
    log_type: 'view_image',
    event: 'view_image',
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    beginning_element: payload.data.beginning_element,
    beginning_element_type: payload.data.beginning_element_type,
    source_tab: payload.data.sourceTab,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
    search_status: payload.data.search_status,
    search_term: payload.data.keyword,
    search_type: payload.data.searchType,
    search_color: payload.data.color,
    search_shape: payload.data.aspectRatio,
    view_item_key: payload.data.view_item_key.join(','),
    view_item_type: payload.data.view_item_type.join(', '),
    view_load_num: payload.data.view_load_num,
  };
};
