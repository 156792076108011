import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickMainPageFloatingSignUpButton: GAParameterMakerFnType<
  'ACTION_CLICK_MAIN_PAGE_FLOATING_SIGN_UP_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '메인페이지',
    location2: '고정StartForFree',
    action1: '클릭',
    label1: '메인페이지_고정_StartForFree_클릭',
  };
};
