import { AIDrawingGTMTriggerEventParams } from '@configs/google_analytics/types/AIDrawingGTMTriggerEventParams';

/**
 * 데이터 레이어에 string 또는 undefined 값을 전송해야하므로,
 * 모든 속성에 대해 undefined 값을 할당한 객체를 초기화에 사용한다.
 */
export const DEFAULT_AI_DRAWING_EVENT_PAYLOAD: {
  [P in keyof AIDrawingGTMTriggerEventParams]: undefined;
} = {
  event: undefined,
  log_type: undefined,
  team_idx: undefined,
  account_id: undefined,
  design_idx: undefined,
  positive_prompt: undefined,
  drawing_style: undefined,
  image_url: undefined,
  thumbnail_url: undefined,
  reference_type: undefined,
  reference_keyword: undefined,
  retry_keyword: undefined,
  retry_style: undefined,
  action_label: undefined,
  action_depth: undefined,
  inflow_type: undefined,
  inflow_keyword: undefined,
  etc1: undefined,
  etc2: undefined,
  etc3: undefined,
  etc4: undefined,
  etc5: undefined,
  prompt_source: undefined,
  denoise_strength: undefined,
  retry_denoise_strength: undefined,
  plan_type: undefined,
  language_setting: undefined,
  image_uuid: undefined,
  success_count: undefined,
  failed_count: undefined,
  remain_count: undefined,
  action_type: undefined,
  premium_remain_count: undefined,
  file_key: undefined,
} satisfies AIDrawingGTMTriggerEventParams;
