import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickAIPresentationRetryButton: GAParameterMakerFnType<
  'ACTION_CLICK_AI_PRESENTATION_RETRY_BUTTON'
> = (payload) => ({
  log_type: 'generic_event_mican',
  event: 'gtm_trigger_event',
  location1: '에디터',
  location2: '프레젠테이션 자동 생성',
  location3: '로딩 중',
  location4: '다시 시도',
  action1: '클릭',
  label1: '프레젠테이션 자동 생성_로딩 중_다시 시도',
  label2: payload.data.requestIdx.toString(),
  account_id: payload.data.accountId?.toString(),
});
