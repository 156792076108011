import { AudioTempo } from '@miri-unicorn/miricanvas-api-client';

const audioTempoToKoreanMap: Record<AudioTempo, string> = {
  SLOW: '느림',
  NORMAL: '보통',
  FAST: '빠름',
};

export const makeAudioTempoToKoreanMap = (tempo?: AudioTempo) =>
  tempo ? audioTempoToKoreanMap[tempo] : undefined;
