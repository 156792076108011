import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickHeaderPrintoutButton = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickHeaderPrintoutButton: GAParameterMakerFnType<
  'ACTION_CLICK_HEADER_PRINTOUT_BUTTON'
> = (payload) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '인쇄물제작',
    action1: '클릭',
    label1: '에디터_상단바_인쇄물제작버튼',
    account_id: payload.data.account_id,
  } satisfies ActionClickHeaderPrintoutButton;
};
