import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickPropertyElementMove = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickPropertyElementMove: GAParameterMakerFnType<
  'ACTION_CLICK_PROPERTY_ELEMENT_MOVE'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    label1: '요소속성_위치이동',
    location1: '에디터',
    location2: '요소속성',
    location3: '위치이동',
    action1: '클릭',
  } satisfies ActionClickPropertyElementMove;
};
