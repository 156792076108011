/** @type {NodeJS.ProcessEnv} */
module.exports = {
  NODE_ENV: process.env.NODE_ENV,
  NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
  DOMAIN: process.env.DOMAIN,
  NEXT_PUBLIC_USE_PROXY: process.env.NEXT_PUBLIC_USE_PROXY,
  NEXT_PUBLIC_PAYMENT_GATEWAY_API_URL: process.env.NEXT_PUBLIC_PAYMENT_GATEWAY_API_URL,
  NEXT_PUBLIC_PAYMENT_GATEWAY_SCRIPT_URL: process.env.NEXT_PUBLIC_PAYMENT_GATEWAY_SCRIPT_URL,
  ANALYZE: process.env.ANALYZE,
  CHECK_TS: process.env.CHECK_TS,
  GOOGLE_SERVICE_ACCOUNT_EMAIL: process.env.GOOGLE_SERVICE_ACCOUNT_EMAIL,
  GOOGLE_PRIVATE_KEY: process.env.GOOGLE_PRIVATE_KEY,
  NEXT_PUBLIC_LOCAL_PROD: process.env.NEXT_PUBLIC_LOCAL_PROD,
  LHCI_GOOGLE_PRIVATE_KEY: process.env.LHCI_GOOGLE_PRIVATE_KEY,
  LHCI_GOOGLE_CLIENT_EMAIL: process.env.LHCI_GOOGLE_CLIENT_EMAIL,
  LHCI_GOOGLE_SPREAD_SHEET_ID: process.env.LHCI_GOOGLE_SPREAD_SHEET_ID,
  NEXT_PUBLIC_APP_VERSION: process.env.NEXT_PUBLIC_APP_VERSION,
  NEXT_PUBLIC_DATADOG_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  NEXT_PUBLIC_DATADOG_APP_ID: process.env.NEXT_PUBLIC_DATADOG_APP_ID,
};
