import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickDarkModeActiveButtonParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickDarkModeActiveButton: GAParameterMakerFnType<
  'ACTION_CLICK_DARK_MODE_ACTIVE_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '전체메뉴',
    location3: '다크모드',
    action1: '클릭',
    label1: '설정_다크모드',
  } satisfies ActionClickDarkModeActiveButtonParams;
};
