import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickGlobalMainPageFloatingButton: GAParameterMakerFnType<
  'ACTION_CLICK_GLOBAL_MAIN_PAGE_FLOATING_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '해외메인페이지',
    location2: '템플릿페이지고정CTA',
    action1: '클릭',
    label1: '해외메인페이지_템플릿페이지고정CTA',
  };
};
