import { DEFAULT_OBJECT_REMOVAL_EVENT_PAYLOAD } from '@configs/google_analytics/features/property_panel/photo_extra_edit/_constants/defaultObjectRemovalGAPayload';
import {
  ObjectRemovalGTMTriggerEventParams,
  ObjectRemovalGTMTriggerEventPayload,
} from '@configs/google_analytics/types/ObjectRemovalGTMTriggerEventParams';

/**
 * 영역 지우개 GA 페이로드를 다음 규칙에 따라 가공하여 실제로 보낼 GA 파라미터를 만든다.
 * 1. 최종 값은 `string`이거나 `undefined`여야한다.
 * 2. 특히 값이 `string`인 경우, 문자열에서 모든 공백 문자를 제거한다.
 * 3. 빈 문자열이면 `undefined`로 바꾼다.
 */
export const makeObjectRemovalGAParameters = (
  payload: Partial<ObjectRemovalGTMTriggerEventPayload>
): ObjectRemovalGTMTriggerEventParams => {
  const keys = Object.keys(payload) as Array<keyof typeof payload>;
  const params = keys.reduce((acc: { [key in keyof typeof payload]: string | undefined }, key) => {
    const value = payload[key];
    /**
     * AI 도구와 쉬운 편집 GA는 미캔 기본 GA와 다른 별도의 데이터 레이블을 사용하기 때문에 추가 처리 필요.
     * 데이터 집계에 혼동이 없도록 account_id가 0인 경우(비로그인 사용자) undefined로 처리.
     */
    if (key === 'account_id' && value === 0) {
      acc[key] = undefined;
    } else {
      acc[key] = value?.toString().replace(/\s/g, '') || undefined;
    }
    return acc;
  }, {});

  return {
    ...DEFAULT_OBJECT_REMOVAL_EVENT_PAYLOAD,
    ...params,
  };
};
