import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type GAParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickPhotoPropertyCrop: GAParameterMakerFnType<
  'ACTION_CLICK_PHOTO_PROPERTY_CROP'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '사진속성창',
    location3: '자르기',
    action1: '클릭',
    label1: '에디터_속성창만_자르기',
  } satisfies GAParams;
};

export const makeGAParamsActionClickPhotoPropertyTransparency: GAParameterMakerFnType<
  'ACTION_CLICK_PHOTO_PROPERTY_TRANSPARENCY'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '사진속성창',
    location3: '투명도',
    action1: '클릭',
    label1: '에디터_속성창만_투명도',
  } satisfies GAParams;
};

export const makeGAParamsActionClickPhotoPropertyAlign: GAParameterMakerFnType<
  'ACTION_CLICK_PHOTO_PROPERTY_ALIGN'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '사진속성창',
    location3: '정렬',
    action1: '클릭',
    label1: '에디터_속성창만_정렬',
  } satisfies GAParams;
};

export const makeGAParamsActionClickPhotoPropertyOrder: GAParameterMakerFnType<
  'ACTION_CLICK_PHOTO_PROPERTY_ORDER'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '사진속성창',
    location3: '순서',
    action1: '클릭',
    label1: '에디터_속성창만_순서',
  } satisfies GAParams;
};

export const makeGAParamsActionClickPhotoPropertyFlip: GAParameterMakerFnType<
  'ACTION_CLICK_PHOTO_PROPERTY_FLIP'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '사진속성창',
    location3: '반전',
    action1: '클릭',
    label1: '에디터_속성창만_반전',
  } satisfies GAParams;
};

export const makeGAParamsActionClickPhotoPropertySetAsBackground: GAParameterMakerFnType<
  'ACTION_CLICK_PHOTO_PROPERTY_SET_AS_BACKGROUND'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '사진속성창',
    location3: '배경으로설정',
    action1: '클릭',
    label1: '에디터_속성창만_배경으로설정',
  } satisfies GAParams;
};

export const makeGAParamsActionClickPhotoPropertyResetPhoto: GAParameterMakerFnType<
  'ACTION_CLICK_PHOTO_PROPERTY_RESET_PHOTO'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '사진속성창',
    location3: '사진초기화',
    action1: '클릭',
    label1: '에디터_속성창만_사진초기화',
  } satisfies GAParams;
};
