import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type GAParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionTextLock: GAParameterMakerFnType<
  'ACTION_CLICK_TEXT_CONTENT_LOCK'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '텍스트속성',
    location3: '텍스트내용잠금',
    action1: '클릭',
    label1: '텍스트속성_텍스트내용잠금',
  } satisfies GAParams;
};
