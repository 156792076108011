import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickHeaderDesignTitleParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickHeaderDesignTitle: GAParameterMakerFnType<
  'ACTION_CLICK_HEADER_DESIGN_TITLE'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '상단바',
    location3: '제목을입력해주세요',
    action1: '클릭',
    label1: '상단바_제목을입력해주세요',
  } satisfies ActionClickHeaderDesignTitleParams;
};
