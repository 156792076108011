import { BreakPointTypeEnum } from '@configs/break-points/break_points_type';
import { useViewportWidth } from '@utils/hooks/viewport/useViewportWidth';

export const useBreakPointType = () => {
  const isMobile = useViewportWidth((width) => width <= 767);

  if (isMobile) {
    return BreakPointTypeEnum.MOBILE;
  }

  return BreakPointTypeEnum.DESKTOP;
};
