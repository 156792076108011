import { RefObject, useCallback } from 'react';
import { isTouchScreenDevice } from '@configs/devices/device_util';
import { useBoolean, useEventListener } from '@utils/hooks';

/**
 * ### useHover 커스텀 훅
 *
 * - ref 를 연결한 요소에 마우스가 올려졌는지 여부를 boolean 으로 반환합니다.
 * @example
 * ```ts
 * const hoverRef = useRef(null)
 * const isHover = useHover(hoverRef)
 *  <div ref={hoverRef}>
 *    {isHover ? `hovered` : `unhovered`}
 *  </div>
 * ```
 */
export const useHover = <T extends HTMLElement = HTMLElement>(
  elementRef: RefObject<T>
): boolean => {
  const { value, setTrue, setFalse } = useBoolean(false);
  const isTouchDevice = isTouchScreenDevice();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMouseEnter = useCallback(() => {
    !isTouchDevice && setTrue();
  }, [isTouchDevice, setTrue]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMouseLeave = useCallback(() => {
    !isTouchDevice && setFalse();
  }, [isTouchDevice, setFalse]);

  useEventListener('mouseenter', handleMouseEnter, elementRef);
  useEventListener('mouseleave', handleMouseLeave, elementRef);

  return value;
};
