import { DEFAULT_AI_DRAWING_EVENT_PAYLOAD } from '@configs/google_analytics/features/left_panel/ai_drawing/constants/defaultAIDrawingGAPayload';
import {
  AIDrawingGTMTriggerEventParams,
  AIDrawingGTMTriggerEventPayload,
} from '@configs/google_analytics/types/AIDrawingGTMTriggerEventParams';

/**
 * AI 드로잉 GA 페이로드를 다음 규칙에 따라 가공하여 실제로 보낼 GA 파라미터를 만든다.
 * 1. 최종 값은 `string`이거나 `undefined`여야한다.
 * 2. 특히 값이 `string`인 경우, `positive_prompt`를 제외하고 문자열에서 모든 공백 문자를 제거한다.
 * 3. 빈 문자열이면 `undefined`로 바꾼다.
 */
export const makeAIDrawingGAParameters = (
  payload: Partial<AIDrawingGTMTriggerEventPayload>
): AIDrawingGTMTriggerEventParams => {
  const keys = Object.keys(payload) as Array<keyof typeof payload>;
  const params = keys.reduce(
    (acc: { [key in keyof typeof payload]: AIDrawingGTMTriggerEventParams[key] }, key) => {
      if (key === 'positive_prompt' || key === 'retry_denoise_strength') {
        (acc[key] as (typeof payload)[typeof key]) = payload[key];
      } else if (key === 'account_id' && payload[key] === 0) acc[key] = undefined;
      else acc[key] = payload[key]?.toString().replace(/\s/g, '') || undefined;

      return acc;
    },
    {}
  );

  return {
    ...DEFAULT_AI_DRAWING_EVENT_PAYLOAD,
    ...params,
  };
};
