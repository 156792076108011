import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionInviteFriendLinkCopy: GAParameterMakerFnType<
  'ACTION_CLICK_INVITE_FRIEND_LINK_COPY_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '친구초대',
    location3: 'Copy',
    label1: '에디터_친구초대_Copy',
    action1: '클릭',
  };
};
