import {
  DesignResourceType,
  GetDesignResourceListParams,
  GetRecentDesignResourceListParams,
  UserDesignResourceType,
} from '@miri-unicorn/miricanvas-api-client';

export const elementQueryKeys = {
  getElementResourceList: (params: GetDesignResourceListParams) => [
    'getElementResourceList',
    params,
  ],
  getRecentElementResourceList: (params: GetRecentDesignResourceListParams) => [
    'getRecentElementResourceList',
    params,
  ],
  getRecentElementResourceListDefault: () => ['getRecentElementResourceList'],
  getCollectionList: (collectionIdx: number) => [['elementCollectionDetail', collectionIdx]],
  getElementCollectionList: (collectionIdx: number) => ['elementCollectionList', collectionIdx],
  getSimilarElementListInfinite: (param: {
    resourceIdx: number;
    resourceType: DesignResourceType;
    searchTypeList: DesignResourceType[];
  }) => ['getSimilarElementListInfinite', param],
  getSimilarDesignResourceListByUserResource: (param: {
    resourceKey: string;
    resourceType: UserDesignResourceType;
    searchTypeList: DesignResourceType[];
  }) => ['getSimilarDesignResourceListByUserResource', param],
};
