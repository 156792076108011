import { getDeviceType } from '@configs/devices/device_util';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { leftPanelExpanedeWidthSnapshotValue } from '@ui/design/desktop/left_panel/_store/useLeftPanelExpandedWidthStore';
import { ActionDragLeftPanelResizeButtonReturnPayload } from '../types/ActionDragLeftPanelResizeButtonPayload';

export const makeGAParamsActionDragLeftPanelResizeButton: GAParameterMakerFnType<
  'ACTION_DRAG_LEFT_PANEL_RESIZE_BUTTON'
> = (params) => {
  const leftPanelExpandedWidth = leftPanelExpanedeWidthSnapshotValue();
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location3: `${leftPanelExpandedWidth}`,
    label1: '사이즈조절버튼',
    account_id: `${params.data.account_id}`,
    device_type: getDeviceType(),
  } satisfies ActionDragLeftPanelResizeButtonReturnPayload;
};
