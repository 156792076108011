const { LANGUAGE_TYPE_LIST } = require('@constants/LanguageType');

/** @type {import('next-i18next').UserConfig} */
const config = {
  /**
   * @see `src/configs/i18next/language_util.ts` LANGUAGE_TYPE_LIST
   * 바뀔시 같이 바뀌어야함
   */
  i18n: {
    defaultLocale: 'ko',
    locales: LANGUAGE_TYPE_LIST,
  },
  localePath: './resource/i18n',
  // useTranslation(ns?) 을 사용할때 ns 값이 없을때 shared로 자동으로 들어감.
  defaultNS: ['shared'],
  ns: ['editor', 'shared', 'workspace', 'homepage'],
  // keySeparator [기본 값: '.'] : key를 나눌 구분자 문자. flat 한 JSON으로 사용할 경우 false 값으로 설정해야 함
  keySeparator: false,
  interpolation: {
    prefix: '%{',
    suffix: '}',
  },
  // i18next.t() 함수의 키 위치에 "namespace:key" 와 같이 namespace 값과 key 값을 같이 사용할 경우, 사용할 separator
  // default 설정 값은 ":"(콜론) 이라 미리 정의되지 않은 "key" 값에서 ":"(콜론) 사용할 경우,
  // i18next.t() 함수의 리턴 값이 콜론 앞 부분이 제거되는 어색한 현상 발생하여 nsSeparator 값을 커스텀 함.
  nsSeparator: '%:%',
  react: {
    useSuspense: false,
  },
};

module.exports = config;
