import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickDesignShareResource: GAParameterMakerFnType<
  'ACTION_CLICK_DESIGN_SHARE_RESOURCE'
> = ({ data: { page, share_type } }) => {
  return {
    event: 'design_save_resource',
    log_type: 'design_save_resource',
    share_type,
    ...page,
    design_idx: `${page.design_idx}`,
    design_page_idx: `${page.design_page_idx}`,
  };
};
