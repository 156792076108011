import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { ActionSearchUploadItemPayload } from '../types/UploadPanelPayload';

type ActionSearchUploadItemParams = GAParam<ActionSearchUploadItemPayload>;

export const makeGAParamsActionSearchUploadItem: GAParameterMakerFnType<
  'ACTION_SEARCH_UPLOAD_ITEM'
> = (payload) => {
  return {
    event: 'search_upload',
    log_type: 'search_upload',
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
    search_status: payload.data.search_status,
    search_upload_type: payload.data.search_upload_type,
    search_term: payload.data.search_term,
  } satisfies ActionSearchUploadItemParams;
};
