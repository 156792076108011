import {
  GetDesignResourceListParams,
  GetRecentDesignResourceListParams,
} from '@miri-unicorn/miricanvas-api-client';

export const textElementQueryKeys = {
  getTextElementList: (params: GetDesignResourceListParams) =>
    ['getTextElementList', params] as const,
  getTextElementListInfinite: (params: Omit<GetDesignResourceListParams, 'page'>) =>
    ['getTextElementListInfinite', params] as const,
  getRecentTextElementListInfinite: (params: GetRecentDesignResourceListParams) =>
    ['getRecentTextElementListInfinite', params] as const,
  getRecentTextElementListInfiniteDefault: () => ['getRecentTextElementListInfinite'],
};
