import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { getChangeTemplateSizeButtonGAText } from '../utils/template_size_ga_util';

export const makeGAParamsActionClickChangeTemplateSizeButton: GAParameterMakerFnType<
  'ACTION_CLICK_CHANGE_TEMPLATE_SIZE_BUTTON'
> = (params) => {
  const changeTemplateSizeButtonType = getChangeTemplateSizeButtonGAText(params);

  const additionalParams =
    changeTemplateSizeButtonType === '이전크기로돌아갈래요'
      ? {
          location3: '페이지사이즈로적용',
          location4: `${changeTemplateSizeButtonType}`,
          label1: `디자인적용방식_페이지사이즈적용_${changeTemplateSizeButtonType}`,
        }
      : {
          location3: changeTemplateSizeButtonType,
          label1: `디자인적용방식_${changeTemplateSizeButtonType}`,
        };

  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '디자인 적용 방식 팝업',
    action1: '클릭',
    ...additionalParams,
  };
};
