import {
  GetDesignResourceListParams,
  GetRecentDesignResourceListParams,
} from '@miri-unicorn/miricanvas-api-client';

export const backgroundQueryKeys = {
  getBackgroundElementList: (params: GetDesignResourceListParams) =>
    ['getBackgroundElementList', params] as const,
  getBackgroundElementListInfinite: (params: Omit<GetDesignResourceListParams, 'page'>) =>
    ['getBackgroundElementListInfinite', params] as const,
  getBackgroundElementRecentListInfinite: (
    params: Omit<GetRecentDesignResourceListParams, 'page'>
  ) => ['getBackgroundElementRecentListInfinite', params] as const,
  getBackgroundElementRecentListInfiniteDefault: () => ['getBackgroundElementRecentListInfinite'],
};
