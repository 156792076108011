import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickSnapGuideToggleParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickSnapGuideToggle: GAParameterMakerFnType<
  'ACTION_CLICK_SNAP_GUIDE_TOGGLE'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '전체메뉴',
    location3: '안내선설정',
    location4: '스냅가이드보기',
    action1: '클릭',
    label1: '설정_에디터환경_스냅가이드',
  } satisfies ActionClickSnapGuideToggleParams;
};
