import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickAIPresentationEditOutline8PageButton: GAParameterMakerFnType<
  'ACTION_CLICK_AI_PRESENTATION_EDIT_OUTLINE_8PAGE_BUTTON'
> = () => ({
  log_type: 'generic_event_mican',
  event: 'gtm_trigger_event',
  location1: '에디터',
  location2: '프레젠테이션 자동 생성',
  location3: '개요 편집',
  location4: '페이지 8장',
  action1: '클릭',
  label1: '프레젠테이션 자동 생성_개요 편집_페이지 8장',
});
