import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import {
  ActionClickApplyThemeAllPageReturnPayload,
  ActionClickCurrentThemeInitializeButtonReturnPayload,
  ActionClickRecommendThemeMoreInfoButtonReturnPayload,
  ActionClickThemeSelectButtonReturnPayload,
  ActionClickThemeShuffleButtonReturnPayload,
  ApplyThemeType,
} from '../types/ThemePanelPayload';
import { getActionClickCurrentThemeInitializeButtonThemeTypeGAText } from '../utils/ThemeTypeGAText';

export const makeGAParamsActionSearchTheme: GAParameterMakerFnType<'ACTION_SEARCH_THEME_ITEM'> = (
  payload
) => {
  return {
    log_type: 'search_theme',
    event: 'search_theme',
    search_status: payload.data.search_status,
    search_term: payload.data.search_term,
    search_type: payload.data.search_type,
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
  };
};

export const makeGAParamsActionClickTheme: GAParameterMakerFnType<'ACTION_CLICK_THEME_ITEM'> = (
  payload
) => {
  return {
    log_type: 'action_theme',
    event: 'action_theme',
    action_item_type: payload.data.action_item_type,
    action_theme: payload.data.action_theme,
    action_theme_id: payload.data.action_theme_id,
    is_shuffle: payload.data.is_shuffle,
    priority: payload.data.priority,
    search_status: payload.data.search_status,
    search_term: payload.data.search_term,
    search_type: payload.data.search_type,
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
  };
};

export const makeGAParamsActionViewTheme: GAParameterMakerFnType<'ACTION_VIEW_THEME_ITEM'> = (
  payload
) => {
  return {
    log_type: 'view_theme',
    event: 'view_theme',
    search_status: payload.data.search_status,
    search_term: payload.data.search_term,
    search_type: payload.data.search_type,
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
    view_item_key: payload.data.view_item_key.join(', '),
    view_item_type: payload.data.view_item_type.join(', '),
    view_load_num: payload.data.view_load_num,
  };
};

export const makeGAParamsActionClickCurrentThemeInitializeButton: GAParameterMakerFnType<
  'ACTION_CLICK_CURRENT_THEME_INITIALIZE_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '테마',
    location3: '현재 색상 - 초기화',
    action1: '클릭',
    label1: '테마_현재색상초기화',
  } satisfies ActionClickCurrentThemeInitializeButtonReturnPayload;
};

export const makeGAParamsActionClickRecommendThemeMoreInfoButton: GAParameterMakerFnType<
  'ACTION_CLICK_RECOMMEND_THEME_MORE_INFO_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '테마',
    location3: '추천 테마색_더보기',
    action1: '클릭',
    label1: '테마_추천테마색더보기',
  } satisfies ActionClickRecommendThemeMoreInfoButtonReturnPayload;
};

export const makeGAParamsActionClickThemeShuffleButton: GAParameterMakerFnType<
  'ACTION_CLICK_THEME_SHUFFLE_BUTTON'
> = (params) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '테마',
    location3: `${getActionClickCurrentThemeInitializeButtonThemeTypeGAText(
      params.data.applyThemeType
    )}_셔플`,
    action1: '클릭',
    label1: `테마_${getActionClickCurrentThemeInitializeButtonThemeTypeGAText(
      params.data.applyThemeType
    ).replace(' ', '')}셔플`,
  } satisfies ActionClickThemeShuffleButtonReturnPayload;
};

export const makeGAParamsActionClickThemeSelectButton: GAParameterMakerFnType<
  'ACTION_CLICK_THEME_SELECT_BUTTON'
> = (params) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '테마',
    location3: `${getActionClickCurrentThemeInitializeButtonThemeTypeGAText(
      params.data.applyThemeType
    )}`,
    location4: '색상칩',
    action1: '클릭',
    label1: `테마_${getActionClickCurrentThemeInitializeButtonThemeTypeGAText(
      params.data.applyThemeType
    ).replace(' ', '')}_색상칩`,
  } satisfies ActionClickThemeSelectButtonReturnPayload;
};

export const makeGAParamsActionClickApplyThemeAllPage: GAParameterMakerFnType<
  'ACTION_CLICK_APPLY_THEME_ALL_PAGE'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '테마',
    location3: '모든 페이지에 적용',
    action1: '클릭',
    label1: '테마_모든페이지에적용',
  } satisfies ActionClickApplyThemeAllPageReturnPayload;
};
