const DEVICE_TYPE_LIST = ['desktop', 'mobile', 'tablet', 'unknown'] as const;

export type DeviceType = (typeof DEVICE_TYPE_LIST)[number];

export const DeviceTypeEnum = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
  TABLET: 'tablet',
  UNKNOWN: 'unknown',
} as const satisfies Record<Uppercase<DeviceType>, DeviceType>;
