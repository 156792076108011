import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { TextPresetType } from '@ui/design/_common/design_resource_panel/text/style/add_style/_constants/font';
import { ActionClickAddTextButtonReturnParam } from '../types/ActionClickAddTextButtonPayload';

export const makeGAParamsActionClickAddTextButton: GAParameterMakerFnType<
  'ACTION_CLICK_ADD_TEXT_BUTTON'
> = (payload) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    action1: '클릭',
    label1: getTextTypeToGAParam(payload.data.text_type),
    account_id: payload.data.account_id?.toString(),
    device_type: payload.data.device_type,
  } satisfies ActionClickAddTextButtonReturnParam;
};

export const getTextTypeToGAParam = (textType?: TextPresetType) => {
  switch (textType) {
    case 'TITLE1':
      return '제목텍스트';
    case 'TITLE2':
      return '부제목텍스트';
    case 'BODY':
      return '본문텍스트';
    default:
      return '';
  }
};
