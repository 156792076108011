import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import {
  GTMTriggerEventParams,
  GTMUserParams,
} from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionResizePrintoutTemplateSize = GAParam<GTMTriggerEventParams & GTMUserParams>;
export const makeGAParamsActionResizePrintoutTemplateSize: GAParameterMakerFnType<
  'ACTION_RESIZE_PRINTOUT_TEMPLATE_SIZE'
> = (payload) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '인쇄물제작하기',
    location2: '3뎁스',
    location3: '크기조정',
    action1: '뷰',
    label1: payload.data.template_type_id,
    account_id: payload.data.account_id,
  } satisfies ActionResizePrintoutTemplateSize;
};
