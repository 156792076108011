import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';
import { ActionClickHeaderBarDownloadButtonReturnPayload } from '../types/ActionClickHeaderBarDownloadButtonPayload';
import { ActionClickHeaderBarLoginButtonReturnPayload } from '../types/ActionClickHeaderBarLoginButtonPayload';

type ActionClickSlideShowButton = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickSlideShowButton: GAParameterMakerFnType<
  'ACTION_CLICK_SLIDE_SHOW_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '상단바',
    location3: '슬라이드쇼',
    action1: '클릭',
    label1: '상단바_슬라이드쇼',
  } satisfies ActionClickSlideShowButton;
};

export const makeGAParamsActionClickHeaderBarDownloadButton: GAParameterMakerFnType<
  'ACTION_CLICK_HEADER_BAR_DOWNLOAD_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '상단바',
    location3: '다운로드',
    action1: '클릭',
    label1: '상단바_다운로드',
  } satisfies ActionClickHeaderBarDownloadButtonReturnPayload;
};

export const makeGAParamsActionClickHeaderBarLoginButton: GAParameterMakerFnType<
  'ACTION_CLICK_HEADER_BAR_LOGIN_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '상단바',
    location3: '로그인',
    action1: '클릭',
    label1: '에디터_상단바_로그인',
  } satisfies ActionClickHeaderBarLoginButtonReturnPayload;
};
