/** 검색 ~~  */

/** 공통으로 쓰이는 SearchCase */
const CommonSearchCase = {
  /**  키워드를 검색바에 직접 입력하는 케이스  */
  MANUAL: '직접입력',

  /**  검색바의 추천키워드 클릭해서 검색하는 케이스  */
  RECOMMENDED: '추천키워드',

  /**  검색바의 최근검색어 클릭해서 검색하는 케이스  */
  RECENT: '최근검색어',

  /**  리스트에서 더보기를 눌렀을 때 키워드가 검색바에 세팅되는 케이스  */
  MORE: '더보기',

  /**  개별 템플릿이나 요소에 포함된 키워드를 클릭하여 검색바가 세팅되는 케이스  */
  INCLUDED_KEYWORD: '리소스할당키워드',

  /**  검색바에 검색어를 입력한 상태에서 하단에 뜨는 연관검색어를 클릭해서 검색하는 케이스  */
  RELATED: '연관검색어',

  /** 템플릿 용 - 비슷한 템플릿 목록이 보여질 경우 보내는 케이스 */
  SIMILAR_TEMPLATE: '비슷한템플릿찾기',

  /**  위에 해당되지 않는 케이스  */
  ETC: '기타',
} as const;

/** 디자인 리소스 패널 서브페이지(2뎁스)용 search case*/
export const LeftPanelSubPageSearchCase = {
  /**  비슷한 템플릿이나 요소 보기 등을 클릭하여 검색바가 세팅되는 케이스  */
  SIMILAR_RESOURCE: '비슷한리소스찾기',

  /**  비슷한 리소스 미리보기 > 더보기 클릭하여 2뎁스 열리는 케이스  */
  SIMILAR_RESOURCE_MORE: '비슷한리소스찾기더보기',
} as const;

/** LeftPanel에서만 쓰이는 SearchCase */
export const LeftPanelSearchCase = {
  ...CommonSearchCase,
  ...LeftPanelSubPageSearchCase,

  /**  리소스 정보에 있는 출처를 눌러 검색하는 케이스 (UNICORN-21120)  */
  SOURCE: '출처',

  /**  리소스 정보에 있는 크리에이터를 눌러 검색하는 케이스 (UNICORN-21120)  */
  CREATOR: '크리에이터',

  /** 챗봇 */
  CHATBOT: '챗봇',

  /**  비슷한 리소스 미리보기 UI가 열리는 케이스  */
  SIMILAR_RESOURCE_PREVIEW: '비슷한리소스찾기미리보기',
} as const;

/** TemplateWorkspace에서만 쓰이는 SearchCase */
export const TemplateWorkspaceSearchCase = {
  ...CommonSearchCase,

  /**
   * 검색어에 대한 연관 검색어를 클릭해서 검색하는 케이스
   * 위 RELATED와 차이는?
   * RELATED(/api/search/recommendation에서 가져옴) - 자동완성 키워드 ex) '새해' -> '새해인사', '새해복'
   * LINK_KEYWORD(api/search/related-terms에서 가져옴) - 연관 키워드 ex) '녹색' -> '청록색', '그린', '푸르른 녹색'
   */
  LINK_KEYWORD: '연계검색어',
} as const;

/** 좌패널 GA 보낼때 이용 */
export const SearchActionStatus = {
  /** 목록에서 탬플릿을 클릭했을 때  */
  VIEW: '뷰',
  /** SearchType 의 검색들  */
  SEARCH: '검색',
} as const;

export type LeftPanelSearchCaseType<T extends object = object> = ValueOf<
  typeof LeftPanelSearchCase & T
>;
export type LeftPanelSubpageSearchCaseType = ValueOf<typeof LeftPanelSubPageSearchCase>;
export const isSubPageSearchCase = (
  searchCase: LeftPanelSearchCaseType
): searchCase is LeftPanelSubpageSearchCaseType =>
  Object.values(LeftPanelSubPageSearchCase).includes(searchCase);

export type TemplateWorkspaceSearchType = ValueOf<typeof TemplateWorkspaceSearchCase>;
export type SearchActionStatusType = ValueOf<typeof SearchActionStatus>;

/**
 * 검색어를 변경하지 않고, 뷰만 변경되는 SearchCase
 * TODO: 추후 ItemSearchCase 에서 제외하고, 별도로 관리하는 방법을 찾아보기
 */
export type ShowOnlyLeftPanelSearchCaseType = ValueOf<
  Pick<typeof LeftPanelSearchCase, 'CHATBOT' | 'SIMILAR_TEMPLATE'>
>;
