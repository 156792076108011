import {
  GetRecentDesignResourceListParams,
  getDesignResourceList,
} from '@miri-unicorn/miricanvas-api-client';

export type DesignResourceListParmas = Parameters<typeof getDesignResourceList>[0];

export const pictureElementQueryKeys = {
  getPictureElementList: (params: DesignResourceListParmas) =>
    ['getPictureElementList', params] as const,
  getRecentPictureElementList: (params: GetRecentDesignResourceListParams) =>
    ['getRecentPictureElementList', params] as const,
  getRecentPictureElementListDefault: () => ['getRecentPictureElementList'],
  getPictureElementRecentListInfinite: (params: Omit<GetRecentDesignResourceListParams, 'page'>) =>
    ['getPictureElementRecentListInfinite', params] as const,
};
