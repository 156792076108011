import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import {
  GTMTriggerEventParams,
  GTMUserParams,
} from '@configs/google_analytics/types/GTMTriggerEventParams';

type GAParams = GAParam<GTMTriggerEventParams> & GTMUserParams;

export const makeGAParamsActionClickCalendar: GAParameterMakerFnType<'ACTION_CLICK_CALENDAR'> = (
  payload
) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '요소메뉴',
    location2: '옵션',
    action1: '클릭',
    label1: '캘린더',
    account_id: `${payload.data.accountId}`,
    device_type: payload.data.deviceType,
  } satisfies GAParams;
};

export const makeGAParamsActionApplyCalendar: GAParameterMakerFnType<
  'ACTION_CLICK_APPLY_CALENDAR'
> = (payload) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '요소메뉴',
    location2: '옵션',
    action1: '클릭',
    label1: '캘린더_적용',
    label2: `${payload.data.date}`,
    label3: `${payload.data.holiday ? '공휴일, 기념일 채우기' : '공휴일, 기념일 채우지 않기'}`,
    account_id: `${payload.data.accountId}`,
    device_type: payload.data.deviceType,
  } satisfies GAParams;
};
