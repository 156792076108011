import { Tier } from '@miri-unicorn/miricanvas-api-client';

export const isFreeDesignResource = (tier: Tier) => {
  return tier === 'FREE';
};

export const isPremiumDesignResource = (tier?: Tier) => {
  return tier === 'PREMIUM';
};

const TierKorMap: Record<Tier, string> = {
  FREE: '무료',
  PREMIUM: '유료',
};

export const ALL_TIER_LIST: Tier[] = Object.values(Tier);

export const getTierKor = (tier: Tier): string => {
  return TierKorMap[tier];
};

export const isTier = (tier: string | undefined): tier is Tier => {
  if (!tier) {
    return false;
  }
  return tier in Tier;
};

export const getFilterTierList = (tierList?: Tier[]) => {
  return !tierList?.length || tierList?.length === ALL_TIER_LIST.length ? undefined : [...tierList];
};
