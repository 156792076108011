import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { UpgradePopupFlowStep } from '../types/UpgradePopupPayload';

export const makeGAParamsChangeAnimationDirectionUpgradePopup: GAParameterMakerFnType<
  'ACTION_CHANGE_ANIMATION_DIRECTION_UPGRADE_POPUP'
> = (payload) => {
  return {
    event: 'purchase_flow_develop',
    log_type: 'purchase_flow_develop',
    flow_name1: payload.data.flow_name1,
    flow_name2: payload.data.flow_name2,
    flow_type: UpgradePopupFlowStep[payload.data.flow_type],
    action_type: payload.data.action_type,
    team_idx: payload.data.team_idx.toString(),
    billing_cycle: payload.data.billing_cycle,
    plan_type: payload.data.plan_type,
    currency: payload.data.currency,
    payment_orders_idx: payload.data.payment_orders_idx?.toString(),
    account_id: payload.data.account_id.toString(),
  };
};
