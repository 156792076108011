import { INITIAL_LEFT_PANEL_WIDTH } from '@ui/design/desktop/left_panel/handle/constants/left_panel_size_constants';
import { useViewportWidth } from '@utils/hooks/viewport/useViewportWidth';

export const useMaxLeftPanelWidth = () => {
  return useViewportWidth(getPanelMaxWidth) ?? INITIAL_LEFT_PANEL_WIDTH;
};

/**
 * 최대 패널 너비
 * * 아래 셋 중 가장 작은 값을 사용함
 * 1. 60vw
 * 2. 1320px
 * 3. 100vw - 440px (우측 캔버스 영역 최소 너비)
 * 단, 패널 최소 너비보다 큰 값을 사용 함
 */
const getPanelMaxWidth = (viewportWidth: number) => {
  return Math.max(
    Math.min(viewportWidth * 0.6, 1320, viewportWidth - 440),
    INITIAL_LEFT_PANEL_WIDTH
  );
};
