import { EventPayloadDataRegistry } from '../_types/EventPayloadDataRegistry';
import { EventType } from '../_types/EventType';
import { eventSubject } from '../eventSubject';

export const useEventEmitter = () => {
  return {
    emit: emitEvent,
  };
};

export const emitEvent = <
  EVENT_TYPE extends EventType,
  PAYLOAD_DATA extends EventPayloadDataRegistry[EVENT_TYPE]
>(
  type: EVENT_TYPE,
  payloadData: PAYLOAD_DATA
) => {
  eventSubject.next({
    type,
    payload: {
      data: payloadData,
    },
  });
};
