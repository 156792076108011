import { DependencyList, EffectCallback, useEffect } from 'react';
import { useIsFirstRender } from '@utils/hooks/useIsFirstRender';

/**
 * ComponentDidMount 과정을 생략한 useEffect(첫 렌더시 실행 x)
 * ComponentDidUpdate 대체제
 */
export const useUpdateEffect = (effect: EffectCallback, deps?: DependencyList) => {
  const isFirst = useIsFirstRender();

  useEffect(() => {
    if (!isFirst) {
      return effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
