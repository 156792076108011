import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';
import { GAParam } from '@configs/google_analytics/types/GACommonTypes';

type ActionClickAppHomeMoreTemplateButtonParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickAppHomeMoreTemplateButton: GAParameterMakerFnType<
  'ACTION_CLICK_APP_HOME_MORE_TEMPLATE_BUTTON'
> = (params) => {
  return {
    log_type: 'generic_event_mican',
    event: 'gtm_trigger_event',
    location1: '홈화면',
    location2: '키워드카테고리',
    location3: '더보기',
    action1: '클릭',
    label1: params.data.keyword_category,
  } satisfies ActionClickAppHomeMoreTemplateButtonParams;
};
