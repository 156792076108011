import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import {
  GTMTriggerEventParams,
  GTMUserParams,
} from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickDownloadModalPrintoutMakeButton = GAParam<GTMTriggerEventParams & GTMUserParams>;

export const makeGAParamsActionClickDownloadModalMakePrintButton: GAParameterMakerFnType<
  'ACTION_CLICK_DOWNLOAD_MODAL_MAKE_PRINT_BUTTON'
> = (payload) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '상단바',
    location3: '다운로드',
    location4: '인쇄물제작하기버튼',
    action1: '클릭',
    label1: '상단바_다운로드_인쇄물제작버튼_클릭',
    label2: payload.data.product_name,
    label3: payload.data.has_linked_bizhows_account,
    label4: payload.data.current_plan,
    account_id: payload.data.account_id,
  } satisfies ActionClickDownloadModalPrintoutMakeButton;
};
