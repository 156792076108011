import { useCallback, useMemo } from 'react';
import { Plan } from '@miri-unicorn/miricanvas-api-client';
import { DEFAULT_USER } from '@business/user/_constants/user';
import { useUserQuery } from '@business/user/_queries/useUserQuery';
import { useUserMutation } from '@business/user/_queries/useUserMutation';
import { useSelectedTeamIdx } from '@business/user/_services/_hooks/useSelectedTeamIdx';
import { isFreePlan } from '@business/user/_services/_pures/userPlanUtil';
import { getIsLogin } from '@business/user/_utils/getIsLogin';
import { isKoreanLanguageSet } from '@configs/i18next/language_util';
import { useQueryClient } from '@configs/react-query';
import { queryKeys } from '@configs/react-query/queryKeys';

export const useCurrentUser = () => {
  const { data, isError, isLoading, isFetching, isRefetching } = useUserQuery();
  const { loginUserMutation } = useUserMutation();
  const { mutateAsync } = loginUserMutation;
  const queryClient = useQueryClient();

  const user = useMemo(() => {
    // 로그인 api 에러 발생 시, api 무한호출 되는 현상이 있어서, default user return
    if (isError || !data) {
      return DEFAULT_USER;
    }
    return data?.data;
  }, [data, isError]);

  const selectedTeamIdx = useSelectedTeamIdx();
  const isFirstLogin = useMemo(() => user?.isFirstLogin, [user]);
  const userIpCountryCode = useMemo(() => user?.ipCountryCode, [user]);
  const selectedTeam = useMemo(
    () => user?.teamList.find((team) => team.idx === selectedTeamIdx),
    [selectedTeamIdx, user?.teamList]
  );
  const currentPlan = useMemo((): Plan => {
    return user?.teamList.find((team) => team.idx === selectedTeamIdx)?.plan || 'FREE';
  }, [selectedTeamIdx, user?.teamList]);

  const signUpDate = useMemo(() => data?.data.signUpDate, [data]);

  const isLoadingUser = isLoading || isFetching || isRefetching;

  const checkUserAuthStatus = useCallback(async () => {
    const user = await mutateAsync({ loginAutomatically: true });
    return user.data.authStatus !== 'NOT_CONFIRMED';
  }, [mutateAsync]);

  const getIsLoginAsync = useCallback(async () => {
    const user = await mutateAsync({ loginAutomatically: true });
    queryClient.setQueryData(queryKeys.getUser(), user);

    return user.data.accountId !== 0;
  }, [mutateAsync, queryClient]);

  /**
   * 유저의 워크스페이스 중에 무료 워크스페이스의 idx를 반환
   * 선택된 워크스페이스가 무료인 경우 -> 선택된 워크스페이스의 idx
   * 선택된 워크스페이스가 뮤료가 아닌 경우 -> 유저의 워크스페이스를 순회하며 배열 순서상 첫번째로 무료인 워크스페이스 idx
   */
  const getFreeTeamIdx = useCallback((): number | undefined => {
    if (user?.teamList?.some((_team) => _team.idx === selectedTeamIdx)) {
      return selectedTeamIdx ? Number(selectedTeamIdx) : undefined;
    }

    return user?.teamList?.find((_team) => isFreePlan(_team.plan))?.idx;
  }, [selectedTeamIdx, user?.teamList]);

  // 연동된 비즈하우스 계정 존재 여부
  const hasLinkedBizhowsAccount = useMemo(
    () =>
      user?.myAuthInfoList.some(
        (authInfo) => authInfo.authStatus === 'CONFIRMED' && authInfo.auth === 'BIZHOWS_MICAN'
      ) ?? false,
    [user?.myAuthInfoList]
  );

  return {
    isLogin: getIsLogin(user),
    isAdmin: user?.admin,
    // ! selectedTeamIdx만 필요한 경우 useSelectedTeamIdx 사용할 것
    selectedTeamIdx: Boolean(selectedTeamIdx) ? selectedTeamIdx : undefined,
    selectedTeam,
    userName: user?.name,
    userEmail: user?.email,
    teamList: user?.teamList,
    imageUploadSizeLimit: user?.imageUploadSizeLimit,
    animatedUploadSizeLimit: user?.animatedUploadSizeLimit,
    videoUploadSizeLimit: user?.videoUploadSizeLimit,
    svgUploadSizeLimit: user?.svgUploadSizeLimit,
    totalUploadSizeLimit: user?.totalUploadSizeLimit,
    currentPlan,
    isKoreanUser: user?.ipCurrency === 'KRW' || isKoreanLanguageSet(),
    user,
    checkUserAuthStatus,
    isAuthConfirmed: user?.authStatus !== 'NOT_CONFIRMED',
    userUuid: user?.uuid,
    getFreeTeamIdx,
    getIsLoginAsync,
    isLoadingUser,
    userLanguage: user?.languageSetting || '',
    signUpDate,
    isFirstLogin,
    userIpCountryCode,
    hasLinkedBizhowsAccount,
  };
};
