import { GetKeywordCategoryPreviewTemplateParams } from '@miri-unicorn/miricanvas-api-client';
import { UseKeywordCategoryListQueryParams } from '@business/design_resource/common/contents_category/keyword_category/_queries/useKeywordCategoryQuery';
import { LanguageType } from '@constants/LanguageType';

export const keywordCategoryQueryKeys = {
  getKeywordCategoryList: (params: UseKeywordCategoryListQueryParams, languageType: LanguageType) =>
    ['getKeywordCategoryList', params, languageType] as const,
  getKeywordCategoryPreviewTemplate: (params: GetKeywordCategoryPreviewTemplateParams) =>
    ['getKeywordCategoryPreviewTemplate', params] as const,
};
