import { atom, useAtom } from 'jotai';
import { useMemo } from 'react';
import { getRootStore } from '@configs/jotai/RootProvider';
import { useMaxLeftPanelWidth } from '@ui/design/desktop/left_panel/_hooks/useMaxLeftPanelWidth';
import {
  INITIAL_LEFT_PANEL_WIDTH,
  LEFT_PANEL_MIN_WIDTH,
} from '@ui/design/desktop/left_panel/handle/constants/left_panel_size_constants';
import { throttle } from '@utils/functions/lodash_util';
import { clamp } from '@utils/functions/math_util';

const leftPanelExpandedWidthAtom = atom<number>(INITIAL_LEFT_PANEL_WIDTH);

/**
 * 좌패널 너비 상태를 관리하는 스토어
 *
 */
export const useLeftPanelExpandedWidthStore = () => {
  const maxPanelWidth = useMaxLeftPanelWidth();
  const [leftPanelExpandedWidth, setLeftPanelExpandedWidth] = useAtom(leftPanelExpandedWidthAtom);

  const throttled = useMemo(
    () =>
      throttle((width) => {
        setLeftPanelExpandedWidth(clamp(width, LEFT_PANEL_MIN_WIDTH, maxPanelWidth));
      }, 100),
    [maxPanelWidth, setLeftPanelExpandedWidth]
  );

  return {
    leftPanelExpandedWidth,
    setLeftPanelExpandedWidth: throttled,
  };
};

export const leftPanelExpanedeWidthSnapshotValue = () =>
  getRootStore().get(leftPanelExpandedWidthAtom);
