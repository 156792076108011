import { useEffect } from 'react';
import { useCurrentUser } from '@business/user/_services/_hooks/useCurrentUser';
import { DISABLED_VARIABLE } from '@configs/ab-test/_constants/abVariables';
import { AbValue } from '@configs/ab-test/_types/type';
import { emitEvent } from '@event/_hooks/useEventEmitter';

type CollectAbGtagProps = {
  abData: AbValue | null;
};

export const CollectAbGtag = ({ abData }: CollectAbGtagProps) => {
  const { isLoadingUser, user } = useCurrentUser();

  useEffect(() => {
    if (!isLoadingUser && abData) {
      Object.entries(abData).forEach(([flagKey, variation]) => {
        if (!variation?.variationKey) return;
        if (variation?.variationKey === DISABLED_VARIABLE) return;

        emitEvent('ACTION_BY_AB_TEST', {
          accountId: Boolean(user?.accountId) ? user?.accountId.toString() : undefined,
          variation: variation,
          flagKey: flagKey,
        });
      });
    }
  }, [abData, isLoadingUser, user?.accountId]);

  return <></>;
};
