import { DesignResourceType } from '@miri-unicorn/miricanvas-api-client';
import { ElementTabKey } from '@business/design_resource_bar/tab/_constants/elementTabType';

export const makeElementFileTypeToKorean = (
  tabKey: ElementTabKey,
  typeList: Array<DesignResourceType>
) => {
  // 요소 패널에서 선택된 탭이 일러스트가 아닐경우 -> 파일 타입 선택 못하므로 빈 스트링
  if (tabKey !== 'ILLUSTRATION') {
    return undefined;
  }

  // 파일 타입이 선택되어 있을 경우 -> typeList의 length는 1이기 때문에 빈 스트링
  if (typeList.length !== 1) {
    return undefined;
  }

  if (typeList[0] === DesignResourceType.ILLUST) {
    return '벡터';
  }

  if (typeList[0] === DesignResourceType.BITMAP) {
    return '비트맵';
  }

  return undefined;
};
