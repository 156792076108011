import { GetExternalResourceParams } from '@miri-unicorn/miricanvas-api-client';
import { getQueryKey } from '@configs/react-query/queryKeys';
import { UseDownloadExternalResourceParams } from './useDownloadExternalResourceQuery';

export const downloadExternalResourceQueryKeys = {
  downloadExternalResource: (params: UseDownloadExternalResourceParams) =>
    getQueryKey('downloadExternalResource', params),
  externalResourceDetail: (params: GetExternalResourceParams) =>
    getQueryKey('externalResourceDetail', params),
};
