import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAActionClickBackgroundColorChipButton: GAParameterMakerFnType<
  'ACTION_CLICK_BACKGROUND_COLOR_CHIP_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '배경',
    location3: '단색',
    location4: '색상칩',
    action1: '클릭',
    label1: '배경_단색_색상칩',
  };
};

export const makeGAActionClickBackgroundPictureTabButton: GAParameterMakerFnType<
  'ACTION_CLICK_BACKGROUND_PICTURE_TAB_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '배경',
    location3: '사진',
    action1: '클릭',
    label1: '배경_사진',
  };
};

export const makeGAActionClickBackgroundPatternTabButton: GAParameterMakerFnType<
  'ACTION_CLICK_BACKGROUND_PATTERN_TAB_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '배경',
    location3: '패턴',
    action1: '클릭',
    label1: '배경_패턴',
  };
};

export const makeGAActionClickBackgroundEditButton: GAParameterMakerFnType<
  'ACTION_CLICK_BACKGROUND_EDIT_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '배경',
    location3: '배경',
    location4: '배경편집',
    action1: '클릭',
    label1: '배경_배경_배경편집',
  };
};
