import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickFontFilter = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionEyeDropper: GAParameterMakerFnType<
  'ACTION_CLICK_EYE_DROPPER'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '색상팝업',
    location3: '스포이드',
    action1: '클릭',
    label1: '색상팝업_스포이드',
  } satisfies ActionClickFontFilter;
};
