import { V2_BASE_PATH } from '@configs/environments/base_url';
import { LogUtil } from '@utils/functions/log_util';
import { useIsomorphicLayoutEffect } from '@utils/hooks';

const SW_JS_PATH = `${V2_BASE_PATH}/sw.js`;

export const useServiceWorkerInitializer = () => {
  useIsomorphicLayoutEffect(() => {
    if (!('serviceWorker' in navigator)) {
      return;
    }

    window.addEventListener('load', registerServiceWorker);
    navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);

    return () => {
      window.removeEventListener('load', registerServiceWorker);
      navigator.serviceWorker?.removeEventListener('message', handleServiceWorkerMessage);
    };
  }, []);
};

const handleServiceWorkerMessage = (event: MessageEvent) => {
  if (event.data && event.data.type === 'API_REQUEST_LIMIT_EXCEEDED') {
    const url = event.data.payload.url;
    LogUtil.warn.dev('API_REQUEST_LIMIT_EXCEEDED: ', url);
    alert(`비정상적으로 많이 호출되는 API 감지\n${url}`);
  }
};

const registerServiceWorker = () => {
  navigator.serviceWorker
    .register(SW_JS_PATH)
    .then((registration) => {
      LogUtil.info.dev('Service Worker 등록 성공:', registration.scope);
    })
    .catch((registrationError) => {
      LogUtil.info.dev('Service Worker 등록 실패:', registrationError);
    });
};
