import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionDetailTemplateWorkspace: GAParameterMakerFnType<
  'ACTION_DETAIL_TEMPLATE_WORKSPACE'
> = (payload) => {
  return {
    log_type: 'detail_template_workspace',
    event: 'detail_template_workspace',
    search_status: payload.data.search_status,
    action_item: payload.data.action_item?.toString(),
    search_type: payload.data.search_type,
    search_template_type: payload.data.search_template_type,
    search_term: payload.data.search_term,
    priority: payload.data.priority?.toString(),
    priority2: payload.data.priority2?.toString(),
    source_term: payload.data.source_term,
    search_keyword_category: payload.data.search_keyword_category,
    team_idx: payload.data.team_idx?.toString(),
    plan_type: payload.data.plan_type,
    account_id: payload.data.account_id?.toString(),
    beginning_template: payload.data.beginning_template,
  };
};
