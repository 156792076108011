import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type GAParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickPhotoExtraEditToolButton: GAParameterMakerFnType<
  'ACTION_CLICK_PHOTO_EXTRA_EDIT_TOOL_BUTTON'
> = (payload) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    label1: payload.data.tool_name,
    ...(payload.data.tool_sub_name && { label2: payload.data.tool_sub_name }),
    location1: '에디터',
    location2: '편집메뉴',
    location3: '사진',
    location4: '쉬운편집',
    action1: '클릭',
    account_id: payload.data.account_id,
    action4: payload.data.plan_type,
  } satisfies GAParams;
};
