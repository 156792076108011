import {
  GetDesignResourceListParams,
  GetRecentDesignResourceListParams,
} from '@miri-unicorn/miricanvas-api-client';
import {
  ExternalSource,
  InternalSource,
} from '@business/design_resource/_types/designResourceInfiniteTypes';

export const designResourceQueryKeys = {
  getInternalDesignResourceListInfinite: (
    params: GetDesignResourceListParams,
    internalSourceList: InternalSource[]
  ) => ['getInternalDesignResourceListInfinite', params, internalSourceList],
  getExternalDesignResourceListInfinite: (
    params: GetDesignResourceListParams,
    externalSourceList: ExternalSource[]
  ) => ['getExternalDesignResourceListInfinite', params, externalSourceList],
  getRecentDesignResourceListInfinite: (params: Omit<GetRecentDesignResourceListParams, 'page'>) =>
    ['getRecentDesignResourceListInfinite', params] as const,
  getRecentDesignResourceListInfiniteDefault: () => ['getRecentDesignResourceListInfinite'],
};
