import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAparamsActionClickTemplateSearchPagePriceFilterPremium: GAParameterMakerFnType<
  'ACTION_CLICK_TEMPLATE_SEARCH_PAGE_PRICE_FILTER_PREMIUM'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '템플릿페이지',
    location2: '템플릿검색결과',
    location3: '유료무료필터',
    location4: '유료',
    action1: '클릭',
    label1: '템플릿페이지_검색결과_유료무료필터_유료_클릭',
  };
};
