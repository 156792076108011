import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickUploadItemDownloadButtonParams = GAParam<GTMTriggerEventParams>;
export const makeGAParamsActionClickUploadItemDownloadButton: GAParameterMakerFnType<
  'ACTION_CLICK_UPLOAD_ITEM_DOWNLOAD_BUTTON'
> = (payload) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '편집메뉴',
    location3: '업로드',
    location4: '정보더보기',
    action1: '클릭',
    action2: undefined,
    action3: undefined,
    action4: undefined,
    label1: '원본다운로드',
    label2: payload.data.label2,
    label3: undefined,
    label4: undefined,
    account_id: payload.data.account_id,
    device_type: undefined,
  } satisfies ActionClickUploadItemDownloadButtonParams;
};
