import { ApplyThemeType } from '../types/ThemePanelPayload';

export const getActionClickCurrentThemeInitializeButtonThemeTypeGAText = (
  applyThemeType: ApplyThemeType
) => {
  switch (applyThemeType) {
    case 'CurrentTheme':
      return '현재 색상';
    case 'AllTheme':
      return '테마 색상';
    case 'PhotoTheme':
      return '추천 테마색';
    case 'BrandTheme':
      return '브랜드 테마색';
    case 'SearchTheme':
      return '검색 테마색';
  }
};
