import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionSearchTemplateRecommendChatBotPayload: GAParameterMakerFnType<
  'ACTION_SEARCH_TEMPLATE_RECOMMEND_CHATBOT'
> = (payload) => ({
  log_type: 'search_template_chatbot',
  event: 'search_template_chatbot',
  conversation_id: payload.data.conversation_id,
  account_id: payload.data.account_id,
  team_idx: payload.data.team_idx,
  design_idx: payload.data.design_idx,
  plan_type: payload.data.plan_type,
  last_search_term: payload.data.last_search_term,
  preferred_language: payload.data.preferred_language,
  inflow_type: payload.data.inflow_type,
});
