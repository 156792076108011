import { useRef } from 'react';

/**
 * 첫 렌더일때만 true, 이후에 false
 * 첫 렌더링을 판별할 때 사용
 */
export const useIsFirstRender = (): boolean => {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
};
