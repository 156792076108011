import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionAppHome = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionAppHomeView: GAParameterMakerFnType<'ACTION_VIEW_APP_HOME'> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '홈화면',
    action1: '뷰',
  } satisfies ActionAppHome;
};

export const makeGAParamsActionAppHomeBottomTab: GAParameterMakerFnType<
  'ACTION_CLICK_APP_HOME_BOTTOM_TAB'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '홈화면',
    location2: '네비게이션',
    location3: '둘러보기',
    action1: '클릭',
  } satisfies ActionAppHome;
};

export const makeGAParamsActionAppHomeWorkspaceBottomTab: GAParameterMakerFnType<
  'ACTION_CLICK_APP_HOME_WORKSPACE_BOTTOM_TAB'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '홈화면',
    location2: '네비게이션',
    location3: '작업공간',
    action1: '클릭',
  } satisfies ActionAppHome;
};

export const makeGAParamsActionAppHomeCardNews: GAParameterMakerFnType<
  'ACTION_CLICK_APP_HOME_CARD_NEWS'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '홈화면',
    location2: '디자인타입',
    location3: '카드뉴스',
    action1: '클릭',
  } satisfies ActionAppHome;
};

export const makeGAParamsActionAppHomeDetailPage: GAParameterMakerFnType<
  'ACTION_CLICK_APP_HOME_DETAIL_PAGE'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '홈화면',
    location2: '디자인타입',
    location3: '상세페이지',
    action1: '클릭',
  } satisfies ActionAppHome;
};

export const makeGAParamsActionAppHomePresentation: GAParameterMakerFnType<
  'ACTION_CLICK_APP_HOME_PRESENTATION'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '홈화면',
    location2: '디자인타입',
    location3: '프레젠테이션',
    action1: '클릭',
  } satisfies ActionAppHome;
};

export const makeGAParamsActionAppHomeYoutubeThumbnail: GAParameterMakerFnType<
  'ACTION_CLICK_APP_HOME_YOUTUBE_THUMBNAIL'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '홈화면',
    location2: '디자인타입',
    location3: '유튜브썸네일',
    action1: '클릭',
  } satisfies ActionAppHome;
};

export const makeGAParamsActionAppHomeSnsSquare: GAParameterMakerFnType<
  'ACTION_CLICK_APP_HOME_SNS_SQUARE'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '홈화면',
    location2: '디자인타입',
    location3: '소셜미디어정사각형',
    action1: '클릭',
  } satisfies ActionAppHome;
};

export const makeGAParamsActionAppHomeWebPosterLandscape: GAParameterMakerFnType<
  'ACTION_CLICK_APP_HOME_WEB_POSTER_LANDSCAPE'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '홈화면',
    location2: '디자인타입',
    location3: '웹포스터가로형',
    action1: '클릭',
  } satisfies ActionAppHome;
};
export const makeGAParamsActionAppHomeVideo: GAParameterMakerFnType<
  'ACTION_CLICK_APP_HOME_VIDEO'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '홈화면',
    location2: '디자인타입',
    location3: '동영상',
    action1: '클릭',
  } satisfies ActionAppHome;
};
export const makeGAParamsActionAppHomeMore: GAParameterMakerFnType<
  'ACTION_CLICK_APP_HOME_MORE'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '홈화면',
    location2: '디자인타입',
    location3: '더보기',
    action1: '클릭',
  } satisfies ActionAppHome;
};

export const makeGAParamsActionAppHomeStartEmpty: GAParameterMakerFnType<
  'ACTION_CLICK_APP_HOME_START_EMPTY'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '홈화면',
    location2: '빈디자인으로시작',
    action1: '클릭',
  } satisfies ActionAppHome;
};

export const makeGAParamsActionAppHomeStartEmptySelectType: GAParameterMakerFnType<
  'ACTION_CLICK_APP_HOME_START_EMPTY_SELECT_TYPE'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '홈화면',
    location2: '빈디자인으로시작',
    location3: '타입선택',
    action1: '클릭',
  } satisfies ActionAppHome;
};

export const makeGAParamsActionAppHomeSearch: GAParameterMakerFnType<
  'ACTION_CLICK_APP_HOME_SEARCH'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '홈화면',
    location2: '검색',
    action1: '클릭',
  } satisfies ActionAppHome;
};

export const makeGAParamsActionAppEditorCrash: GAParameterMakerFnType<
  'ACTION_APP_EDITOR_CRASH'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    action1: '새로고침',
  } satisfies ActionAppHome;
};
