import React, {
  type ComponentProps,
  type ReactNode,
  createContext,
  useCallback,
  useState,
} from 'react';
import { bindHook } from '@utils/hooks';

// target => "any" bindHooked Component
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ComponentType = ReturnType<typeof bindHook<any, any>>;

/**
 * @property {boolean} isRemainVisible 팝업 위에 다른 팝업이 뜰 때 기존 팝업을 유지할 경우 true
 */
export type PopupState<T extends ComponentType> = {
  isOpen: boolean;
  component: T;
  props: ComponentProps<T>;
  id?: number;
  isRemainVisible?: boolean;
};

export type OpenPopupFnParams<T extends ComponentType> = Omit<PopupState<T>, 'isOpen'>;

export type GlobalPopupContextValue = {
  openPopup: <T extends ComponentType>(params: OpenPopupFnParams<T>) => void;
  closePopup: (targetId?: number) => void;
  closeAllPopups: () => void;
};

export const PopupContext = createContext<GlobalPopupContextValue | null>(null);

type PopupProviderProps = {
  children: ReactNode;
};

export const PopupProvider = ({ children }: PopupProviderProps) => {
  // target => "any" bindHooked Component
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [popups, setPopups] = useState<PopupState<any>[]>([]);
  const openPopup = useCallback(
    <T extends ComponentType>({
      component,
      props,
      id,
      isRemainVisible = false,
    }: OpenPopupFnParams<T>) => {
      setPopups((prev) => {
        return [...prev, { isOpen: true, component, props, id, isRemainVisible }];
      });
    },
    []
  );

  const closePopup = useCallback((targetId?: number) => {
    if (targetId && typeof targetId === 'number') {
      setPopups((prev) => {
        return prev.filter((popup) => popup.id !== targetId);
      });
      return;
    }
    setPopups((prev) => (prev.length < 1 ? [] : [...prev].slice(0, prev.length - 1)));
  }, []);

  const closeAllPopups = useCallback(() => {
    setPopups([]);
  }, []);

  return (
    <PopupContext.Provider
      value={{
        openPopup,
        closePopup,
        closeAllPopups,
      }}>
      {popups.map(
        (popup, idx) =>
          // 중첩 props가 있거나 마지막 팝업만 노출
          (popup.isRemainVisible || idx === popups.length - 1) &&
          popup.isOpen && <popup.component key={popup.id} {...popup.props} />
      )}
      {children}
    </PopupContext.Provider>
  );
};
