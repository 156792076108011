import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickGlobalMainPageGoToEditorCTA: GAParameterMakerFnType<
  'ACTION_CLICK_GLOBAL_MAIN_PAGE_GO_TO_EDITOR_CTA'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '메인페이지(글로벌)',
    location3: 'CreateDesign',
    action1: '클릭',
    label1: '메인페이지_CreateDesign',
  };
};
