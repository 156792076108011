import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { ActionClickUploadItemPayload } from '../types/UploadPanelPayload';

type ActionClickUploadItemParams = GAParam<ActionClickUploadItemPayload>;

export const makeGAParamsActionClickUploadItem: GAParameterMakerFnType<
  'ACTION_CLICK_UPLOAD_ITEM'
> = (payload) => {
  return {
    event: 'action_upload',
    log_type: 'action_upload',
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
    action_item: payload.data.action_item,
    action_item_type: payload.data.action_item_type,
    search_status: payload.data.search_status,
    search_upload_type: payload.data.search_upload_type,
    priority: payload.data.priority,
    priority2: payload.data.priority2,
    search_term: payload.data.search_term,
  } satisfies ActionClickUploadItemParams;
};
