import { css } from 'styled-components';
import { BuildEnv } from '@configs/environments/BuildEnv';

export const DevToolsStyle = BuildEnv.IS_LOCAL
  ? css`
      /* jotai-devtools */
      button.jotai-devtools-trigger-button {
        left: unset;
        right: 5px;
        bottom: 100px; // react-query-devtools 와 겹치지 않게
        width: 3em;
        height: 3em;
      }

      /* react-query-devtools */
      aside.ReactQueryDevtools {
        & > button[aria-expanded='false'] {
          transform: translateY(-48px); // 하단 도움말 버튼과 겹치지 않게
        }
      }

      /* 모바일 환경을 위한 스타일 */
      @media (max-width: 479px) and (pointer: coarse) {
        button.jotai-devtools-trigger-button {
          top: var(--dragY) !important;
          left: var(--dragX) !important;
          bottom: unset;
          transform: translate(-50%, -50%) !important;
        }

        aside.ReactQueryDevtools {
          & > button[aria-expanded='false'] {
            top: var(--dragY) !important;
            left: var(--dragX) !important;
            bottom: unset !important;
            right: unset !important;
            transform: translate(-50%, -50%) !important;
          }
        }
      }
    `
  : '';
