import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickAIPresentationCreateNewDesignButton: GAParameterMakerFnType<
  'ACTION_CLICK_AI_PRESENTATION_CREATE_NEW_DESIGN_BUTTON'
> = () => ({
  log_type: 'generic_event_mican',
  event: 'gtm_trigger_event',
  location1: '에디터',
  location2: '프레젠테이션 자동 생성',
  location3: '생성 완료',
  location4: '새 디자인으로 만들기',
  action1: '클릭',
  label1: '프레젠테이션 자동 생성_생성 완료_새 디자인으로 만들기',
});
