import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickMainMenuDesignTitleParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickMainMenuDesignTitle: GAParameterMakerFnType<
  'ACTION_CLICK_MAIN_MENU_DESIGN_TITLE'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '전체메뉴',
    location3: '제목입력',
    action1: '클릭',
    label1: '파일_제목입력',
  } satisfies ActionClickMainMenuDesignTitleParams;
};
